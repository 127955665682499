import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toastMessage, Button, Input, Dropdown, Checkbox, Loader, AddRemove } from "../utils";
import { useNavigate } from "react-router-dom";
import { buttonClasses } from "../utils/theme";
import { useAddDeviceConfigMutation, useGetDefaultDeviceConfigMutation, useUpdateDeviceConfigMutation } from "../../api/deviceSlice";
import { useOrgs, useUserDetail } from "../../services/hooks";
import { Accordion, AccordionDetails, AccordionSummary, Tooltip } from "@mui/material";
import { ExpandMoreIcon, InfoIcon } from "../../services/icons";
import { langToSpeakerMap, speechLanguageOptions } from "./speakerData";

const DEFAULT_LOG_PATTERN = [{ commandCode: "", isLog: true, logTime: 0, keys: [{ key: "", value: "" }] }];
const DEFAULT_VALUE_CONFIG = [{ key: "", value: "" }];
const DEFAULT_ANTENNA = [{ antennanumber: "", status: "", action: "" }];
const defaultFormData = {
	dataSeparator: "",
	startSeparator: "",
	endSeparator: "",
	commandIndex: "",
	displayName: "",
	tidPattern: "",
	imei: "",
	power: "",
	orgId: "",
	deviceType: "",
	deviceSubType: "",
	characterEncoding: "utf-8",
	ledMsgType: "",
	ledStaticMsg: "",
	proximityMode: false,
	speechLanguage: "",
	speechVoice: "",
};

function updateLogPattern(logs) {
	return logs.map((_) => {
		const { commandCode = 0, logTime = 0, ...keys } = _;
		return {
			commandCode,
			logTime,
			isLog: logTime != undefined,
			keys: Object.keys(keys).map((key) => {
				return { key: key || "", value: keys[key] || "" };
			}),
		};
	});
}
function updateValueConfig(valueConfig) {
	// console.log(valueConfig);
	return Object.keys(valueConfig).map((key) => ({ key, value: valueConfig[key] }));
}
function fillFormData(data) {
	return {
		dataSeparator: data.dataSeparator || "",
		startSeparator: data.startSeparator || "",
		endSeparator: data.endSeparator || "",
		commandIndex: data.commandIndex || 0,
		displayName: data.Displayname || data.displayName || "",
		tidPattern: data.tidPattern || "",
		imei: data.imei || "",
		power: data.power || "",
		orgId: data.orgId || "",
		deviceType: data.deviceType || "",
		deviceSubType: data.deviceSubType || "",
		characterEncoding: data.characterEncoding || "",
		ledMsgType: data.ledMsgType || "",
		ledStaticMsg: data.ledStaticMsg || "",
		proximityMode: data.proximityMode || false,
		speechLanguage: data.speechLanguage || "",
		speechVoice: data.speechVoice || "",
	};
}

const AddDeviceForm = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { data: updateData } = location.state || {};
	const isUpdate = location.pathname.includes("edit");
	const { userDetail } = useUserDetail();
	const [formData, setFormData] = useState(updateData ? fillFormData(updateData) : defaultFormData);
	const { orgOptions } = useOrgs();
	const orgValue = orgOptions?.find((_) => _.value == formData.orgId) || null;

	const [getDefaultDeviceConfig, { isLoading: isDefaultConfigFetching }] = useGetDefaultDeviceConfigMutation({});
	const handleFormChange = (e) => {
		let { name, value, checked, type } = e.target;
		value = type == "checkbox" ? checked : value;
		setFormData((old) => ({ ...old, [name]: value }));
	};
	const onDropdownChange = (option, name) => {
		handleFormChange({ target: { name, value: option?.value || "" } });

		if (name == "deviceType") {
			switch (option?.value) {
				case "led":
				case "speaker":
					setFormData((o) => ({ ...defaultFormData, displayName: o.displayName, imei: o.imei, orgId: o.orgId, deviceType: option?.value }));
					setLogs(DEFAULT_LOG_PATTERN);
					setConfigs(DEFAULT_VALUE_CONFIG);
					setAntenna(DEFAULT_ANTENNA);
					break;
				default:
					break;
			}
		}
		if (name == "deviceSubType") {
			if (option?.value) {
				getDefaultDeviceConfig({ deviceType: formData.deviceType, deviceSubType: option?.value })
					.unwrap()
					.then((res) => {
						if (res.error) {
							toastMessage(false, res.message);
						} else {
							setFormData((o) => ({ ...fillFormData(res.data), displayName: o.displayName, imei: o.imei, orgId: o.orgId }));
							setLogs(updateLogPattern(res.data.logPattern));
							setAntenna(res.data.antenna);
						}
					});
			}
		}
	};

	const deviceTypeValue = deviceTypeOptions.find((_) => _.value == formData.deviceType);
	const deviceSubTypeValue = deviceSubTypeOptions.find((_) => _.value == formData.deviceSubType);
	const charEncodingValue = charEncodingOptions.find((_) => _.value == formData.characterEncoding);
	const ledMsgTypeValue = ledStatusOpts.find((_) => _.value == formData.ledMsgType);
	const speechLanguageValue = speechLanguageOptions.find((_) => _.value == formData.speechLanguage);

	const speechVoiceOptions = langToSpeakerMap[formData.speechLanguage] || [];
	const speechVoiceValue = speechVoiceOptions.find((_) => _.value == formData.speechVoice);

	const [logs, setLogs] = useState(updateData ? updateLogPattern(updateData.logPattern) : DEFAULT_LOG_PATTERN);
	const addLog = () => {
		setLogs((old) => [
			...old,
			{
				commandCode: "",
				isLog: true,
				logTime: 0,
				keys: [{ key: "", value: "" }],
			},
		]);
	};
	const removeLog = (index) => setLogs((old) => old.filter((_, i) => i != index));
	const handleLogChange = (e, index) => {
		let { name, value, checked, type } = e.target;
		value = type == "checkbox" ? checked : value;
		setLogs((old) => old.map((lp, i) => (i == index ? { ...lp, [name]: value } : lp)));
	};
	const handleKeysChange = (e, outerIndex, innerIndex) => {
		let { name, value } = e.target;
		setLogs((old) => old.map((lp, i) => (i == outerIndex ? { ...lp, keys: lp.keys.map((k, j) => (j == innerIndex ? { ...k, [name]: value } : k)) } : lp)));
	};
	const addKey = (outerIndex) => {
		setLogs((old) => old.map((lp, i) => (i == outerIndex ? { ...lp, keys: [...lp.keys, { key: "", value: "" }] } : lp)));
	};
	const removeKey = (outerIndex, innerIndex) => {
		setLogs((old) => old.map((lp, i) => (i == outerIndex ? { ...lp, keys: lp.keys.filter((_, j) => j != innerIndex) } : lp)));
	};

	const [configs, setConfigs] = useState(updateData ? updateValueConfig(updateData.valueConfig) : DEFAULT_VALUE_CONFIG);
	const handleConfigChange = (e, index) => {
		let { name, value } = e.target;
		setConfigs((old) => old.map((c, i) => (i == index ? { ...c, [name]: value } : c)));
	};
	const addConfig = () => setConfigs((old) => [...old, { key: "", value: "" }]);
	const removeConfig = (index) => setConfigs((old) => old.filter((_, i) => i != index));

	const [antenna, setAntenna] = useState(updateData ? updateData.antenna : DEFAULT_ANTENNA);
	const handleAntennaChange = (e, index) => {
		let { name, value } = e.target;
		setAntenna((old) => old.map((c, i) => (i == index ? { ...c, [name]: value } : c)));
	};
	const addAntenna = () => setAntenna((old) => [...old, { antennanumber: "", status: "", action: "" }]);
	const removeAntenna = (index) => setAntenna((old) => old.filter((_, i) => i != index));

	const [error, setError] = useState({});

	const [handleAddDevice, { isLoading: isAdding }] = useAddDeviceConfigMutation();
	const [handleUpdateDevice, { isLoading: isUpdating }] = useUpdateDeviceConfigMutation();

	const handleFormSubmit = async () => {
		let payload = {
			...formData,
			logPattern: logs.map((item) => ({
				commandCode: item.commandCode,
				logTime: item.logTime,
				...item.keys.reduce((res, { key, value }) => {
					res[key] = value;
					return res;
				}, {}),
			})),
			valueConfig: configs.reduce((res, { key, value }) => {
				res[key] = value;
				return res;
			}, {}),
			antenna,
		};

		if (isUpdate) {
			payload.deviceId = updateData?._id;
		}

		const {
			data: { error, message },
		} = isUpdate ? await handleUpdateDevice(payload) : await handleAddDevice(payload);

		// console.log({error, message})
		if (error) {
			toastMessage(false, message);
		} else {
			toastMessage(true, message);
			navigate("/devices");
		}

		// console.log(payload);
	};

	const handleFormValidation = () => {
		let error = {};

		if (userDetail?.userType == "superAdmin" && !formData.orgId) error.orgId = "Organization name is required";

		// if (!formData.dataSeparator) error.dataSeparator = "Data Separator is required";
		// if (!formData.startSeparator) error.startSeparator = "Start Separator is required";
		// if (!formData.endSeparator) error.endSeparator = "End Separator is required";
		// if (!formData.commandIndex) error.commandIndex = "Command Index is required";

		if (!formData.displayName) error.displayName = "Display Name is required";
		// if (!formData.tidPattern) error.tidPattern = "TID Pattern is required";
		if (!formData.imei) error.imei = "IMEI is required";
		if (!formData.deviceType) error.deviceType = "Device Type is required";
		else if (formData.deviceType == "rfidReader" && !formData.deviceSubType) error.deviceSubType = "Device Subtype is required";
		else if (formData.deviceType == "led" && !formData.ledMsgType) error.ledMsgType = "LED Message Type is required";

		if (Object.keys(error).length) {
			setError(error);
		} else {
			handleFormSubmit();
		}
	};

	const showFields = formData.deviceType == "rfidReader" ? !!formData.deviceSubType : !!formData.deviceType;

	const showSeparators = formData.deviceType == "rfidReader" && formData.deviceSubType == "zebra";
	const showCIndex = showSeparators;
	const showPower = formData.deviceType == "rfidReader";
	const showTid = showPower;
	const showInfo = showCIndex || showPower || showTid;
	const showLogs = showTid;
	const showVC = showTid;
	const showAnt = showTid;
	const showLedInfo = formData.deviceType == "led";
	const showSpeakerInfo = formData.deviceType == "speaker";

	return (
		<>
			<div className="contentpanel add_device_config">
				<div className="cust-row flex-algn-cent">
					<div className="cust-col-5">
						<h1 className="title">{isUpdate ? "Update Device" : "Add Device"}</h1>
					</div>
					<div className="cust-col-5 flex-jc-end">
						<Link to="/devices" className=" ">
							<Button className="" text="Go Back" style={buttonClasses.lynkitOrangeEmpty} />
						</Link>
					</div>
				</div>
				<hr className="bgg" />

				<form>
					<div className="form-inputs">
						{userDetail?.userType == "superAdmin" ? (
							<Dropdown id="orgId" name="orgId" label="Organization Name" onChange={onDropdownChange} options={orgOptions} value={orgValue} error={error.orgId} />
						) : null}
						<Input label="Display Name" placeholder="Enter Display Name" name="displayName" value={formData.displayName} onChange={handleFormChange} error={error.displayName} required />
						<Input label="IMEI" placeholder="Enter IMEI" name="imei" value={formData.imei} onChange={handleFormChange} required error={error.imei} />

						<Dropdown
							id="deviceType"
							name="deviceType"
							label="Device Type"
							onChange={onDropdownChange}
							options={deviceTypeOptions}
							value={deviceTypeValue}
							error={error.deviceType}
							required
						/>
						{formData.deviceType == "rfidReader" ? (
							<Dropdown
								id="deviceSubType"
								name="deviceSubType"
								label="Device Sub Type"
								onChange={onDropdownChange}
								options={deviceSubTypeOptions}
								value={deviceSubTypeValue}
								required
								error={error.deviceSubType}
							/>
						) : null}

						<Dropdown
							id="characterEncoding"
							name="characterEncoding"
							label="Character Encoding"
							onChange={onDropdownChange}
							options={charEncodingOptions}
							value={charEncodingValue}
							error={error.charEncodingValue}
						/>
						{showSpeakerInfo ? (
							<>
								<Dropdown
									id="speechLanguage"
									name="speechLanguage"
									label="Speech Language"
									onChange={onDropdownChange}
									options={speechLanguageOptions}
									value={speechLanguageValue}
									error={error.speechLanguage}
								/>

								{formData.speechLanguage ? (
									<Dropdown
										id="speechVoice"
										name="speechVoice"
										label="Speech Voice"
										onChange={onDropdownChange}
										options={speechVoiceOptions}
										value={speechVoiceValue}
										error={error.speechVoice}
									/>
								) : null}
							</>
						) : null}
						{showLedInfo ? (
							<>
								<Dropdown
									id="ledMsgType"
									name="ledMsgType"
									label="LED Message Type"
									onChange={onDropdownChange}
									options={ledStatusOpts}
									value={ledMsgTypeValue}
									error={error.ledMsgType}
									required
								/>
								{formData.ledMsgType == "combo" ? (
									<Input
										label="LED Static Message"
										placeholder="Enter static message"
										name="ledStaticMsg"
										value={formData.ledStaticMsg}
										onChange={handleFormChange}
										error={error.ledStaticMsg}
									/>
								) : null}
							</>
						) : null}
					</div>
					<div>
						{formData.deviceType == "rfidReader" ? (
							<>
								<Checkbox id="proximityMode" name="proximityMode" checked={formData.proximityMode} onChange={handleFormChange} label="Proximity Mode" />
								<Tooltip title="Enable this to allow Out-Of-Range events to be detected through this device.">
									<InfoIcon sx={{ fill: "#ff7200" }} />
								</Tooltip>
							</>
						) : null}
					</div>
					{isDefaultConfigFetching ? (
						<Loader size="3rem" height="50vh" />
					) : showFields ? (
						<>
							{showSeparators ? (
								<Accordion>
									<AccordionSummary expandIcon={<ExpandMoreIcon />}>
										<h5>Separators</h5>
									</AccordionSummary>
									<AccordionDetails>
										<div className="form-inputs">
											<Input
												label="Data Separator"
												placeholder="Enter Data Separator"
												name="dataSeparator"
												value={formData.dataSeparator}
												onChange={handleFormChange}
												error={error.dataSeparator}
											/>
											<Input
												label="Start Separator"
												placeholder="Enter Start Separator"
												name="startSeparator"
												value={formData.startSeparator}
												onChange={handleFormChange}
												error={error.startSeparator}
											/>
											<Input
												label="End Separator"
												placeholder="Enter End Separator"
												name="endSeparator"
												value={formData.endSeparator}
												onChange={handleFormChange}
												error={error.endSeparator}
											/>
										</div>
									</AccordionDetails>
								</Accordion>
							) : null}
							{showInfo ? (
								<Accordion>
									<AccordionSummary expandIcon={<ExpandMoreIcon />}>
										<h5>Config</h5>
									</AccordionSummary>
									<AccordionDetails>
										<div className="form-inputs">
											{showCIndex ? (
												<Input
													label="Command Index"
													type="number"
													placeholder="Enter Command Index"
													name="commandIndex"
													value={formData.commandIndex}
													onChange={handleFormChange}
													error={error.commandIndex}
												/>
											) : null}
											{showTid ? (
												<Input
													label="TID Pattern"
													placeholder="Enter TID Pattern"
													name="tidPattern"
													value={formData.tidPattern}
													onChange={handleFormChange}
													error={error.tidPattern}
												/>
											) : null}
											{showPower ? <Input label="Power" placeholder="Enter Power" name="power" value={formData.power} onChange={handleFormChange} /> : null}
										</div>
									</AccordionDetails>
								</Accordion>
							) : null}
							{showLogs ? (
								<Accordion>
									<AccordionSummary expandIcon={<ExpandMoreIcon />}>
										<h5>Log Patterns</h5>
									</AccordionSummary>
									<AccordionDetails>
										{logs?.map((log, li) => {
											return (
												<div key={li} className="border flex-col-g1  log_pat_padding boder-rad-10p">
													<div className="d-flex-g1 log_patrn_ar">
														<Button onClick={addLog} text="Add" style={buttonClasses.lynkitOrangeEmpty} />
														{li > 0 ? (
															<Button
																className="failBtn"
																variant="outline-danger"
																size="sm"
																onClick={() => removeLog(li)}
																text="Remove"
																style={buttonClasses.lynkitOrangeEmpty}
															/>
														) : null}
													</div>
													<Input label="Command Code" placeholder="Command Code" name="commandCode" value={log.commandCode} onChange={(e) => handleLogChange(e, li)} />

													<div style={{ display: "grid", gridTemplateColumns: "20% 70%", gap: "1rem" }}>
														<Checkbox id="isLog" name="isLog" checked={log.isLog} onChange={(e) => handleLogChange(e, li)} label="Log Time" />
														<Input
															label="Log Time Index"
															placeholder="Enter Log Time Index"
															name="logTime"
															type="number"
															disabled={!log.isLog}
															value={log.logTime}
															onChange={(e) => handleLogChange(e, li)}
														/>
													</div>

													<div className="">
														{log.keys?.map(({ key, value }, i) => {
															return (
																<div key={i} className="form-inputs" style={{ marginBottom: "0.5rem" }}>
																	<Input label="Key" placeholder="Enter Key" name="key" value={key} onChange={(e) => handleKeysChange(e, li, i)} />
																	<Input label="Index" placeholder="Enter Index" name="value" value={value} onChange={(e) => handleKeysChange(e, li, i)} />
																	<AddRemove list={log.keys} filterMethod={(k) => k.key == ""} index={li} outerIndex={i} onAdd={addKey} onRemove={removeKey} />
																</div>
															);
														})}
													</div>
												</div>
											);
										})}
									</AccordionDetails>
								</Accordion>
							) : null}
							{showVC ? (
								<Accordion>
									<AccordionSummary expandIcon={<ExpandMoreIcon />}>
										<h5>Value Configs</h5>
									</AccordionSummary>
									<AccordionDetails>
										{configs.map(({ key, value }, ci) => {
											return (
												<div key={ci} className="form-inputs mb-2">
													<Input label="Key" placeholder="Enter Key" name="key" value={key} onChange={(e) => handleConfigChange(e, ci)} />
													<Input label="Index" placeholder="Enter Index" name="value" value={value} onChange={(e) => handleConfigChange(e, ci)} />
													<AddRemove list={configs} filterMethod={(k) => k.key == ""} index={ci} outerIndex={ci} onAdd={addConfig} onRemove={removeConfig} />
												</div>
											);
										})}
									</AccordionDetails>
								</Accordion>
							) : null}

							{showAnt ? (
								<Accordion>
									<AccordionSummary expandIcon={<ExpandMoreIcon />}>
										<h5>Antennas</h5>
									</AccordionSummary>
									<AccordionDetails>
										{antenna.map(({ antennanumber, status, action }, aIndex) => {
											return (
												<div key={aIndex} className="form-inputs mb-3">
													<Input
														label="Antenna Number"
														placeholder="Enter Antenna Number"
														name="antennanumber"
														value={antennanumber}
														onChange={(e) => handleAntennaChange(e, aIndex)}
													/>
													<Dropdown
														id="status"
														name="status"
														label="Status"
														onChange={(option, name) => handleAntennaChange({ target: { name, value: option?.value || "" } }, aIndex)}
														options={statusOptions}
														value={statusOptions.find((_) => _.value == status)}
													/>

													<Input label="Action" placeholder="Enter Action" name="action" value={action} onChange={(e) => handleAntennaChange(e, aIndex)} />
													<AddRemove
														list={antenna}
														filterMethod={(k) => k.antennanumber == ""}
														index={aIndex}
														outerIndex={aIndex}
														onAdd={addAntenna}
														onRemove={removeAntenna}
													/>
												</div>
											);
										})}
									</AccordionDetails>
								</Accordion>
							) : null}
						</>
					) : null}

					<div className="d-flex justify-content-center" style={{ height: "79px" }}>
						<Button onClick={handleFormValidation} text={isUpdate ? "Update" : "Submit"} style={{ ...buttonClasses.lynkitOrangeFill, width: "15%" }} disabled={isAdding || isUpdating} />
					</div>
				</form>
			</div>
		</>
	);
};
export default AddDeviceForm;

const statusOptions = [
	{ label: "--select--", value: "" },
	{ label: "Active", value: "Active" },
	{ label: "In-Active", value: "In-Active" },
];

const deviceTypeOptions = [
	{ label: "RFID Reader", value: "rfidReader" },
	{ label: "Speaker", value: "speaker" },
	{ label: "LED", value: "led" },
];

const deviceSubTypeOptions = [
	{ label: "Zebra", value: "zebra" },
	{ label: "Seuic", value: "seuic" },
];

const charEncodingOptions = [
	{ label: "UTF-8", value: "utf-8" },
	{ label: "Hex (combine w/o separators)", value: "hex" },
];

const ledStatusOpts = [
	{ label: "Display on top-half", value: "top" },
	{ label: "Display on bottom-half", value: "bottom" },
	{ label: "Display full screen scrolling", value: "scroll" },
];
