import React, { useLayoutEffect, useRef } from "react";

export const ExternalAppIFrame = ({ serverURL, action }) => {
	const containerRef = useRef(null);

	useLayoutEffect(() => {
		const auth = "LYNKID";
		const timestamp = new Date().getTime();

		const url = new URL(serverURL);
		url.searchParams.append("action", action);
		url.searchParams.append("auth", auth);
		url.searchParams.append("timestamp", timestamp);

		url.searchParams.append("primaryColor", localStorage.getItem("primaryColor") || "#ff7200");
		url.searchParams.append("selectedTheme", localStorage.getItem("selectedTheme") || "lightMode");

		// Check if an iframe already exists
		let iframe = containerRef.current.querySelector("iframe");
		if (!iframe) {
			iframe = document.createElement("iframe");
			iframe.style.width = "100%";
			iframe.style.height = "100%";
			iframe.style.border = "none";
			containerRef.current.appendChild(iframe);
		}

		iframe.src = url.toString();

		const msgToSend = {
			phrase: localStorage.getItem("secretkey"),
		};

		const messageEventListener = (evt) => {
			if (serverURL.includes(evt.origin)) {
				try {
					const req_data = typeof evt.data == "string" ? JSON.parse(evt.data) : evt.data;
					if (req_data.perkey == "sendphrase") {
						evt.source.postMessage(msgToSend, evt.origin);
					}
				} catch (error) {
					console.error(error);
				}
			}
		};

		window.addEventListener("message", messageEventListener);

		return () => {
			window.removeEventListener("message", messageEventListener);
		};
	}, [serverURL, action]);

	return (
		<>
			<div style={{ height: "88vh" }} ref={containerRef} />
		</>
	);
};
