import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

import { Dropdown, GenericTable, Input, toastMessage, Button, tCell, ActionMenu, Dialog, generateDetailRows, DetailCard, AddRemove } from "../utils/index";
import theme, { buttonClasses } from "../utils/theme";
import { useCheckpointTypes, useOrgs, useUserPermissions } from "../../services/hooks";
import { capitalizeCamelCase, convertToCamelCase, formatDate } from "../../services/functions";
import { useAddOrgMutation, useUpdateThemeMutation } from "../../api/userSlice";
import { Navigate } from "react-router-dom/dist";
import Genericfilter from "../utils/Genericfilter";
import UploadFileButton from "../utils/index";
import { Checkbox, ColorPicker } from "../utils/index";
import { base64Convert, debounce } from "../../services/functions";
import { GenericstatsV2 } from "../utils/Genericstats";
import "./Company.css";
import { assignDeep } from "../Dynamic/DynamicForm";

const defaultFilter = {
	pagesize: 10,
	page_no: 1,
};

const filterFields = [
	{
		inputType: "textfield",
		id: "name",
		name: "name",
		type: "text",
		label: "Company Name",
		placeholder: "Company Name",
		readOnly: false,
		error: "",
		value: "",
	},
	{
		inputType: "dropdown",
		id: "status",
		name: "status",
		label: "Status",
		error: "",
		option: [
			{ label: "Active", value: "ACTIVE" },
			{ label: "Blocked", value: "Blocked" },
		],
	},
];

function getCompanyDetails(data) {
	const dataToShow = ["companyName", "representativeName", "representativeEmail", "representativeMobile", "address", "state", "city", "pincode", "pan", "gstin", "status"];
	const res = dataToShow.map((key) => ({ label: capitalizeCamelCase(key), value: data[key == "companyName" ? "name" : key] || "-" }));
	return res;
}

const Company = () => {
	const { userPermissions, isFetched } = useUserPermissions();

	const [isModal, setIsModal] = useState(false);
	const [filterApplied, setFilterApplied] = useState(false);
	const onModalOpen = () => setIsModal(true);
	const onModalClose = () => {
		setIsModal(false);
		if (updateDataRef.current) {
			updateDataRef.current = null;
		}
	};
	const updateDataRef = useRef(null);
	const filterRef = useRef(defaultFilter);
	const { page_no, pagesize } = filterRef.current;
	const { orgs, count, isLoading, message, refetch } = useOrgs(filterRef.current);
	const [isOpen, setIsOpen] = useState(false);
	const [detail, setDetail] = useState(null);
	const handleDetailClose = () => {
		setIsOpen(false);
		setDetail(null);
	};

	const handleDeviceView = (e, index) => {
		// console.log(index, "view", devices[index]);
		setDetail(generateDetailRows(getCompanyDetails(orgs[index])));
		setIsOpen(true);
	};

	let actionHandlers = {};
	// if (userPermissions?.updateOrganization?.value) {
	// 	actionHandlers.edit = (id) => {
	// 		updateDataRef.current = orgs[id];
	// 		setIsModal(true);
	// 	};
	// }
	actionHandlers.edit = (id) => {
		updateDataRef.current = orgs[id];
		onModalOpen();
	};

	let header = ["S.no", "Company Name", "Status", "Added On"];
	// if (userPermissions?.updateOrganization?.value || userPermissions?.deleteOrganization?.value) {
	// 	header.push("Action");
	// }
	header.push("Action");
	const rows =
		orgs?.map((d, i) => {
			let row = [];
			row.push(tCell((page_no - 1) * pagesize + i + 1), tCell(d.name, theme.themeOrange, "pointer", handleDeviceView, i), tCell(d.status), tCell(formatDate(d.addedOn)));
			// if (userPermissions?.updateOrganization?.value || userPermissions?.deleteOrganization?.value) {
			// 	<ActionMenu id={i} handlers={actionHandlers} />;
			// }
			row.push(
				<div style={{ paddingLeft: "0.5rem" }}>
					<ActionMenu id={i} handlers={actionHandlers} />
				</div>
			);

			return row;
		}) || [];

	const onPageChange = (page_no) => {
		filterRef.current = { ...filterRef.current, page_no: page_no + 1 };
		refetch();
	};
	const onPageSizeChange = (e) => {
		const { value } = e.target;
		filterRef.current = { ...filterRef.current, pagesize: value };
		refetch();
	};
	const handleFilterChange = (filter) => {
		if ((filter?.name != "" || filter?.status != "") && Object.keys(filter).length > 0) {
			// console.log('filter on change-device', filter);
			// Update orgId property safely
			const updatedFilter = { ...filter };
			const statusValue = filter?.status?.value || "";
			updatedFilter.status = statusValue;

			// Update filterRef.current
			filterRef.current = { ...filterRef.current, ...updatedFilter };
			refetch();
			setFilterApplied(true);
		}
		if (filter?.name === "" && filter?.status === "" && Object.keys(filter).length > 0 && filterApplied === true) {
			// console.log('without-filter on change-device', filter);
			filterRef.current = { ...defaultFilter };
			refetch();
			setFilterApplied(false);
		}
	};
	if (isFetched && userPermissions && Object.keys(userPermissions).length && !userPermissions.getOrganizations?.value) return <Navigate to="/not-authorized" replace />;

	return (
		<div className="contentpanel">
			<div className="cust-row flex-algn-cent">
				<div className="cust-col-5">
					<h1 className="title">Company Management</h1>
				</div>
				{userPermissions?.addOrganization?.value ? (
					<div className="cust-col-5 flex-jc-end">
						<Link className="a_tag_no">
							<Button onClick={onModalOpen} text="Add Company" style={buttonClasses.lynkitOrangeEmpty} />
						</Link>
					</div>
				) : null}
			</div>
			<hr className="bgg" />
			<GenericstatsV2 statsFor={"company"} />
			<div className="cust-row flex-algn-cent">
				<Genericfilter filterFields={filterFields} onFilterChange={debounce((filter) => handleFilterChange(filter), 500)} />
			</div>
			<div className="main_content">
				<GenericTable
					header={header}
					rows={rows}
					pageCount={count}
					pageNo={filterRef.current?.page_no}
					limit={filterRef.current?.pagesize}
					onPageChange={onPageChange}
					onPageSizeChange={onPageSizeChange}
					isLoading={isLoading}
				/>
				<DetailCard isOpen={isOpen} onClose={handleDetailClose} title={"Company Details"}>
					{detail}
				</DetailCard>
			</div>

			{isModal && <AddCompany open={isModal} onClose={onModalClose} updateData={updateDataRef.current} />}
		</div>
	);
};

export default Company;

const defaultForm = {
	orgName: "",
	representativeName: "",
	representativeEmail: "",
	representativeMobile: "",
	address: "",
	state: "",
	city: "",
	pincode: "",
	pan: "",
	gstin: "",
	updateId: undefined,
	title: "",
	theme: {
		primaryColor: "#ff7200",
		selectedTheme: "lightMode",
	},
	logo: {
		fileData: "",
		docType: "",
		fileName: "",
	},
	favIcon: {
		fileData: "",
		docType: "",
		fileName: "",
	},
	permissions: {},
	hhtImei: [{ imei: "" }],
	statIcon: {
		total: {
			fileData: "",
			docType: "",
			fileName: "",
		},
		todayIn: {
			fileData: "",
			docType: "",
			fileName: "",
		},
		todayOut: {
			fileData: "",
			docType: "",
			fileName: "",
		},
	},
};

function fillUpdateData(data) {
	// console.log('data', data)
	return {
		orgName: data.name,
		representativeName: data.representativeName,
		representativeEmail: data.representativeEmail,
		representativeMobile: data.representativeMobile,
		address: data.address,
		state: data.state,
		city: data.city,
		pincode: data.pincode,
		pan: data.pan,
		gstin: data.gstin,
		updateId: data._id,
		title: data.themeSettings.title,
		theme: {
			primaryColor: data.themeSettings.theme.primaryColor,
			selectedTheme: data.themeSettings.theme.selectedTheme,
		},
		logo: {
			fileData: "",
			docType: "",
			fileName: data.themeSettings.logo,
		},
		favIcon: {
			fileData: "",
			docType: "",
			fileName: data.themeSettings.favIcon,
		},
		permissions: data.permissions || {},
		hhtImei: data.hhtImei || [{ imei: "" }],
		statIcon: {
			total: {
				fileData: "",
				docType: "",
				fileName: data.themeSettings.statIcon?.total || "",
			},
			todayIn: {
				fileData: "",
				docType: "",
				fileName: data.themeSettings.statIcon?.todayIn || "",
			},
			todayOut: {
				fileData: "",
				docType: "",
				fileName: data.themeSettings.statIcon?.todayOut || "",
			},
		},
	};
}
let orgPerms = {
	multiTagSupport: {
		value: true,
		strict: false,
		dependancy: [],
	},
	thirdPartyTripSetup: {
		value: true,
		strict: false,
		dependancy: [],
	},
	thirdPartyTripStatusUpdate: {
		value: true,
		strict: false,
		dependancy: [],
	},
	assetMappingSupport: {
		value: true,
		strict: false,
		dependancy: [],
	},
	assetAdd: {
		value: true,
		strict: false,
		dependancy: [],
	},
	assetScan: {
		value: true,
		strict: false,
		dependancy: [],
	},
	createTripsThroughStrayFastags:{
		value:true,
		strict: false,
		dependancy:[]
	}
};

const DEFAULT_CHEKPOINTS = [
	{ displayName: "Access", checkpointType: "access" },
	{ displayName: "Gate In", checkpointType: "gateIn" },
	{ displayName: "Bay", checkpointType: "bay" },
	{ displayName: "Gate Out", checkpointType: "gateOut" },
];

function AddCompany({ onClose, open, updateData }) {
	const [formData, setFormData] = useState(updateData ? fillUpdateData(updateData) : defaultForm);
	const [errors, setErrors] = useState({});
	const [updateThemeTrigger] = useUpdateThemeMutation();

	const [customizeCheckpoint, setCustomizeCheckpoint] = useState(false);

	const { checkpointTypeOptions } = useCheckpointTypes({ orgId: "all" });

	const handleInput = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		if (name === "representativeMobile") {
			if (value.length <= 10) {
				setFormData({ ...formData, [name]: value });
			}
		} else {
			setFormData({ ...formData, [name]: value });
		}
	};
	const handlePermChange = (e) => {
		const { name } = e.target;

		setFormData((old) => {
			let newData = JSON.parse(JSON.stringify(old));
			if (newData.permissions[name]) {
				delete newData.permissions[name];
			} else {
				newData.permissions[name] = orgPerms[name];
			}
			return newData;
		});
	};
	const handleCheckAllPerm = (e) => {
		const { checked } = e.target;
		setFormData((o) => ({
			...o,
			permissions: checked ? { ...orgPerms } : {},
		}));
	};

	// console.log(formData)

	let perms = [];
	for (let perm in orgPerms) {
		perms.push(
			<Checkbox
				key={perm}
				label={capitalizeCamelCase(perm)}
				id={perm}
				name={perm}
				checked={!!formData.permissions[perm]}
				onChange={(e) => {
					handlePermChange(e);
				}}
				disabled={perm == "multiTagSupport" && updateData ? true : false}
			/>
		);
	}

	const onDropdownChange = (option) => {
		let inputData = { ...formData };
		inputData.orgId = option?.value || "";
		setFormData(inputData);
	};
	const [handleSubmit] = useAddOrgMutation();
	const handleFormSubmit = async () => {
		const valid = validateForm(formData);
		// console.log('error', valid);
		if (valid.success === false) {
			setErrors(valid.errors);
			return;
		} else {
			setErrors({});
			let payload = { ...formData };
			if (payload.checkpoints) {
				payload.checkpoints = payload.checkpoints.map((_) => ({ ..._, checkpointStatus: convertToCamelCase(_.displayName) }));
			}
			const {
				data: { error, message },
			} = await handleSubmit(payload);
			if (error) {
				toastMessage(false, message);
			} else {
				toastMessage(true, message);
				handleClose();
			}
		}
	};

	const handleUpdateSubmit = async () => {
		// console.log('form submit', formData);
		const valid = updateValidateForm(formData);
		if (valid.success === false) {
			setErrors(valid.errors);
			return;
		}

		const payload = {
			theme: formData.theme,
			title: formData.title,
			orgID: formData.updateId,
			permissions: formData.permissions,
			hhtImei: formData.hhtImei,
			statIcon: formData.statIcon,
		};
		if (formData.logo) {
			payload.logo = {
				fileData: formData.logo.fileData,
				docType: formData.logo.docType,
				fileName: formData.logo.fileName
			};
		}

		if (formData.favIcon) {
			payload.favIcon = {
				fileData: formData.favIcon.fileData,
				docType: formData.favIcon.docType,
				fileName: formData.favIcon.fileName
			};
		}
		// console.log({payload})
		updateThemeTrigger({ ...payload })
			.unwrap()
			.then((res) => {
				if (res.error) {
					toastMessage(false, res.message);
				} else {
					toastMessage(true, res.message);
					handleClose();
				}
			})
			.catch((err) => {
				// console.log(err);
				toastMessage(false, "Organization Update Failed");
			});

	};
	const handleClose = () => {
		setFormData(defaultForm);
		onClose();
	};

	const handleThemeChange = async (name, value, themeValue) => {
		if (name == "logo" || name == "favIcon" || name.includes("statIcon")) {
			const result = value != "" ? await base64Convert(value) : value;
			setFormData((o) => {
				let n = JSON.parse(JSON.stringify(o));
				assignDeep(n, name, { fileData: result, docType: name, fileName: value?.name || "" });
				return n;
			});
		} else if (name === "primaryColor") {
			setFormData({ ...formData, theme: { ...formData.theme, primaryColor: value } });
		} else if (name === "selectedTheme") {
			if (value === true) {
				setFormData({ ...formData, theme: { ...formData.theme, selectedTheme: themeValue } });
			}
		} else {
			setFormData({ ...formData, [name]: value });
		}
	};

	function updateValidateForm(formData) {
		const errors = {};

		let success = true;
		// Validate title
		if (!formData.title) {
			errors.title = "Title is required";
			success = false;
		}

		// Validate theme
		if (!formData.theme || typeof formData.theme !== "object") {
			errors.theme = "Theme data is missing or invalid";
			success = false;
		} else {
			// Validate primaryColor
			if (!formData.theme.primaryColor.trim()) {
				errors.theme = "Primary color is required";
				success = false;
			}
			// Validate selectedTheme
			if (!["lightMode", "darkMode"].includes(formData.theme.selectedTheme)) {
				errors.theme = "Invalid selected theme";
				success = false;
			}
		}

		return { errors, success };
	}

	function validateForm(formData) {
		const errors = {};

		let success = true;

		// Validate orgName
		if (!formData.orgName.trim()) {
			errors.orgName = "Company name is required";
			success = false;
		}

		// Validate representativeName
		if (!formData.representativeName.trim()) {
			errors.representativeName = "Representative name is required";
			success = false;
		}

		// Validate representativeEmail
		if (!formData.representativeEmail.trim()) {
			errors.representativeEmail = "Email is required";
			success = false;
		} else if (!/^\S+@\S+\.\S+$/.test(formData.representativeEmail)) {
			errors.representativeEmail = "Invalid email address";
			success = false;
		}

		// Validate representativeMobile
		if (!formData.representativeMobile.trim()) {
			errors.representativeMobile = "Mobile number is required";
			success = false;
		} else if (formData.representativeMobile.trim().length !== 10) {
			errors.representativeMobile = "Mobile number must be 10 digits";
			success = false;
		}

		// Validate address
		if (!formData.address) {
			errors.address = "Address is required";
			success = false;
		}

		// Validate state
		if (!formData.state) {
			errors.state = "State is required";
			success = false;
		}

		// Validate city
		if (!formData?.city) {
			errors.city = "City is required";
			success = false;
		}

		// Validate pincode
		if (!formData?.pincode) {
			errors.pincode = "Pincode is required";
			success = false;
		} else if (!/^\d{6}$/.test(formData.pincode.trim())) {
			errors.pincode = "Invalid pincode";
			success = false;
		}

		// Validate PAN
		if (!formData?.pan) {
			errors.pan = "PAN is required";
			success = false;
		} else if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(formData.pan.trim().toUpperCase())) {
			errors.pan = "Invalid PAN number";
			success = false;
		}

		// Validate GSTIN
		if (!formData?.gstin) {
			errors.gstin = "GSTIN is required";
			success = false;
		} else if (!/^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d{1}Z[A-Z0-9]{1}$/.test(formData.gstin.trim().toUpperCase())) {
			errors.gstin = "Invalid GSTIN";
			success = false;
		}

		// Validate title
		if (!formData.title) {
			errors.title = "Title is required";
			success = false;
		}

		// Validate theme
		if (!formData.theme || typeof formData.theme !== "object") {
			errors.theme = "Theme data is missing or invalid";
			success = false;
		} else {
			// Validate primaryColor
			if (!formData.theme.primaryColor) {
				errors.theme = "Primary color is required";
				success = false;
			}
			// Validate selectedTheme
			if (!["lightMode", "darkMode"].includes(formData.theme.selectedTheme)) {
				errors.theme = "Invalid selected theme";
				success = false;
			}
		}

		// Validate logo
		if (!formData.logo || typeof formData.logo !== "object") {
			errors.logo = "Logo data is missing or invalid";
			success = false;
		} else {
			// Validate fileData
			if (!formData.logo.fileData) {
				errors.logo = "Logo file data is required";
				success = false;
			}
			// Validate docType
			if (!formData.logo.docType) {
				errors.logo = "Logo document type is required";
				success = false;
			}
		}

		// Validate favIcon
		if (!formData.favIcon || typeof formData.favIcon !== "object") {
			errors.favIcon = "Favicon data is missing or invalid";
			success = false;
		} else {
			// Validate fileData
			if (!formData.favIcon.fileData) {
				errors.favIcon = "Favicon file data is required";
				success = false;
			}
			// Validate docType
			if (!formData.favIcon.docType) {
				errors.favIcon = "Favicon document type is required";
				success = false;
			}
		}

		return { errors, success };
	}
	// console.log(formData)
	return (
		<Dialog size="md" open={open} title={"Add Company"} SubmitText={updateData ? "Update" : "Submit"} handleClose={handleClose} handleSubmit={updateData ? handleUpdateSubmit : handleFormSubmit}>
			<form className="">
				<div className="form-inputs mt-2">
					<Input
						readOnly={!!updateData}
						error={errors?.orgName}
						label="Company Name"
						placeholder="Enter Company Name"
						name="orgName"
						value={formData.orgName}
						onChange={handleInput}
						required={true}
					/>
					<Input
						readOnly={!!updateData}
						error={errors?.representativeName}
						label="Name"
						placeholder="Enter Name"
						name="representativeName"
						value={formData.representativeName}
						onChange={handleInput}
						required={true}
					/>
					<Input
						readOnly={!!updateData}
						error={errors?.representativeMobile}
						label="Mobile"
						placeholder="Enter Mobile"
						name="representativeMobile"
						value={formData.representativeMobile}
						onChange={handleInput}
						required={true}
					/>
					<Input
						readOnly={!!updateData}
						error={errors?.representativeEmail}
						type="email"
						label="Email"
						placeholder="Enter Email"
						name="representativeEmail"
						value={formData.representativeEmail}
						onChange={handleInput}
						required={true}
					/>
					<Input readOnly={!!updateData} error={errors?.address} label="Address" placeholder="Enter Address" name="address" value={formData.address} onChange={handleInput} required={true} />
					<Input readOnly={!!updateData} error={errors?.state} label="State" placeholder="Enter State" name="state" value={formData.state} onChange={handleInput} required={true} />
					<Input readOnly={!!updateData} error={errors?.city} label="City" placeholder="Enter City" name="city" value={formData.city} onChange={handleInput} required={true} />
					{/* <Dropdown
						id="state"
						name="state"
						label="Select State"
						onChange={onDropdownChange}
						options={[]}
						value={{
							value: formData.orgId,
							label:
								orgs.find((_) => _._id == formData.orgId)
									?.name || "",
						}}
					/> */}
					{/* <Dropdown
						id="city"
						name="city"
						label="Select City"
						onChange={onDropdownChange}
						options={[]}
						value={{
							value: formData.orgId,
							label:
								orgs.find((_) => _._id == formData.orgId)
									?.name || "",
						}}
					/> */}
					<Input readOnly={!!updateData} error={errors?.pincode} label="Pincode" placeholder="Enter Pincode" name="pincode" value={formData.pincode} onChange={handleInput} required={true} />
					<Input readOnly={!!updateData} error={errors?.pan} label="PAN" placeholder="Enter PAN" name="pan" value={formData.pan} onChange={handleInput} required={true} />
					<Input readOnly={!!updateData} error={errors?.gstin} label="GST" placeholder="Enter GST" name="gstin" value={formData.gstin} onChange={handleInput} required={true} />
					{!updateData ? (
						<Checkbox
							name="customizeCheckpoint"
							checked={customizeCheckpoint}
							onChange={(e) => {
								setCustomizeCheckpoint(e.target.checked);
								if (e.target.checked) {
									setFormData((o) => ({ ...o, checkpoints: DEFAULT_CHEKPOINTS }));
								} else {
									setFormData((o) => {
										let n = { ...o };
										delete n.checkpoints;
										return n;
									});
								}
							}}
							label="Customize Checkpoints"
						/>
					) : null}
				</div>
				{!updateData && customizeCheckpoint ? (
					<div className="theme-container">
						<h6>Checkpoints</h6>
						<div style={{ display: "flex", gap: "0.5rem", flexDirection: "column", marginTop: "0.75rem" }}>
							{formData.checkpoints.map(({ displayName, checkpointType }, i) => {
								return (
									<div key={i} style={{ display: "grid", gap: "1rem", gridTemplateColumns: "30% 30% 10%" }}>
										<Input
											name="displayName"
											label="Display Name"
											value={displayName}
											onChange={(e) => {
												setFormData((o) => ({
													...o,
													checkpoints: o.checkpoints.map((_, j) => {
														if (i == j) {
															return { ..._, [e.target.name]: e.target.value };
														}
														return _;
													}),
												}));
											}}
										/>
										<Dropdown
											name="checkpointType"
											label="Checkpoint Type"
											value={checkpointTypeOptions.find((_) => _.value == checkpointType) || null}
											onChange={(opt, name) => {
												setFormData((o) => ({
													...o,
													checkpoints: o.checkpoints.map((_, j) => {
														if (i == j) {
															return { ..._, [name]: opt?.value || "" };
														}
														return _;
													}),
												}));
											}}
											options={checkpointTypeOptions}
										/>
										<AddRemove
											list={formData.checkpoints}
											filterMethod={(_) => _.displayName == "" && _.checkpointType == ""}
											onAdd={() => {
												setFormData((o) => ({ ...o, checkpoints: [...o.checkpoints, { displayName: "", checkpointType: "" }] }));
											}}
											onRemove={(i) => {
												setFormData((o) => ({ ...o, checkpoints: o.checkpoints.filter((_, j) => j != i) }));
											}}
											index={i}
											outerIndex={i}
										/>
									</div>
								);
							})}
						</div>
					</div>
				) : null}
				<div className="theme-container">
					<div className="flex-row-sb mb-2">
						<div style={{ fontWeight: "600" }}>Permissions</div>
						<Checkbox
							label="Select All"
							id="selectAllPerm"
							name="selectAllPerm"
							checked={Object.keys(formData?.permissions || {})?.length == Object.keys(orgPerms || {})?.length}
							onChange={handleCheckAllPerm}
						/>
					</div>
					<div className="perm_wrap">{perms}</div>
				</div>

				<div className="theme-container">
					<h6>Theme Setting</h6>
					<div className="form-inputs">
						<Input
							label="Title"
							placeholder="Enter Title"
							name="title"
							value={formData.title}
							error={errors?.title}
							onChange={(e) => handleThemeChange(e.target.name, e.target.value)}
							required={true}
						/>
						<UploadFileButton
							allowedExtensions=".jpg, .jpeg, .png"
							name="logo"
							fileName={formData.logo?.fileName}
							onChange={(file) => handleThemeChange("logo", file)}
							onChangeClear={() => handleThemeChange("logo", "")}
							id="uploadFileforLogo"
							buttonText="Upload Logo *"
							error={errors?.logo}
						/>
						<UploadFileButton
							allowedExtensions=".png, .ico"
							name="favIcon"
							fileName={formData.favIcon.fileName}
							onChange={(file) => handleThemeChange("favIcon", file)}
							onChangeClear={() => handleThemeChange("favIcon", "")}
							id="uploadFileforFavIcon"
							buttonText="Upload Favicon *"
							error={errors?.favIcon}
						/>
						{Object.keys(formData.statIcon).map((key, i) => {
							let statIcon = formData.statIcon[key];
							return (
								<UploadFileButton
									key={i}
									allowedExtensions=".jpg, .jpeg, .png, .svg"
									name={`statIcon.${key}`}
									fileName={statIcon.fileName}
									onChange={(file) => handleThemeChange(`statIcon.${key}`, file)}
									onChangeClear={() => handleThemeChange(`statIcon.${key}`, "")}
									id={`statIcon.${key}`}
									buttonText={`Upload ${capitalizeCamelCase(key)} Icon`}
								/>
							);
						})}
						<Checkbox
							name="selectedTheme"
							label="Dark Mode"
							checked={formData.theme.selectedTheme === "darkMode" ? true : false}
							onChange={(e) => handleThemeChange(e.target.name, e.target.checked, "darkMode")}
						/>
						<Checkbox
							name="selectedTheme"
							label="Light Mode"
							checked={formData.theme.selectedTheme === "lightMode" ? true : false}
							onChange={(e) => handleThemeChange(e.target.name, e.target.checked, "lightMode")}
						/>
						<ColorPicker name={"primaryColor"} onChange={(color) => handleThemeChange("primaryColor", color)} />
					</div>
				</div>

				<div className="theme-container">
					<h6>HHT IMEI</h6>
					<div style={{ display: "flex", gap: "0.5rem", flexDirection: "column" }}>
						{formData.hhtImei.map(({ imei }, i) => {
							return (
								<div key={i} style={{ display: "flex", gap: "1rem" }}>
									<Input
										name="imei"
										label="IMEI"
										value={imei}
										onChange={(e) => {
											setFormData((o) => ({
												...o,
												hhtImei: o.hhtImei.map((_, j) => {
													if (i == j) {
														return { ..._, [e.target.name]: e.target.value };
													}
													return _;
												}),
											}));
										}}
									/>
									<AddRemove
										list={formData.hhtImei}
										filterMethod={(_) => _.imei == ""}
										onAdd={() => {
											setFormData((o) => ({ ...o, hhtImei: [...o.hhtImei, { imei: "" }] }));
										}}
										onRemove={(i) => {
											setFormData((o) => ({ ...o, hhtImei: o.hhtImei.filter((_, j) => j != i) }));
										}}
										index={i}
										outerIndex={i}
									/>
								</div>
							);
						})}
					</div>
				</div>
			</form>
		</Dialog>
	);
}
