import { apiSlice } from "./api";

const devicesApi = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		addDeviceConfig: build.mutation({
			query: (body) => {
				return {
					url: "devices/add-config",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["device"],
		}),
		getDeviceConfig: build.mutation({
			query: (body) => {
				return {
					url: "devices/get-config",
					method: "post",
					body,
				};
			},
			providesTags: ["device"],
		}),
		getDefaultDeviceConfig: build.mutation({
			query: (body) => {
				return {
					url: "devices/get-default-config",
					method: "post",
					body,
				};
			},
		}),
		updateDeviceConfig: build.mutation({
			query: (body) => {
				return {
					url: "devices/update-config",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["device"],
		}),
		getDeviceLogs: build.mutation({
			query: (body) => {
				return {
					url: "devices/get-logs",
					method: "post",
					body,
				};
			},
		}),
		addTag: build.mutation({
			query: (body) => {
				return {
					url: "devices/tag/add-tag",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["tag"],
		}),
		uploadBulkTag: build.mutation({
			query: (body) => {
				return {
					url: "devices/tag/bulkUpload-tag",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["tag"],
		}),
		getTags: build.mutation({
			query: (body) => {
				return {
					url: "devices/tag/get-tag",
					method: "post",
					body,
				};
			},
			providesTags: ["tag"],
		}),
		getTagsMutation: build.mutation({
			query: (body) => {
				return {
					url: "devices/tag/get-tag",
					method: "post",
					body,
				};
			},
		}),
		updateTag: build.mutation({
			query: (body) => {
				return {
					url: "devices/tag/update-tag",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["tag"],
		}),
		deleteTag: build.mutation({
			query: (body) => {
				return {
					url: "devices/tag/delete-tag",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["tag"],
		}),
		getArchiveTags: build.query({
			query: (body) => {
				return {
					url: "devices/tag/get-archived-tags",
					method: "post",
					body,
				};
			},
			providesTags: ["archiveTag"],
		}),
		getAssetTag: build.mutation({
			query: (body) => {
				return {
					url: "vms/asset/get-assetTag",
					method: "post",
					body,
				};
			},
		}),
		addAssetTag: build.mutation({
			query: (body) => {
				return {
					url: "vms/asset/add-assetTag",
					method: "post",
					body,
				};
			},
		}),
		updateAssetTag: build.mutation({
			query: (body) => {
				return {
					url: "vms/asset/update-assetTag",
					method: "post",
					body,
				};
			},
		}),
		getTcpClients: build.mutation({
			query: (body) => {
				return {
					url: "devices/get-tcp-clients",
					method: "post",
					body,
				};
			},
		}),
	}),
	overrideExisting: false,
});

export const {
	useAddDeviceConfigMutation,
	useGetDeviceConfigMutation,
	useUpdateDeviceConfigMutation,

	useAddTagMutation,
	useUploadBulkTagMutation,
	useGetTagsMutation,
	useGetTagsMutationMutation,
	useUpdateTagMutation,
	useDeleteTagMutation,
	useGetArchiveTagsQuery,

	useAddAssetTagMutation,
	useGetAssetTagMutation,
	useUpdateAssetTagMutation,
	useGetDefaultDeviceConfigMutation,

	useGetDeviceLogsMutation,
	useGetTcpClientsMutation,
} = devicesApi;
