import React, { useEffect, useState } from "react";
import "./util.css";
import { useGetStatsMutation } from "../../api/api";
import { DeviceIcon, TagIcon, UsersIcon, CompanyIcon, ProductIcon, ProductCategoriesIcon, SupplierIcon, RoleIcon, ReportIcon, CheckpointTypeIcon, WorkFlowIcon } from "../../services/icons";
import { useGetDeviceStatsQuery, useGetUsersStatsQuery, useGetVMSStatsQuery } from "../../api/api";
import { useSocketCheckpointForDashboard, useThemeSetting } from "../../services/hooks";

export default function Genericstats({ onhandleFilter, checkpointID, filterType = "" }) {
	const [statData, setStatData] = useState([]);
	const { checkpointEvents } = useSocketCheckpointForDashboard();
	const { orgDetail } = useThemeSetting();

	const [getStatsTrigger, { data: statsData, error: statsError, isLoading: statsLoading }] = useGetStatsMutation();

	//TODO
	/**
     * Currently, the stat api returns data in the following format : 
     * {
            "totalVehicleRequest": 68,
            "totalTodayVehicleIn": 0,
            "totalTodayVehicleOut": 0
        }

        when we remove the vehicle number dependency, we'll have to do this as well
     */
	useEffect(() => {
		// If checkpointID is a string
		if (typeof checkpointID === "string") {
			getStatsTrigger({
				typesArr: ["All"],
				checkpointId: checkpointID,
				type: filterType,
				isCount: true,
			})
				.unwrap()
				.then((res) => {
					if (!res?.error) {
						const UpdatedStats = Object.keys(res?.data).map((key) => ({
							statName: capitalizeWords(key),
							statValue: typeof res?.data[key] !== "object" ? res?.data[key] : 0,
						}));
						setStatData(UpdatedStats);
					}
				})
				.catch((err) => {
					console.error(err);
				});
		}
	}, [checkpointID, checkpointEvents]);

	useEffect(() => {
		// Check if checkpointID is an array and has elements
		if (Array.isArray(checkpointID) && checkpointID.length > 0) {
			// Create an array to hold promises for each API call
			const promises = checkpointID.map(async (ele, index) => {
				try {
					// Fetch stats data for each checkpointID
					const res = await getStatsTrigger({
						typesArr: ["All"],
						checkpointId: ele,
						isCount: true,
					}).unwrap();
					// If response is successful
					if (!res?.error) {
						const UpdatedStats = Object.keys(res?.data).map((key) => ({
							statName: capitalizeWords(key),
							statValue: typeof res?.data[key] !== "object" ? res?.data[key] : 0,
						}));
						// Return different slices of UpdatedStats based on index
						if (index === 0) {
							return UpdatedStats.slice(0, 2);
						} else {
							return UpdatedStats.slice(2, 3);
						}
					}
				} catch (err) {
					console.error(err);
					return null; // Return null for failed promises
				}
			});
			// Resolve all promises
			Promise.all(promises)
				.then((result) => {
					// Flatten the array of arrays
					const flatResult = result.flat();
					// Filter out null values
					const filteredResult = flatResult.filter((item) => item !== null);
					// Set the state with the filtered result
					setStatData(filteredResult);
				})
				.catch((err) => {
					console.error(err);
				});
		}
	}, [checkpointEvents]);

	return (
		<>
			<div className="genericStats-containter">
				{statData.length > 0 &&
					statData.map((ele, index) => {
						return (
							<div className="genericStats-tab" key={ele.statName} onClick={(ele) => (onhandleFilter ? onhandleFilter(ele) : "")}>
								<div style={{ color: "#48494a" }}>
									{ele.statName === "Total Vehicle Request"
										? TotalTruckRequest(orgDetail?.statIcon?.total)
										: ele.statName === "Total Today Vehicle In"
										? TotalTruckIn(orgDetail?.statIcon?.todayIn)
										: ele.statName === "Total Today Vehicle Out"
										? TotalTruckOut(orgDetail?.statIcon?.todayOut)
										: ele.statName == "Total Currently In"
										? TotalCurrentIn()
										: null}
								</div>
								<div>
									<div className="genericStats-tab-point">{ele.statValue}</div>
									<div className="genericStats-tab-title">{ele.statName}</div>
								</div>
							</div>
						);
					})}
			</div>
		</>
	);
}

export function GenericstatsV2({ statsFor }) {
	const [statData, setStatData] = useState([]);
	const { data: DeviceStatsData = {}, refetch: DeviceStatsRefetch } = ["devices", "Tag"].includes(statsFor)
		? useGetDeviceStatsQuery({ statsFor: statsFor }, { refetchOnMountOrArgChange: true })
		: {};
	const { data: UsersStatsData = {}, refetch: UsersRefetech } = ["users", "company", "roles"].includes(statsFor)
		? useGetUsersStatsQuery({ statsFor: statsFor }, { refetchOnMountOrArgChange: true })
		: {};
	const { data: VMSStatsData = {}, refetch: VMSStatsRefetch } = ["product", "productCategories", "supplier", "workflow", "checkpoint", "checkpointType"].includes(statsFor)
		? useGetVMSStatsQuery({ statsFor: statsFor }, { refetchOnMountOrArgChange: true })
		: {};
	const { data: DeviceStats } = DeviceStatsData;
	const { data: UsersStats } = UsersStatsData;
	const { data: VMSStats } = VMSStatsData;

	useEffect(() => {
		if (DeviceStats) {
			const UpdatedStats = Object.keys(DeviceStats).map((key) => ({
				statName: capitalizeWords(key),
				statValue: typeof DeviceStats[key] !== "object" ? DeviceStats[key] : 0,
			}));
			setStatData(UpdatedStats);
		}
		if (UsersStats) {
			const UpdatedStats = Object.keys(UsersStats).map((key) => ({
				statName: capitalizeWords(key),
				statValue: typeof UsersStats[key] !== "object" ? UsersStats[key] : 0,
			}));
			setStatData(UpdatedStats);
		}
		if (VMSStats) {
			const UpdatedStats = Object.keys(VMSStats).map((key) => ({
				statName: capitalizeWords(key),
				statValue: typeof VMSStats[key] !== "object" ? VMSStats[key] : 0,
			}));
			setStatData(UpdatedStats);
		}
	}, [DeviceStats, UsersStats, VMSStats]);

	const fetchLogo = (statName) => {
		switch (statName) {
			case "Total Vehicle Request":
				return TotalTruckRequest();
			case "Total Today Vehicle In":
				return TotalTruckIn();
			case "Total Today Vehicle Out":
				return TotalTruckOut();
			case "Total Devices":
				return <DeviceIcon style={{ fontSize: "3.5em" }} />;
			case "Total Tags":
				return <TagIcon style={{ fontSize: "3.2em" }} />;
			case "Total Allocated Tags":
				return AllocatedTag();
			case "Total Users":
				return <UsersIcon style={{ fontSize: "3.3em" }} />;
			case "Total Roles":
				return <RoleIcon style={{ fontSize: "3.3em" }} />;
			case "Total Product Categories":
				return <ProductCategoriesIcon style={{ fontSize: "3.2em" }} />;
			case "Total Products":
				return <ProductIcon style={{ fontSize: "3.3em" }} />;
			case "Total Suppliers":
				return <SupplierIcon style={{ fontSize: "3.5em" }} />;
			case "Total Companies":
				return <CompanyIcon style={{ fontSize: "3.3em" }} />;
			case "Total Checkpoints":
				return <ReportIcon style={{ fontSize: "3.3em" }} />;
			case "Total Checkpoint Types":
				return <CheckpointTypeIcon style={{ fontSize: "3.3em" }} />;
			case "Total Workflows":
				return <WorkFlowIcon style={{ fontSize: "3.3em" }} />;
			default:
				null;
		}
	};
	return (
		<>
			<div className="genericStats-containter">
				{statData.length > 0 &&
					statData.map((ele, index) => (
						<div className="genericStats-tab" key={ele.statName} onClick={(ele) => (onhandleFilter ? onhandleFilter(ele) : "")}>
							<div style={{ color: "#48494a", marginRight: "5px" }}>{fetchLogo(ele.statName)}</div>
							<div>
								<div className="genericStats-tab-point">{ele.statValue}</div>
								<div className="genericStats-tab-title">{ele.statName}</div>
							</div>
						</div>
					))}
			</div>
		</>
	);
}

const capitalizeWords = (str) => {
	return str
		.split(" ")
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(" ")
		.split(/(?=[A-Z])/)
		.join(" ");
};

const TotalTruckRequest = (src) => {
	return src ? (
		<img src={src} height="64" width="64" />
	) : (
		<svg xmlns="http://www.w3.org/2000/svg" width="4em" height="4em" viewBox="0 0 24 24">
			<path
				fill="currentColor"
				d="M20 8h-3V4H1v13h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5zM6 18.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5s1.5.67 1.5 1.5s-.67 1.5-1.5 1.5M15 7h-2v7h-2V7H9v7H7V7H5v7H3V6h12zm3 11.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5s1.5.67 1.5 1.5s-.67 1.5-1.5 1.5M17 12V9.5h2.5l1.96 2.5z"
			/>
		</svg>
	);
};

const TotalTruckIn = (src) => {
	return src ? (
		<img src={src} height="64" width="64" />
	) : (
		<svg xmlns="http://www.w3.org/2000/svg" width="4em" height="4em" viewBox="0 0 24 24">
			<path
				fill="currentColor"
				d="M18 18.5c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5s.67 1.5 1.5 1.5m1.5-9H17V12h4.46zM6 18.5c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5s.67 1.5 1.5 1.5M20 8l3 4v5h-2c0 1.66-1.34 3-3 3s-3-1.34-3-3H9c0 1.66-1.34 3-3 3s-3-1.34-3-3H1V6c0-1.11.89-2 2-2h14v4zM3 6v9h.76c.55-.61 1.35-1 2.24-1s1.69.39 2.24 1H15V6zm2 4.5L6.5 9L8 10.5L11.5 7L13 8.5l-5 5z"
			/>
		</svg>
	);
};

const TotalTruckOut = (src) => {
	return src ? (
		<img src={src} height="64" width="64" />
	) : (
		<svg xmlns="http://www.w3.org/2000/svg" width="4em" height="4em" viewBox="0 0 24 24">
			<path
				fill="currentColor"
				d="M18 4h-5v9H1v4h2c0 .83.3 1.53.89 2.13c.61.59 1.3.87 2.11.87s1.5-.28 2.11-.87c.59-.6.89-1.3.89-2.13h5.5c0 .83.28 1.53.88 2.13c.59.59 1.29.87 2.12.87c.8 0 1.5-.28 2.09-.87c.6-.6.91-1.3.91-2.13H23v-7zM7.08 18.07c-.28.3-.64.43-1.08.43s-.8-.13-1.08-.43s-.42-.65-.42-1.07c0-.39.14-.74.42-1.04s.64-.46 1.08-.46s.8.16 1.08.46s.42.65.42 1.04c0 .42-.14.77-.42 1.07m11.46 0c-.3.3-.65.43-1.04.43c-.42 0-.77-.13-1.07-.43S16 17.42 16 17c0-.39.13-.74.43-1.04s.65-.46 1.07-.46c.39 0 .74.16 1.04.46s.46.65.46 1.04c0 .42-.16.77-.46 1.07M15 10V6h2.06l3.33 4z"
			/>
		</svg>
	);
};

const AllocatedTag = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="3.2em" height="3.2em" viewBox="0 0 24 24">
			<path
				fill="currentColor"
				d="m21.41 11.58l-9-9C12.04 2.21 11.53 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .53.21 1.04.59 1.41l.41.4c.9-.54 1.94-.81 3-.81c3.31 0 6 2.69 6 6c0 1.06-.28 2.09-.82 3l.4.4c.37.38.89.6 1.42.6s1.04-.21 1.41-.59l7-7c.38-.37.59-.88.59-1.41s-.21-1.04-.59-1.42M5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4S7 4.67 7 5.5S6.33 7 5.5 7m3.13 7.27l-3.87 3.9l-1.35-1.37L2 18.22L4.75 21l5.28-5.32z"
			/>
		</svg>
	);
};

const TotalCurrentIn = (src) => {
	return src ? (
		<img src={src} height="64" width="64" />
	) : (
		<svg width="4em" height="4em" viewBox="0 0 612 612" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000" version="1.1" id="Capa_1" xml:space="preserve">
			<g>
				<g>
					<path
						fill="currentColor"
						d="M226.764,375.35c-28.249,0-51.078,22.91-51.078,51.16c0,28.166,22.829,51.078,51.078,51.078s51.078-22.912,51.078-51.078    C277.841,398.26,255.013,375.35,226.764,375.35z M226.764,452.049c-14.125,0-25.54-11.498-25.54-25.541    c0-14.123,11.415-25.539,25.54-25.539c14.124,0,25.539,11.416,25.539,25.539C252.302,440.551,240.888,452.049,226.764,452.049z     M612,337.561v54.541c0,13.605-11.029,24.635-24.636,24.635h-26.36c-4.763-32.684-32.929-57.812-66.927-57.812    c-33.914,0-62.082,25.129-66.845,57.812H293.625c-4.763-32.684-32.93-57.812-66.845-57.812c-33.915,0-62.082,25.129-66.844,57.812    h-33.012c-13.606,0-24.635-11.029-24.635-24.635v-54.541H612L612,337.561z M494.143,375.35c-28.249,0-51.16,22.91-51.16,51.16    c0,28.166,22.912,51.078,51.16,51.078c28.166,0,51.077-22.912,51.077-51.078C545.22,398.26,522.309,375.35,494.143,375.35z     M494.143,452.049c-14.125,0-25.539-11.498-25.539-25.541c0-14.123,11.414-25.539,25.539-25.539    c14.042,0,25.539,11.416,25.539,25.539C519.682,440.551,508.185,452.049,494.143,452.049z M602.293,282.637l-96.817-95.751    c-6.159-6.077-14.453-9.526-23.076-9.526h-48.86v-18.313c0-13.631-11.004-24.635-24.635-24.635H126.907    c-13.55,0-24.635,11.005-24.635,24.635v3.86L2.3,174.429l177.146,23.068L0,215.323l178.814,25.423L0,256.25l102.278,19.29    l-0.007,48.403h509.712v-17.985C611.983,297.171,608.452,288.796,602.293,282.637z M560.084,285.839h-93.697    c-2.135,0-3.86-1.724-3.86-3.859v-72.347c0-2.135,1.725-3.86,3.86-3.86h17.82c0.985,0,1.971,0.411,2.71,1.068l75.796,72.347    C565.257,281.569,563.532,285.839,560.084,285.839z"
					/>
				</g>
			</g>
		</svg>
	);
};
