import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetOrgsQuery, useGetCurrentUserDetailQuery, useGetUsersMutation, useGetRolesMutation, useGetAllPermissionSetQuery, useUpdateThemeMutation } from "../api/userSlice";
import {
	useGetVehiclesQuery,
	useGetSuppliersMutation,
	useGetProductsMutation,
	useGetProductCategoriesMutation,
	useGetCheckPointQuery,
	useGetTemplateDetailQuery,
	useLazyGetCheckpointDataListQuery,
	useGetDashboardStatsMutation,
	useGetCheckpointTypeMutation,
	useGetDynamicModuleMutation,
	useGetDynamicModuleEntryMutation,
	useGetTemplatesMutation,
	useGetCheckPointOnCallMutation,
	useGetWorkflowListingQuery,
} from "../api/vmsSlice";
import { useGetThemeQuery } from "../api/userSlice";
import { useGetAssetTagMutation, useGetDeviceConfigMutation, useGetDeviceLogsMutation, useGetTagsMutation, useGetTcpClientsMutation } from "../api/deviceSlice";
import { socket } from "../socket";
import { toastMessage, dismissToast } from "../Components/utils";
import { setFavicon, setTitle, customAPI, saveAsExcel } from "./functions";
import * as serviceWorkerRegistration from "../serviceWorkerRegistration";
import { toast } from "react-toastify";

//device
function useDevices(filter) {
	const [getDevices, { data, isLoading }] = useGetDeviceConfigMutation({ fixedCacheKey: "getDevices" });

	useEffect(() => {
		if (filter) getDevices(filter);
	}, []);

	let { data: devices, count, message, error } = data || { data: [] };

	if (!devices) devices = [];
	return { devices, count, message, isLoading, getDevices };
}
function useDeviceLogs(filter) {
	const [getDeviceLogs, { data, isLoading }] = useGetDeviceLogsMutation({ fixedCacheKey: "getDevicesLogs" });

	useEffect(() => {
		if (filter) getDeviceLogs(filter);
	}, []);

	let { data: logs, count, message, error } = data || { data: [] };

	if (!logs) logs = [];
	return { logs, count, message, isLoading, getDeviceLogs };
}
function useTags(filter, cacheKey = "getTags") {
	const [getTags, { data, isLoading }] = useGetTagsMutation({ fixedCacheKey: cacheKey });
	useEffect(() => {
		if (filter) getTags(filter);
	}, []);

	let { data: tags, count, message } = data || { data: [] };
	if (!tags) tags = [];
	const tagOptions = tags.map((_) => ({ label: _.displayName, value: _._id, data: _ }));
	return { tags, count, message, isLoading, getTags, tagOptions };
}
function useTagsForScan(filter) {
	const [fetchData, { data: rtkData, isLoading, reset }] = useGetTagsMutation({ fixedCacheKey: "getTagsForScan" });
	useEffect(() => {
		if (filter) fetchData(filter);
	}, []);

	let { data: scannedTags, count, message } = rtkData || { data: [] };
	if (!scannedTags) scannedTags = [];
	return {
		scannedTags,
		count,
		message,
		isLoading,
		getTagsScan: fetchData,
		clearData: () => {
			reset();
		},
	};
}
function useAssetTags(filter) {
	const [fetchData, { data: rtkData, isLoading, reset }] = useGetAssetTagMutation({ fixedCacheKey: "getAssetTags" });
	useEffect(() => {
		if (filter) fetchData(filter);
	}, []);

	let { data: assetTags, count, message } = rtkData || { data: [] };
	if (!assetTags) assetTags = [];
	return {
		assetTags,
		count,
		message,
		isLoading,
		getAssetTags: fetchData,
		clearData: () => {
			reset();
		},
	};
}
//user
function useUsers(filter) {
	const [getUsers, { data: _data, isLoading }] = useGetUsersMutation({ fixedCacheKey: "getUsers" });
	useEffect(() => {
		if (filter) getUsers(filter);
	}, []);

	let { data: users, count, message, error } = _data || { data: [] };
	if (!users) users = [];
	const userOptions = users.map((_) => ({ label: _.name, value: _._id })); //[{label: "--select--", value: ""}]

	return { getUsers, users, count, message, isLoading, userOptions };
}
function useOrgs(filter = {}) {
	const { data, refetch, isLoading, isFetching } = useGetOrgsQuery(filter, {
		refetchOnMountOrArgChange: true,
	});

	let { data: orgs, count, message, error } = data || { data: [] };
	if (!orgs) orgs = [];
	const orgOptions = [
		// { label: "--select--", value: "" },
		...(orgs?.map((org) => {
			return { label: org.name, value: org._id };
		}) || []),
	];
	return { orgs, count, message, isLoading: isFetching, refetch, orgOptions };
}
function getWorkflow(filter = {}) {
	const { data, refetch, isLoading, isFetching } = useGetWorkflowListingQuery(filter, { refetchOnMountOrArgChange: true });
	let { data: workflowListing = [], count, message, error } = data || { data: [] };
	return { workflowListing, message, error, refetch, isLoading, count };
}
function useAllPermissionSet() {
	const { data, refetch, isFetching } = useGetAllPermissionSetQuery(
		{ project: "lynkid" },
		{
			refetchOnMountOrArgChange: true,
		}
	);

	const { data: permData, message, error } = data || { data: [] };

	return {
		permissions: permData.reduce((res, curr) => {
			res[curr.module] = curr.permissions || {};
			return res;
		}, {}),
		refetch,
		isLoading: isFetching,
	};
}

function useUserPermissions() {
	const { userDetail, isUserDetailSuccess } = useUserDetail();
	const userPermissions =
		userDetail?.userPermissions?.reduce((acc, curr) => {
			return { ...acc, ...curr.permissions };
		}, {}) || {};

	userPermissions && Object.keys(userPermissions).length && userPermissions.offlineAccess?.value ? localStorage.setItem("offlinePermission", true) : localStorage.setItem("offlinePermission", false);
	return { userPermissions, isFetched: isUserDetailSuccess, isRespose: Object.keys(userPermissions).length };
}

function useAuth() {
	const { userDetail, statusCode, isUserDetailSuccess } = useUserDetail();
	const { userPermissions, isRespose, isFetched } = useUserPermissions();
	const { checkpoints, isSuccess, isCheckpointFetched } = useCheckpoints({});

	if (isUserDetailSuccess) {
		if (statusCode == 401) return "/not-authorized";
		const { userType } = userDetail || {};
		if (userType == "superAdmin") return "/devices";

		if (isFetched) {
			if (userPermissions.getDashboard?.value) {
				return "/dashboard";
			} else if (userPermissions.getDashboardV2?.value) {
				return "/dashboard";
			}
			// welcome
			if (!isRespose || !Object.keys(userPermissions).length) return "/not-authorized";

			if (isCheckpointFetched) {
				if (!checkpoints.length) return "/welcome";
				// find any checkpoint permission : "checkpoint-checkpointId"
				const checkpointPerm = Object.keys(userPermissions).find((key) => key.includes("checkpoint"));
				// console.log({ checkpointPerm });
				if (!checkpointPerm) return "/welcome";
				let [, checkpointId] = checkpointPerm.split("-");
				const userCheckpoint = checkpoints.find((_) => _._id == checkpointId);
				if (!userCheckpoint) return "/welcome";

				const { checkpointType, checkpointNumber } = userCheckpoint;

				// valid checkpoint and user has permission -> show the checkpoint page
				return `/checkpoints/${checkpointType}/${checkpointNumber}`;
			}
		}
	}
}
function useRoles(filter) {
	const [getRoles, { data, isLoading }] = useGetRolesMutation({ fixedCacheKey: "getRoles" });

	useEffect(() => {
		if (filter) getRoles(filter);
	}, []);

	const { data: roles, count } = data || { data: [] };
	const roleOptions = [...(roles?.map((_) => ({ label: _.displayName, value: _.role, data: { _id: _._id, checkpoints: _.checkpoints } })) || [])];

	return { roleOptions, roles, getRoles, isLoading, count };
}

function useUserDetail() {
	const { data, isSuccess, isFetching } = useGetCurrentUserDetailQuery({});
	const { data: userDetail, statusCode } = data || {};
	return { userDetail, statusCode, isUserDetailSuccess: isSuccess, isFetching };
}

//vms
function useVehicles(filter = {}) {
	const { data, refetch, isLoading, isFetching } = useGetVehiclesQuery(filter, {
		refetchOnMountOrArgChange: true,
	});

	let { data: vehicles, count, message, error } = data || { data: [] };
	if (!vehicles) vehicles = [];
	return { vehicles, count, message, isLoading: isFetching, refetch };
}
function useSuppliers(filter) {
	const [getSuppliers, { data, isLoading }] = useGetSuppliersMutation({ fixedCacheKey: "getSuppliers" });
	useEffect(() => {
		if (filter) getSuppliers(filter);
	}, []);

	let { data: suppliers, count, message } = data || { data: [] };
	if (!suppliers) suppliers = [];
	return { suppliers, count, message, isLoading, getSuppliers };
}

function useProducts(filter) {
	const [getProducts, { data, isLoading }] = useGetProductsMutation({ fixedCacheKey: "getProducts" });
	useEffect(() => {
		if (filter) getProducts(filter);
	}, []);
	let { data: products, count, message, error } = data || { data: [] };
	if (!products) products = [];
	return { products, count, message, isLoading, getProducts };
}
function useProductCategories(filter) {
	const [getProdCats, { data, isLoading }] = useGetProductCategoriesMutation({ fixedCacheKey: "getProdCats" });
	useEffect(() => {
		if (filter) getProdCats(filter);
	}, []);

	let { data: productCategories, count, message, error } = data || { data: [] };
	if (!productCategories) productCategories = [];
	const options = useMemo(
		() => [
			{ label: "--select--", value: "" },
			...(productCategories?.map((_) => ({
				label: _.productCategory,
				value: _._id,
			})) || []),
		],
		[productCategories]
	);

	return { productCategories, count, message, isLoading, options, getProdCats };
}
function useDynamicModules(filter, fixedCacheKey = "dynamicModule") {
	const [fetchData, { data, isLoading }] = useGetDynamicModuleMutation({ fixedCacheKey });
	useEffect(() => {
		if (filter) fetchData(filter);
	}, []);

	let { data: dynamicModules, count, message } = data || { data: [] };
	if (!dynamicModules) dynamicModules = [];
	return { dynamicModules, count, message, isLoading, getDynamicModules: fetchData };
}
function useDynamicModuleEntries(filter) {
	const [fetchData, { data, isLoading }] = useGetDynamicModuleEntryMutation({ fixedCacheKey: "dynamicModuleEntry" });
	useEffect(() => {
		if (filter) fetchData(filter);
	}, []);

	let { data: dynamicModuleEntries, count, message, tableHeader = [] } = data || { data: [], tableHeader: [] };
	if (!dynamicModuleEntries) dynamicModuleEntries = [];
	return { dynamicModuleEntries, count, message, isLoading, getDynamicModuleEntries: fetchData, tableHeader };
}
//https://redux-toolkit.js.org/rtk-query/usage/cache-behavior#encouraging-re-fetching-with-refetchonmountorargchange
function useCheckpointsOld(filter = {}) {
	const { data, refetch, isLoading, isFetching, isSuccess } = useGetCheckPointQuery(filter, {
		refetchOnMountOrArgChange: 5,
	});
	let { data: checkpoints, count, message, error } = data || { data: [] };
	if (!checkpoints) checkpoints = [];
	checkpoints = [...checkpoints].sort((a, b) => a.checkpointNumber - b.checkpointNumber);

	const checkpointOptions = checkpoints.map((_) => ({ label: _.displayName, value: _._id }));
	return { checkpoints, refetch, isLoading: isFetching, checkpointOptions, isSuccess: error == false, isCheckpointFetched: isSuccess };
}
function useCheckpoints(filter, fixedCacheKey = "checkpoints") {
	const [getCheckpoints, { data, isLoading, isFetching, isSuccess, reset }] = useGetCheckPointOnCallMutation({ fixedCacheKey });

	let { checkpoints, count, error } = useMemo(() => {
		let { data: checkpoints, count, error } = data || { data: [] };
		if (!checkpoints) checkpoints = [];
		checkpoints = [...checkpoints].sort((a, b) => a.checkpointNumber - b.checkpointNumber);
		return { checkpoints, count, error };
	}, [data]);
	const checkpointOptions = checkpoints.map((_) => ({ label: _.displayName, value: _._id }));

	useEffect(() => {
		if (filter) getCheckpoints(filter);
	}, []);

	return { checkpoints, checkpointOptions, getCheckpoints, clearCheckpointData: () => reset(), count, isCheckpointFetched: isSuccess, isLoading, isSuccess: error == false };
}
function useCheckpointWithExtra(filter = {}) {
	const { data, refetch, isLoading, isFetching, isSuccess } = useGetCheckPointQuery(filter, {
		refetchOnMountOrArgChange: 5,
	});
	let { data: checkpoints, count, message, error } = data || { data: [] };
	// console.log({ data });

	if (!checkpoints) checkpoints = [];
	checkpoints = [...checkpoints].sort((a, b) => a.checkpointNumber - b.checkpointNumber);
	// console.log(checkpoints.map((_) => _.checkpointNumber));
	// console.log();
	const checkpointOptions = checkpoints.map((_) => ({ label: _.displayName, value: _.checkpointNumber, checkpointNumber: _.checkpointNumber }));
	// const checkpointOptions = checkpoints.map((_) => ({ label: _.displayName, value: _._id, checkpointNumber: _.checkpointNumber }));
	// console.log(checkpoints);
	return { checkpoints, isLoading: isFetching, checkpointOptions, isSuccess: error == false, isCheckpointFetched: isSuccess };
}

function useTemplateDetail(filter = {}) {
	const { data, refetch, isLoading, isFetching, isSuccess } = useGetTemplateDetailQuery(filter, {
		refetchOnMountOrArgChange: true,
	});
	let { data: templateDetail, count, message, error } = data || { data: { attributes: [] } };
	return { templateDetail, isLoading: isFetching, isSuccess, error };
}
function useTemplateList(filter) {
	const [fetchData, { data, isLoading }] = useGetTemplatesMutation({ fixedCacheKey: "templateList" });
	useEffect(() => {
		if (filter) fetchData(filter);
	}, []);

	let { data: templates, count, message, error } = data || { data: [] };
	if (!templates) templates = [];
	const templateOptions = [
		...(templates?.map((_) => {
			return { label: _.name, value: _._id };
		}) || []),
	];
	return { templates, count, message, isLoading, getTemplateList: fetchData, templateOptions };
}
function useCheckpointData(filter = {}) {
	const [trigger, { data, refetch, isLoading, isFetching, isSuccess }] = useLazyGetCheckpointDataListQuery(filter, {
		refetchOnMountOrArgChange: true,
	});
	let { data: checkpointData, count, message, error, tableHeader = [] } = data || { data: [], tableHeader: [] };
	return { trigger, checkpointData, tableHeader, isLoading: isFetching, isSuccess, refetch, count, message, error };
}

// util
function useClickOutside(ref, callback) {
	const handleClick = (e) => {
		if (ref.current && !ref.current.contains(e.target)) {
			callback();
		}
	};
	useEffect(() => {
		document.addEventListener("click", handleClick);
		return () => document.removeEventListener("click", handleClick);
	});
}

function useSocketCheckpointForDashboard() {
	const [checkpointEvents, setCheckpointEvents] = useState([]);
	useEffect(() => {
		function onCheckpointEvent(eventMsg) {
			const { type, data } = eventMsg;
			if (type === "new_notification") {
				// console.log("notig", data);
				const updatedData = data.map((ele) => {
					const theme = localStorage.getItem("selectedTheme");
					return { ...ele, outTime: null, bgColor: theme === "darkMode" ? "#3d533d" : "#e6ffe6" };
				});
				setCheckpointEvents((old) => [...old, ...updatedData]);
			}
		}
		socket.on("new_notification", onCheckpointEvent);

		return () => {
			socket.off("new_notification", onCheckpointEvent);
		};
	}, []);
	return { checkpointEvents };
}

function useSocketCheckpoint(currentCheckpoint) {
	const [checkpointEvents, setCheckpointEvents] = useState([]);
	useEffect(() => {
		setCheckpointEvents([]);
		function onCheckpointEvent(eventMsg) {
			const { type, data } = eventMsg;
			const theme = localStorage.getItem("selectedTheme");
			// console.log("notig", data, currentCheckpoint);
			if (type === "new_notification" && currentCheckpoint == data[0]?.current_checkpoint_no) {
				const updatedData = data.map((ele) => {
					return { ...ele, outTime: null, bgColor: theme === "darkMode" ? "#3d533d" : "#e6ffe6" };
				});
				setCheckpointEvents((old) => [...old, ...updatedData]);
			} else if (type === "range-checkout") {
				let oldEventExists = false;
				setCheckpointEvents((old) => {
					let fauxOld = JSON.parse(JSON.stringify(old));
					fauxOld = fauxOld.map((trip) => {
						if (trip._id == data[0]?._id) {
							oldEventExists = true;
							return {
								...trip,
								...data[0],
								SOCKET_EVENT_TYPE: type,
								bgColor: theme === "darkMode" ? "#3d533d" : "#e6ffe6",
							};
						}
						return trip;
					});
					return fauxOld;
				});
				if (!oldEventExists) {
					setCheckpointEvents((old) => [
						...old,
						...data.map((ele) => {
							return { ...ele, SOCKET_EVENT_TYPE: type, bgColor: theme === "darkMode" ? "#3d533d" : "#e6ffe6" };
						}),
					]);
				}
			} else {
				// console.log("inner clear")
				// setCheckpointEvents([]);
			}
		}
		socket.on("new_notification", onCheckpointEvent);

		return () => {
			socket.off("new_notification", onCheckpointEvent);
		};
	}, [currentCheckpoint]);
	return { checkpointEvents };
}

function useSocketTagMapping() {
	const [scanData, setScanData] = useState({});
	useEffect(() => {
		function onCheckpointEvent(eventMsg) {
			const { type, data } = eventMsg;
			if (type === "tag_mapping") {
				// console.log("tag_mapping22", data);
				setScanData(data);
			}
		}
		socket.on("new_notification", onCheckpointEvent);

		return () => {
			socket.off("new_notification", onCheckpointEvent);
		};
	}, []);
	return { scanData };
}
function useSocket() {
	const [isConnected, setIsConnected] = useState(socket.connected);
	const { userDetail } = useUserDetail();
	const navigate = useNavigate();
	if (!sessionStorage.getItem("deviceId")) {
		sessionStorage.setItem("deviceId", guidGenerator());
	}
	const toastId = useRef(null);
	// console.log({ socket, userDetail });
	useEffect(() => {
		if (!userDetail) return;
		// console.log("socket mount", socket.connected);

		if (socket.connected) {
			emitLogin();
		}

		function onConnect() {
			setIsConnected(true);
			// console.log("socket connected");
			emitLogin();
		}

		function onDisconnect() {
			setIsConnected(false);
			// console.log("socket disconnected");
		}

		function emitLogin() {
			const data = {
				userId: userDetail?.email,
				orgId: userDetail?.orgId,
				userRoleId: userDetail?.userRoleId,
				userType: userDetail?.userType,
				deviceId: sessionStorage.getItem("deviceId") || guidGenerator(),
			};
			// console.log("Login emit", data);
			socket.emit("user_login", data);
		}

		socket.on("connect", onConnect);
		socket.on("disconnect", onDisconnect);

		function onNewNotification(eventMsg) {
			let { type, data } = eventMsg;
			// console.log(type, " ---> EVENT FIRED ");
			switch (type) {
				case "vehicle-entering-alert":
					onNewVehicleAlert(data);
					break;
				case "excel-report-download":
					onDownloadExcel(data);
					break;
				// case "asset-tag-scan":

				// 	break;
				// default:
				// 	console.error(`Unhandled Socket Event Type : ${type}`);
			}
		}

		socket.on("new_notification", onNewNotification);

		function onNewVehicleAlert(data) {
			// console.log("Socket event --> ", data);
			if (data[0] && data[0].next_checkpoint?.length) {
				let chkpnts = "";
				data[0].next_checkpoint.forEach((pnt) => (chkpnts += ` ${pnt.displayName} /`));
				chkpnts = chkpnts.slice(0, chkpnts.length - 1);
				const go = (data) => {
					// console.log("clicked");
					if (data) {
						navigate("/manage-trip", { state: { data } });
					}
				};
				const msg = (
					<div style={{ cursor: "pointer" }} onClick={() => go(data[0].data)}>
						{`Vehicle ${data[0]?.data?.vehicleNumber || ""} arriving at - ${chkpnts}`}
					</div>
				);
				toastMessage(true, msg);
			} else {
				// console.log("Empty data in socket event");
			}
		}
		// socket.on("vehicle-entering-alert", onNewVehicleAlert);

		function onDownloadExcel(data) {
			// console.log(toastId.current)
			if (data.type === "excel-downloading") {
				toastId.current = toastMessage("loading", "Your report is in progress will download soon..", { position: "bottom-right", autoClose: 300000, closeOnClick: true });
				return;
			} else if (data.type === "excel-error") {
				dismissToast(toastId.current);
				return;
			} else {
				// console.log("received excel data --> ", data)
				dismissToast(toastId.current);
				saveAsExcel(data.data, "report");
				toastMessage(true, "Report Downloaded", { position: "bottom-right" });
			}
		}
		// socket.on("excel-report-download", onDownloadExcel);

		return () => {
			// console.log("socket unmount");
			socket.off("connect", onConnect);
			socket.off("disconnect", onDisconnect);
		};
	}, [userDetail]);
}
function useDeviceSocket() {
	const [deviceData, setDeviceData] = useState({});
	useEffect(() => {
		function onEvent(eventMsg) {
			const { type, data } = eventMsg;
			// console.log({ type, data });
			if (type === "device-status") {
				// console.log(data);
				let newData = { ...deviceData, [data.deviceId]: data.deviceStatus };
				setDeviceData(newData);
			}
		}
		socket.on("new_notification", onEvent);

		return () => {
			socket.off("new_notification", onEvent);
		};
	}, []);
	return { deviceData };
}
function useAssetTagSocket(setScanData) {
	useEffect(() => {
		function onEvent(eventMsg) {
			const { type, data } = eventMsg;
			// console.log({ type, data });
			if (type === "asset-tag-scan") {
				// console.log({ newData });
				// console.log("NEWDATA");
				setScanData((o) => {
					if (o.find((_) => _.epc == data.epc)) {
						return o;
					} else {
						return [{ ...data, type: "scanned" }, ...o];
					}
				});
			}
		}
		socket.on("new_notification", onEvent);

		return () => {
			socket.off("new_notification", onEvent);
		};
	}, []);
}

function useApplytheme() {
	const { data, refetch, isLoading, isFetching, isSuccess } = useGetThemeQuery(
		{},
		{
			refetchOnMountOrArgChange: true,
		}
	);
	let { data: orgDetail, count, message, error } = data || { data: {} };
	// console.log('orgDetail-theme', orgDetail)
	let primaryColor = localStorage.getItem("primaryColor") ? localStorage.getItem("primaryColor") : "#ff7200";
	let selectedTheme = localStorage.getItem("selectedTheme") ? localStorage.getItem("selectedTheme") : "lightMode";

	if (orgDetail?.theme) {
		const { primaryColor: primary, selectedTheme: theme } = orgDetail?.theme;
		primaryColor = primary;
		selectedTheme = theme;
		localStorage.setItem("primaryColor", primary);
		localStorage.setItem("selectedTheme", theme);
	}

	themeMode(selectedTheme, primaryColor);

	if (orgDetail?.favIcon) {
		const faviconFileData = orgDetail?.favIcon;
		window.localStorage.setItem("favicon", faviconFileData);
		setFavicon(faviconFileData);
	}
	useEffect(() => {
		setFavicon(localStorage.getItem("favicon") || "/favicon.ico"); // Replace with the actual path or URL of your favicon
		return () => {
			const existingFavicon = document.getElementById("dynamic-favicon");
			if (existingFavicon) {
				document.head.removeChild(existingFavicon);
			}
		};
	}, []);

	// add title
	useEffect(() => {
		// Function to set the title dynamically
		setTitle(orgDetail?.title || "LynkID");
		// Cleanup function (optional)
		return () => {
			// You can reset the title to its original value here if needed
			setTitle("LynkID");
		};
	}, [orgDetail?.title]);

	return { logo: orgDetail?.logo };
}

function hexToRgba(hex, opacity) {
	// Remove the leading '#' if it's there
	hex = hex.replace(/^#/, "");

	// Parse the r, g, b values
	let r = parseInt(hex.substring(0, 2), 16);
	let g = parseInt(hex.substring(2, 4), 16);
	let b = parseInt(hex.substring(4, 6), 16);

	// Return the RGBA color
	return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export const themeMode = (selectedTheme, primaryColor) => {
	if (selectedTheme == "darkMode") {
		document.documentElement.style.setProperty("--set-primary-color", primaryColor);
		/* default theme */
		document.documentElement.style.setProperty("--set-navbar-bg-color", "#292727");
		document.documentElement.style.setProperty("--set-background-color", "#121212");
		document.documentElement.style.setProperty("--set-text-color", "#ffffff");
		/* input-field */
		document.documentElement.style.setProperty("--set-input-placeholder-color", "#ffffff");
		/* table */
		document.documentElement.style.setProperty("--set-table-inner-header-bg-color", hexToRgba(primaryColor, 0.5));
		document.documentElement.style.setProperty("--set-table-header-bg-color", "rgb(56, 56, 56)");
		document.documentElement.style.setProperty("--set-table-header-text-color", "#ffffff");
		document.documentElement.style.setProperty("--set-table-body-bg-color", "#1d1b1b");
		document.documentElement.style.setProperty("--set-table-pagination-bg", "rgb(56, 56, 56)");

		/* page  */
		document.documentElement.style.setProperty("--set-heading-text-color", "#ffffff");
		document.documentElement.style.setProperty("--set-heading-text-bold", "500");
		document.documentElement.style.setProperty("--set-heading-text-color", "#ffffff");
		/* sidebar */
		document.documentElement.style.setProperty("--set-sidebar-bg-color", "#ffffff");
		document.documentElement.style.setProperty("--set-sidebar-hover-bg-color", "#72737794");
		document.documentElement.style.setProperty("--set-icon-color", "#ffffff");

		//    /* outlinebtn-button */
		document.documentElement.style.setProperty("--set-lynkitBlackEmpty-color", "var(--gray-color)");
		document.documentElement.style.setProperty("--set-lynkitBlackEmpty-Hvr-color", "var(--gray-color)");
		document.documentElement.style.setProperty("--set-outlinebtn-border", "2px");
		document.documentElement.style.setProperty("--set-lynkitBlackEmpty-border-color", "var(--dark-gray-color)");
		document.documentElement.style.setProperty("--set-lynkitBlackEmpty-border-Hvr-color", "var(--dark-gray-color)");
		document.documentElement.style.setProperty("--set-lynkitBlackEmpty-border-Hvr-bg-Color", "var(--dark-gray-color)");
		document.documentElement.style.setProperty("--set-lynkitBlackFill-bg-color", "var(--dark-gray-color)");
		document.documentElement.style.setProperty("--set-lynkitBlackFill-bg-Hvr-color", "var(--dark-gray-color)");
		/* dropdown */
		document.documentElement.style.setProperty("--set-option-hover-color", "#42484d");
		//    /* modal */
		document.documentElement.style.setProperty("--set-model-bg-color", "rgb(56, 56, 56)");
	}
	if (selectedTheme == "lightMode") {
		document.documentElement.style.setProperty("--set-primary-color", primaryColor);
		document.documentElement.style.setProperty("--set-navbar-bg-color", "#ffffff");
		document.documentElement.style.setProperty("--set-background-color", "#ffffff");
		document.documentElement.style.setProperty("--set-text-color", "#000000");
		/* input-field */
		document.documentElement.style.setProperty("--set-input-placeholder-color", "#000000");
		/* table */
		document.documentElement.style.setProperty("--set-table-inner-header-bg-color", hexToRgba(primaryColor, 0.2));
		document.documentElement.style.setProperty("--set-table-header-bg-color", "#D9D9D9");
		document.documentElement.style.setProperty("--set-table-header-text-color", "rgb(69, 90, 100)");
		document.documentElement.style.setProperty("--set-table-body-bg-color", "#ffffff");
		document.documentElement.style.setProperty("--set-table-pagination-bg", "#ffffff");

		/* page  */
		document.documentElement.style.setProperty("--set-heading-text-color", "#000000");
		document.documentElement.style.setProperty("--set-heading-text-bold", "bold");
		document.documentElement.style.setProperty("--set-heading-text-color", "#000000");
		/* sidebar */
		document.documentElement.style.setProperty("--set-sidebar-bg-color", "#ffffff");
		document.documentElement.style.setProperty("--set-sidebar-hover-bg-color", "rgba(0, 0, 0, 0.04)");
		document.documentElement.style.setProperty("--set-icon-color", "rgba(0, 0, 0, 0.54)");

		//    /* outlinebtn-button */
		document.documentElement.style.setProperty("--set-lynkitBlackEmpty-color", "#000000");
		document.documentElement.style.setProperty("--set-lynkitBlackEmpty-Hvr-color", "#ffffff");
		document.documentElement.style.setProperty("--set-outlinebtn-border", "1px");
		document.documentElement.style.setProperty("--set-lynkitBlackEmpty-border-color", "#000000");
		document.documentElement.style.setProperty("--set-lynkitBlackEmpty-border-Hvr-color", "#000000");
		document.documentElement.style.setProperty("--set-lynkitBlackEmpty-border-Hvr-bg-Color", "#000000");
		document.documentElement.style.setProperty("--set-lynkitBlackFill-bg-color", "#000000");
		document.documentElement.style.setProperty("--set-lynkitBlackFill-bg-Hvr-color", "#000000");

		/* dropdown */
		document.documentElement.style.setProperty("--set-option-hover-color", "#d0d1d3");
		//    /* modal */
		document.documentElement.style.setProperty("--set-model-bg-color", "#ffffff");
	}
};

function useWindowOpener() {
	let location = useLocation();
	const navigate = useNavigate();
	let searchParams = new URLSearchParams(location.search);
	let authParam = searchParams.get("auth");
	let actionParam = searchParams.get("action");
	// console.log('searchParams', location);
	let windowType;
	if (authParam) {
		if (window.opener) {
			// console.log('opener opennnn===')
			windowType = "windowOpener";
			window.opener.postMessage({ perkey: "sendphrase" }, "*");
		} else {
			// console.log('iframe opennnn===');
			windowType = "windowIframe";
			window.parent.postMessage(JSON.stringify({ perkey: "sendphrase" }), "*");
			// Set an item in the localStorage with the key "showFrame" and the value 'true'
		}
	}
	window.addEventListener("message", (evt) => {
		// console.log("hello parent",evt);
		if (evt.data.phrase) {
			// if (!window.opener) {
			//   localStorage.setItem("showFrame", 'true')
			// }
			if (["lynktrac", "LYNKID", "WMS"].includes(authParam)) {
				localStorage.setItem("windowType", windowType);
				localStorage.setItem("project", authParam);
				localStorage.setItem("activeURL", actionParam);
				localStorage.setItem("secretkey", evt.data.phrase);
				return navigate(actionParam);
			}
		}
	});
}

function useCheckInternet() {
	const [isOnline, setOnlineStatus] = useState(navigator.onLine);
	const navigate = useNavigate();

	useEffect(() => {
		const handleOffline = () => {
			// console.log("offline=========>", localStorage.getItem("offlinePermission"));
			if (localStorage.getItem("offlinePermission") == "true") {
				setOnlineStatus(false);
				navigate("/offline");
			}
		};

		const handleOnline = () => {
			// console.log("online=========>");
			setOnlineStatus(true);
			// Optionally, you can navigate back to the previous page when online
			// window.history.back();
		};

		window.addEventListener("offline", handleOffline);
		window.addEventListener("online", handleOnline);

		// Cleanup function to remove event listeners when component unmounts
		return () => {
			window.removeEventListener("offline", handleOffline);
			window.removeEventListener("online", handleOnline);
		};
	}, [navigate]);

	return isOnline;
}

function useOfflineTemplate() {
	const { data, isLoading, isFetching, isSuccess } = useGetTemplateDetailQuery(
		{ type: "offline", template_name: "Offline template", organization_id: "all" },
		{ refetchOnMountOrArgChange: false } // Set to false to prevent automatic refetch
	);
	// type : 'offline', template_name: 'Offline template', organization_id : "all"
	useEffect(() => {
		if (isSuccess) {
			const templateDetail = data?.data?.attributes || [];
			let updatedTemplateDetail = JSON.parse(JSON.stringify(templateDetail));

			// Array to store promises for each API request
			const promises = templateDetail.map((attribute, index) => {
				// console.log('field===>', attribute.label);
				// Your fetchSourceDropdownData logic goes here
				function fetchSourceDropdownData(field, inputValue = "") {
					return new Promise((resolve, reject) => {
						const { _id, api, apiMethod, apiParameters, apiBindingKey, apiBindingValue, apiDataReturnKey = "data" } = field;

						let payload;
						if (apiMethod.toLowerCase() == "get") {
							payload = apiParameters?.map((_) => `${encodeURIComponent(_.label)}=${encodeURIComponent(_.value)}`).join("&");
						} else {
							payload = {};
							apiParameters?.forEach((_) => {
								payload[_.label] = _.value == "onSearch" ? inputValue : _.value;
							});
						}

						// console.log("Fetching dropdown options from API...",api, payload);
						let authInfo = { auth: field.auth, authMethod: field.authMethod, authParams: field.authParams };
						customAPI(payload, api, apiMethod, null, authInfo)
							.then(({ data }) => {
								if (!data.error) {
									let _data = data[apiDataReturnKey] || [];

									if (updatedTemplateDetail[index]._id == _id) {
										updatedTemplateDetail[index]["options"] = _data?.map((el) => ({ label: el[apiBindingKey || "label"], value: el[apiBindingValue || "value"] }));
									}
									resolve(); // Resolve the promise when API request is successful
								} else {
									reject(data.error); // Reject the promise if there's an error
								}
							})
							.catch((error) => {
								reject(error); // Catch and reject any errors that occur during the API request
							});
					});
				}

				return fetchSourceDropdownData(attribute);
			});

			// Wait for all promises to resolve
			Promise.all(promises)
				.then(() => {
					// console.log('updatedTemplateDetail==>', updatedTemplateDetail);
					localStorage.setItem("templateDetail", JSON.stringify(updatedTemplateDetail));
				})
				.catch((error) => {
					console.error("An error occurred while fetching data:", error);
				});
		}
	}, [data, isSuccess]);

	// You may return any necessary data here
}

function useDashboardStats(filter) {
	const [getDashboardStats, { data, isLoading }] = useGetDashboardStatsMutation({ fixedCacheKey: "getDashboardStats" });
	useEffect(() => {
		if (filter) getDashboardStats(filter);
	}, []);

	let { data: stats, count, message, error } = data || { data: [] };
	if (!stats) stats = [];
	return { stats, count, message, isLoading, getDashboardStats };
}

function useCheckpointTypes(filter) {
	const [getCheckpointTypes, { data, isLoading }] = useGetCheckpointTypeMutation({ fixedCacheKey: "getCheckpointTypes" });
	useEffect(() => {
		if (filter) getCheckpointTypes(filter);
	}, []);

	let { data: checkpointTypes, count, message } = data || { data: [] };
	if (!checkpointTypes) checkpointTypes = [];
	const checkpointTypeOptions = checkpointTypes.map((_) => ({ label: _.checkpointType, value: _.checkpointType }));

	return { checkpointTypes, count, message, isLoading, getCheckpointTypes, checkpointTypeOptions };
}

export {
	useDevices,
	useTags,
	useUsers,
	useUserDetail,
	useOrgs,
	getWorkflow,
	useVehicles,
	useSuppliers,
	useProducts,
	useProductCategories,
	useCheckpoints,
	useCheckpointWithExtra,
	useClickOutside,
	useTemplateDetail,
	useCheckpointData,
	useRoles,
	useUserPermissions,
	useAuth,
	useSocket,
	useApplytheme,
	useSocketCheckpoint,
	useSocketCheckpointForDashboard,
	useDashboardStats,
	useWindowOpener,
	useCheckInternet,
	useOfflineTemplate,
	useCheckpointTypes,
	useShowReload,
	useSocketTagMapping,
	useTagsForScan,
	useFocus,
	useAssetTags,
	useAssetTagSocket,
	useDynamicModules,
	useTemplateList,
	useDynamicModuleEntries,
	useDeviceLogs,
	useAllPermissionSet,
	useThemeSetting,
	useDeviceSocket,
	useTcpClients,
};

function guidGenerator() {
	var S4 = function () {
		return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
	};
	return S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4();
}

function useServiceWorker() {
	const [waitingWorker, setWaitingWorker] = useState(null);
	const [showReload, setShowReload] = useState(false);
	// called when a service worker
	// updates. this function is a callback
	// to the actual service worker
	// registration onUpdate.
	const onSWUpdate = useCallback((registration) => {
		// console.log("on sw update")
		setShowReload(true);
		setWaitingWorker(registration.waiting);
	}, []);
	// simply put, this tells the service
	// worker to skip the waiting phase and then reloads the page
	const reloadPage = useCallback(() => {
		waitingWorker?.postMessage({ type: "SKIP_WAITING" });
		setShowReload(false);
		window.location.reload();
	}, [waitingWorker]);
	// register the service worker
	useEffect(() => {
		// If you want your app to work offline and load faster, you can change
		// unregister() to register() below. Note this comes with some pitfalls.
		// Learn more about service workers: https://cra.link/PWA
		serviceWorkerRegistration.register({
			onUpdate: onSWUpdate,
		});
	}, [onSWUpdate]);

	// useEffect(() => {
	// 	serviceWorkerRegistration.unregister();
	// }, []);
	return { showReload, waitingWorker, reloadPage };
}

function useShowReload() {
	const { showReload, waitingWorker, reloadPage } = useServiceWorker();

	useEffect(() => {
		if (showReload && waitingWorker) {
			setTimeout(() => {
				toast.info("New Version Available! Click to reload.", {
					position: "top-right",
					autoClose: false,
					hideProgressBar: false,
					closeOnClick: false,
					pauseOnHover: false,
					draggable: false,
					progress: undefined,
					theme: "colored",
					onClick: () => {
						reloadPage();
					},
					onClose: () => {
						reloadPage();
					},
				});
			}, 1000);
		}
	}, [showReload, waitingWorker, reloadPage]);
}

function useFocus(ref) {
	const [isFocus, setIsFocus] = useState(false);
	useEffect(() => {
		if (!isFocus || !ref.current) return;

		const focusListener = (e) => {
			// console.log("OUT OF FOCUS");
			setIsFocus(false);
		};
		const focusInListener = (e) => {
			// console.log("FOCUSSS");
			setIsFocus(true);
		};
		ref.current.addEventListener("focusout", focusListener);
		ref.current.addEventListener("focusin", focusInListener);

		return () => {
			// console.log("cleanup");
			ref.current?.removeEventListener("focusout", focusListener);
			ref.current?.removeEventListener("focusin", focusInListener);
		};
	}, [isFocus]);

	return { isFocus, setIsFocus };
}

function useThemeSetting() {
	const { data, isFetching } = useGetThemeQuery({});
	let { data: orgDetail = {} } = data || { data: {} };

	const [updateTheme, { isLoading }] = useUpdateThemeMutation();

	return { orgDetail, isOrgDetailLoading: isFetching, updateTheme, isOrgDetailUpdating: isLoading };
}

function useTcpClients(filter = {}) {
	const [getTcpClients, { data, isLoading }] = useGetTcpClientsMutation({ fixedCacheKey: "getTcpClients" });

	useEffect(() => {
		if (filter) {
			getTcpClients(filter);
		}
	}, []);

	let { data: tcpClients = {}, count, message, error } = data || { data: {} };
	return { tcpClients, count, message, isLoading, getTcpClients };
}
