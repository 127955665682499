import { ExternalAppIFrame } from "../ExternalAppIFrame/ExternalAppIFrame";

export default function DriverMaster() {
	const lynkitioServer = import.meta.env.VITE_LYNKIT_IO_REDIRECT_URL;
	const action = "/driver";

	return (
		<div>
			<ExternalAppIFrame serverURL={lynkitioServer} action={action} />
		</div>
	);
}
