import { Route, Routes } from "react-router-dom";
import { useShowReload, useWindowOpener } from "../services/hooks";
import Page from "../webPage";
import Login from "../Components/Login";
import UserManagement from "../Components/Users";
import DeviceManagement from "../Components/Device/Device";
import PrivateRoute from "./PrivateRoute";
import Layout from "../Components/Layout/Layout";
import AddDeviceForm from "../Components/Device/AddDeviceForm";
import Company from "../Components/Company/Company";
import TagManagement from "../Components/Tags/Tags";
import VehicleManagement from "../Components/Vehicle/Vehicle";
import SupplierManagement from "../Components/Supplier/Supplier";
import ProductTypes from "../Components/Product/Products";
import ProductCategories from "../Components/Product/ProductCategories";
import "../App.css";
import "../Static/dashboard.css";
import PublicRoute from "./PublicRoute";
import Checkpoint from "../Components/Checkpoint/Checkpoint";
import Roles from "../Components/Role/Roles";
import LoadScreen from "../Components/Layout/LoadScreen";
import NotAuthorized from "../Components/Login/NotAuthorized";
import NotFound from "../Components/Login/NotFound";
import Welcome from "../Components/Layout/WelcomeScreen";
import Reports from "../Components/Reports/Reports";
import Dashboard from "../Components/Dashboard/Dashboard";
import CheckpointIndex from "../Components/CreateCheckpoint";
import WorkFlowIndex from "../Components/workFlow";
import CreateTemplate from "../Components/Dynamic/CreateTemplate";
import VehicleMasterIndex from "../Components/VehicleMaster";
// import OffLineIndex from "../Components/Offline";
import TripIndex from "../Components/Trip";
import ApprovalIndex from "../Components/Approval";
import CheckpointTypes from "../Components/CheckpointTypes/CheckpointTypes";
import NewDashboard from "../Components/Dashboard";
import TagMappingIndex from "../Components/TagMapping";
import DynamicModule from "../Components/DynamicModule/DynamicModule";
import DynamicModuleCreater from "../Components/DynamicModule/DynamicModuleCreater";
import ApprovalConfigIndex from "../Components/ApprovalConfig";
import DeviceLogs from "../Components/Device/DeviceLogs";
import Settings from "../Components/Settings/Settings";
import AddWorkflowForm from "../Components/workFlow/AddWorkflowForm";
import { SideBarItemsProvider, TourProvider } from "../services/context";
import DriverMaster from "../Components/DriverMaster";

export default function AppRoutes() {
	useWindowOpener();
	useShowReload();
	return (
		<Routes>
			<Route index element={<Page />} />
			<Route element={<PublicRoute />}>
				<Route path="login" element={<Login />} />
			</Route>
			<Route element={<PrivateRoute />}>
				<Route
					element={
						<SideBarItemsProvider>
							<TourProvider>
								<Layout />
							</TourProvider>
						</SideBarItemsProvider>
					}
				>
					<Route path="dashboard_old" element={<Dashboard />} />
					<Route path="dashboard" element={<NewDashboard />} />
					<Route path="users" element={<UserManagement />} />
					<Route path="roles" element={<Roles />} />
					<Route path="companies" element={<Company />} />
					<Route path="devices" element={<DeviceManagement />} />
					<Route path="devices/add-device" element={<AddDeviceForm />} />
					<Route path="devices/edit-device" element={<AddDeviceForm />} />
					<Route path="devices/logs" element={<DeviceLogs />} />
					<Route path="products" element={<ProductTypes />} />
					<Route path="product-categories" element={<ProductCategories />} />
					<Route path="tags" element={<TagManagement />} />
					<Route path="stray-tags" element={<TagMappingIndex />} />
					<Route path="suppliers" element={<SupplierManagement />} />
					<Route path="vehicles" element={<VehicleManagement />} />
					<Route path="reports" element={<Reports />} />
					<Route path="checkpoints/:type/:number" element={<Checkpoint />} />
					<Route path="create-checkpoint" element={<CheckpointIndex />} />
					<Route path="checkpoint-types" element={<CheckpointTypes />} />
					<Route path="workFlow" element={<WorkFlowIndex />} />
					<Route path="add-workflow" element={<AddWorkflowForm />} />
					<Route path="edit-workflow" element={<AddWorkflowForm />} />
					<Route path="loading" element={<LoadScreen />} />
					<Route path="welcome" element={<Welcome />} />
					<Route path="manage-trip" element={<TripIndex />} />

					<Route path="templates" element={<CreateTemplate />} />
					<Route path="create-template" element={<CreateTemplate />} />
					<Route path="vehicle-master" element={<VehicleMasterIndex />} />
					<Route path="driver-master" element={<DriverMaster />} />

					{/* <Route path="offline" element={<OffLineIndex />} /> */}
					<Route path="approval-management" element={<ApprovalIndex />} />
					<Route path="approval-configuration" element={<ApprovalConfigIndex />} />
					<Route path="dynamic-module" element={<DynamicModuleCreater />} />
					<Route path="module/:name" element={<DynamicModule />} />
					<Route path="settings" element={<Settings />} />
				</Route>
				<Route path="/not-authorized" element={<NotAuthorized />} />
				<Route path="*" element={<NotFound />} />
			</Route>
		</Routes>
	);
}
