import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import "./WorkFlowIndex.css";
import { Dropdown, Input, Button, AddRemove } from "../utils";
import { buttonClasses } from "../utils/theme";
import { toastMessage } from "../utils";
import { Checkbox } from "../utils";
import { useCheckpoints, useOrgs } from "../../services/hooks";
import { useCreateWorkflowMutation, useUpdateWorkflowMutation } from "../../api/vmsSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
const defaultFormData = {
	displayName: "",
	workflow: [
		{
			order: "",
			checkpoints: [],
			optional: false,
		},
	],
	orgId: "",
	checkPointOption: [],
	checkPointRes: [],
};

function fillFormData(data) {
	let temp = [];
	data.checkpoints.forEach((ele, index) => {
		temp.splice(index, 1, {
			checkpoints: ele.checkpoints,
			order: ele.order,
			optional: ele.optional,
		});
	});
	return {
		displayName: data.name || "",
		workflow: temp,
		orgId: data.orgId || "",
		checkPointOption: data.checkPointOption || [],
		checkPointRes: data.checkPointRes || [],
	};
}

const DraggableItem = ({ index, moveCard, children }) => {
	const [dragProps, ref] = useDrag({
		type: "CARD",
		item: { index },

		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	});
	const [dropProps, drop] = useDrop({
		accept: "CARD",
		hover: (item) => {
			if (item.index !== index) {
				moveCard(item.index, index);
				item.index = index;
			}
		},
		collect: (monitor) => ({
			isOver: monitor.isOver(),
		}),
	});

	return (
		<div ref={drop} className={dropProps.isOver ? "drop-target" : ""}>
			<div className={`workflow-fields boder-rad-10p ${dragProps.isDragging ? "dragging" : ""}`} ref={ref}>
				{children}
			</div>
		</div>
	);
};

const AddWorkflowForm = () => {
	const location = useLocation();
	const navigate = useNavigate();
	let { data: updatedData } = location.state || {};
	const isUpdate = location.pathname.includes("edit");
	const ref = useRef();
	const [formData, setFormData] = useState(updatedData ? fillFormData(updatedData) : defaultFormData);
	const { orgOptions } = useOrgs();
	const orgValue = orgOptions?.find((_) => _.value == formData.orgId) || null;
	const [error, setError] = useState({});
	const [createWorkflowTrigger] = useCreateWorkflowMutation();
	const [updateWorkflowTrigger] = useUpdateWorkflowMutation();
	const { checkpoints, checkpointOptions, getCheckpoints, clearCheckpointData } = useCheckpoints(null);

	const addWorkflowListing = () => {
		setFormData((prevData) => ({
			...prevData,
			workflow: [
				...prevData.workflow,
				{
					order: "",
					checkpoints: [],
					optional: false,
				},
			],
		}));
	};

	const removeWorkflowListing = (index) => {
		setFormData((prevData) => {
			const updatedWorkflow = [...prevData.workflow];
			updatedWorkflow.splice(index, 1);
			return {
				...prevData,
				workflow: updatedWorkflow,
			};
		});
	};

	const onHandleCheckpointChange = (value, name, index) => {
		let _value=value;
		if (name=='checkpoints'){
			_value=checkpoints.filter((_) => value.find((v) => v.value == _._id))
		}
		setFormData((prevData) => {
			const updatedWorkflow = [...prevData.workflow];
			updatedWorkflow[index] = {
				...updatedWorkflow[index],
				[name]: _value
			};
			return { ...prevData, workflow: updatedWorkflow };
		});
	};


	const handleSubmit = (action) => {
		setError({});
		let payload = {
			name: formData.displayName,
			orgId: formData.orgId || "",
			orgName: orgValue?.label || "",
			checkpoints: formData.workflow.map((_, i) => {
				return { ..._, order: i + 1 };
			}),
		};
		const valid = checkValidation(payload);
		if (!valid.success) {
			setError(valid.error);
		} else {
			if (action === "submit") {
				createWorkflowTrigger(payload)
					.unwrap()
					.then((res) => {
						if (res.error == true) {
							toastMessage(false, res.message);
						} else {
							toastMessage(true, res.message);
							navigate("/workflow");
						}
					})
					.catch((err) => {
						toastMessage(false, err.message);
					});
			} else {
				if (updatedData._id != "") {
					const updatedPayload = { ...payload, _id: updatedData._id };
					updateWorkflowTrigger(updatedPayload)
						.unwrap()
						.then((res) => {
							if (res.error == true) {
								toastMessage(false, res.message);
							} else {
								toastMessage(true, res.message);
								navigate("/workflow");
							}
						})
						.catch((err) => {
							toastMessage(false, err.message);
						});
				} else {
					toastMessage(false, "_id is missing");
				}
			}
		}

	};

	useEffect(() => {
		if (formData.orgId){
			getCheckpoints({ orgId: formData.orgId });
		}
		else{
			clearCheckpointData();
			setFormData((prevData)=>{
				return{
					...prevData,
					workflow:[
						{
							order: "",
							checkpoints: [],
							optional: false,
						},
					]
				
			}})
		}
		
	}, [formData.orgId]);

	const moveCard = (fromIndex, toIndex) => {
		setFormData((prevData) => {
			const updatedWorkflow = [...prevData.workflow];
			const movedCard = updatedWorkflow[fromIndex];
			updatedWorkflow.splice(fromIndex, 1);
			updatedWorkflow.splice(toIndex, 0, movedCard);

			const reorderedWorkflow = updatedWorkflow.map((item, idx) => ({
				...item,
				order: idx + 1,
			}));
			return {
				...prevData,
				workflow: reorderedWorkflow,
			};
		});
	};

	return (
		<DndProvider backend={HTML5Backend}>
			<div className="Workflow_panel">
				<Grid container spacing="1rem">
					<Grid item xl={11} lg={10} md={10} sm={9} xs={6}>
						<div className="workflow_heading">{isUpdate && updatedData && Object.keys(updatedData)?.length > 0 ? "Update Workflow" : "Add New Workflow"}</div>
					</Grid>
					<Grid item xl={1} lg={2} md={2} sm={3} xs={6} display={"flex"} justifyContent={"flex-end"}>
						<Button
							text="Go Back"
							onClick={() => {
								navigate("/workflow");
							}}
							style={{ ...buttonClasses.lynkitOrangeEmpty, width: "fit-content" }}
						/>
					</Grid>
				</Grid>
			</div>
			<div className="Workflow-container">
				<Grid container spacing="1rem">
					<Grid item xl={2} lg={2} md={3} sm={4} xs={12}>
						<Input
							id={"displayName"}
							name={"displayName"}
							value={formData.displayName}
							type={"text"}
							label={"Display Name"}
							onChange={(e) => {
								setFormData((prevData) => ({
									...prevData,
									displayName: e.target.value,
								}));
							}}
							error={error?.displayName || ""}
							sx={{ width: "100%" }}
							required
						/>
					</Grid>
					<Grid item xl={2} lg={2} md={3} sm={4} xs={12}>
						<Dropdown
							id={"orgId"}
							name={"orgId"}
							value={orgValue}
							options={orgOptions}
							label={"Select Organisation"}
							onChange={(option, key) => {
								setFormData((prevData) => ({
									...prevData,
									orgId: option?.value,
								}));
							}}
							error={error?.orgId || ""}
							required
							readonly={updatedData && Object.keys(updatedData)?.length > 0 ? true : false}
							isOptionEqualToValue={(option, value) => option.value == value.value}
						/>
					</Grid>
				</Grid>
			</div>

			<div className="workflow-field-container  boder-rad-10p mt-3">
				{formData.workflow.map((ele, index) => {
					const checkpointValue = ele.checkpoints?.map((ele) => ({ label: ele.displayName, value: ele._id })) || [];

					return (
						// <div className="workflow-fields  boder-rad-10p" key={`workflow_${index}`}>
						<DraggableItem key={index} index={index} moveCard={moveCard}>
							<Grid container spacing="1rem">
								<Grid item xl={2} lg={2} md={3} sm={4} xs={12} sx={{ display: "flex" }}>
									<span id="three_dots_icon" ref={ref} style={{ cursor: "all-scroll", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
										<DragIndicatorIcon />
									</span>
									<Dropdown
										id={"checkpoint"}
										name={"checkpoints"}
										value={checkpointValue}
										options={checkpointOptions}
										label={"Checkpoint"}
										multiple={true}
										required
										onChange={(option, key) => onHandleCheckpointChange(option, key, index)}
										error={error?.checkpoints && error?.checkpoints.length > 0 && error?.checkpoints[index] ? error?.checkpoints[index].checkpoints : ""}
									/>
								</Grid>
								<div className="Workflow-checkpoint-div">
									<Checkbox
										id={index}
										name={"optional"}
										checked={ele.optional || false}
										onChange={(e) => onHandleCheckpointChange(e.target.checked, e.target.name, index)}
										label={"Optional"}
									/>
								</div>
								<Grid item xl={1} lg={1} md={1} sm={2} xs={6} sx={{ display: "flex", gap: "0.25rem", alignItems: "center" }}>
									<AddRemove
										list={formData.workflow}
										filterMethod={(_) => _.checkpoints.length === 0}
										index={index}
										outerIndex={index}
										onAdd={addWorkflowListing}
										onRemove={removeWorkflowListing}
									/>
								</Grid>
							</Grid>
						</DraggableItem>
						// </div>
					);
				})}
			</div>
			<div className="d-flex justify-content-center my-4">
				<Button
					testId={"Submit"}
					text={isUpdate ? "Update" : "Submit"}
					style={{ ...buttonClasses.lynkitOrangeFill, width: "10%" }}
					disabled={false}
					onClick={() => handleSubmit(isUpdate ? "update" : "submit")}
				/>
			</div>
		</DndProvider>
	);
};

export default AddWorkflowForm;

export const checkValidation = (payload) => {
	let success = true; // Initialize success to true
	let error = {};

	if (payload.name === "") {
		error.displayName = "This field is required";
		success = false; // Set success to false if there's an error
	}
	if (payload.orgId === "") {
		error.orgId = "This field is required";
		success = false; // Set success to false if there's an error
	}

	error.checkpoints = []; // Initialize checkpoints array outside the loop

	payload.checkpoints.forEach((ele, index) => {
		
		if (!ele.checkpoints || ele.checkpoints.length === 0) {
			error.checkpoints[index] = { ...error?.checkpoints[index] };
			error.checkpoints[index].checkpoints = "This field is required";
			success = false; // Set success to false if there's an error
		}
	});
	return { error, success };
};
