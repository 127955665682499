import React, { useEffect, useState, useRef } from "react";
import { Input, Dropdown, Button, Checkbox, AddRemove, AccordionWrapper } from "../utils";
import { buttonClasses } from "../utils/theme";
import { useOrgs, useUserDetail, useDevices } from "../../services/hooks";
import { useProductCategories, useProducts, useSuppliers } from "../../services/hooks";
import {
	useAddCheckPointMutation,
	useGetApiListDetailQuery,
	useUpdateCheckPointMutation,
	useGetCheckpointTypeMutation,
	useGetWorkflowListMutation,
	useGetVariableListingMutation,
	useGetApprovalConfigMutation,
} from "../../api/vmsSlice";
import { toastMessage } from "../utils";
import "./CheckpointIndex.css";
import { useGetTemplateListQuery } from "../../api/vmsSlice";
import ListingConfigration from "./ListingConfigration";
import { capitalizeCamelCase } from "../../services/functions";
import { Tooltip } from "@mui/material";
import { InfoIcon } from "../../services/icons";

const checkpointType = [
	{ label: "Access", value: "access" },
	{ label: "Gate In", value: "gateIn" },
	{ label: "Parking", value: "parking" },
	{ label: "Bay", value: "bay" },
	{ label: "Weighbridge", value: "weighbridge" },
	{ label: "Gate Out", value: "gateOut" },
];

const defaultFilter = {
	// pagesize: 40,
	// page_no: 1,
};

const itemTypeOption = [
	{ label: "Product", value: "product" },
	{ label: "Product Category", value: "productCategory" },
	{ label: "Supplier", value: "supplier" },
	{ label: "Custom", value: "custom" },
];

const itemTypeOption2 = [
	{ label: "Product", value: "product" },
	{ label: "Product Category", value: "productCategory" },
	{ label: "Supplier", value: "supplier" },
];

const DEFAULT_LISITINGCONFIG = [
	{
		type: "arrived",
		order: 1,
		label: "Arrived",
		filter: [
			{
				key: "status",
				selfFilter: true,
				formula: {
					operator: "object",
					eval: [
						{
							key: "$gte",
							isDynamic: false,
							value: "1",
						},
					],
				},
			},
		],
	},
];

const DEFAULT_ACCESSORIES = {
	triggerBoomBarrier: {
		isActive: false,
		value: "",
		duration: 0,
	},
	triggerBuzzer: {
		isActive: false,
		value: "",
		duration: 0,
	},
	triggerRedLight: {
		isActive: false,
		value: "",
		duration: 0,
	},
};

const DEFAULT_LED_CONFIG = {
	ledDetails: {
		lineOne: "",
		ledId: "",
		variableList: [],
	},
	isActive: false,
};
const DEFAULT_SPEAKER_CONFIG = {
	speakerDetails: {
		lineOne: "",
		speakerId: "",
		variableList: [],
	},
	isActive: false,
};
const DEFAULT_ASSET_CONFIG = {
	assetAdd: false,
	assetScan: false,
};
export default function CreateCheckpoint({ updatedData = {}, addCheckPoint, setAddCheckPoint, isDuplicate = false, reset, setData, setAssignUserModal }) {
	const { userDetail } = useUserDetail();
	const { orgOptions, orgs } = useOrgs();
	// const { userPermissions } = useUserPermissions();

	// console.log({ assetAddPerm, assetScanPerm });
	const filterRef = useRef(defaultFilter);
	const templateRef = useRef({});
	const devicesRef = useRef({ pagesize: 10, page_no: 1 });
	const { devices, getDevices } = useDevices(devicesRef.current);
	const { data: templateList, refetch: refetchTemplate } = useGetTemplateListQuery(templateRef.current, { refetchOnMountOrArgChange: true });
	const { productCategories, count, isLoading, message, getProdCats } = useProductCategories(filterRef.current);
	const { products, count: productCount, getProducts } = useProducts(filterRef.current);
	const { suppliers, count: supplierCount, getSuppliers } = useSuppliers(filterRef.current);
	const { data: ApiOption, error: apilistError, isLoading: apilistLoading } = useGetApiListDetailQuery({});
	const [checkpointTypeTrigger, { data: checkPointTypeData, error: checkPointTypeError, isLoading: checkPointTypeLoading }] = useGetCheckpointTypeMutation();
	const [handleAddCheckPoint, { isLoading: isUpdating }] = useAddCheckPointMutation();
	const [handleUpdateCheckPoint] = useUpdateCheckPointMutation();
	const [variableListTrigger] = useGetVariableListingMutation();
	// console.log('checkPointTypeOption', devices);
	const [variableListing, setVariableListing] = useState([]);
	const [listingConfig, setListingConfig] = useState(DEFAULT_LISITINGCONFIG);
	const [addCustomAPIEndpoint, setAddCustomAPIEndpoint] = useState(false);
	const [addCustomListingConfig, setAddCustomListingConfig] = useState(false);
	const [addCustomCheckoutAPIEndpoint, setAddCustomCheckoutAPIEndpoint] = useState(false);
	const [checkPoint, setCheckPoint] = useState({
		orgId: "",
		orgName: "",
		checkpointNumber: "",
		checkpointType: "",
		checkpointStatus: "",
		displayName: "",
		templateId: "",
		approvalRequired: false,
		apiMethod: "post",
		api: "vms/vehicle/create-vehicle-entry",
		detailApi: "vms/vehicle/vehicle-entry-data-list",
		latitude: "",
		longitude: "",
		detailApiMethod: "post",
		deallocateTag: false,
		entryPoint: false,
		exitPoint: false,
		createTripsThroughStrayFastags: false,
		defaultWorkflowId: "",
		allowMultipleEntry: false,
		holdDuration: 30,
		validateCheckoutTime: false,
	});
	const { permissions = {} } = orgs.find((_) => _._id == checkPoint.orgId) || {};
	const assetAddPerm = permissions.assetAdd?.value;
	const assetScanPerm = permissions.assetScan?.value;
	// console.log('checkPoint', assetAddPerm, assetScanPerm, permissions)
	const [approvalConfig, setApprovalConfig] = useState({
		sendApproval: false,
		dependentApproval: false,
		approvaldependancy: [{ itemType: "", id: [], approvalId: "" }],
		approvalId: "",
	});
	const [scanConfig, setScanConfig] = useState({
		deviceScannning: false,
		deviceId: "",
		antennaId: undefined,
	});
	const [assetConfig, setAssetConfig] = useState(DEFAULT_ASSET_CONFIG);
	const [checkoutConfig, setCheckoutconfig] = useState({
		api: "vms/vehicle/checkout-vehicle",
		apiMethod: "post",
		buttonLabel: "",
		buttonClass: "",
		submitFormTitle: "",
		templateId: "",
		checkoutOnSubmit: false,
		autoCheckout: false,
		checkoutOnApproval: false,
		checkoutWhenOutOfRange: false,
		entrySuccessText: "",
	});

	const [accessories, setAccessories] = useState(DEFAULT_ACCESSORIES);

	const [LEDConfig, setLEDConfig] = useState(DEFAULT_LED_CONFIG);
	const [speakerConfig, setSpeakerConfig] = useState(DEFAULT_SPEAKER_CONFIG);

	const [error, setError] = useState({
		checkPoint: {},
		listingConfig: [],
		approvalConfig: {},
		scanConfig: {},
		checkoutConfig: {},
		ledConfig: {},
		speakerConfig: {},
	});

	const [getWorkflowListTrigger, { isLoadingg }] = useGetWorkflowListMutation();
	const [workflowListing, setWorkFlowListing] = useState([]);

	const orgValue = orgOptions.find((ele) => ele.value === checkPoint.orgId) || null;
	const fetchWorkFlowListing = (payload = {}) => {
		getWorkflowListTrigger(payload)
			.unwrap()
			.then((res) => {
				// console.log('res==', res)
				if (res?.data) {
					const option = res.data.map((_) => ({ label: _.name, value: _._id }));
					setWorkFlowListing(option);
					// setCount(res.count)
					// setFilter((previousValue) => ({ ...previousValue, count: res.count }));
				}
				if (res.error == true) {
					// toastMessage(false, res.message);
					setWorkFlowListing([]);
				}
			})
			.catch((err) => {
				// toastMessage(false, err.message);
				setWorkFlowListing([]);
			});
	};

	const [getApprovalConfigsTrigger] = useGetApprovalConfigMutation({});
	const [approvalConfigOptions, setApprovalConfigOptions] = useState([]);

	const fetchApprovalConfigurations = (payload = {}) => {
		getApprovalConfigsTrigger(payload)
			.unwrap()
			.then((res) => {
				if (res.data) {
					const options = res.data.map((_) => ({ label: _.module, value: _._id }));
					setApprovalConfigOptions(options);
				} else {
					setApprovalConfigOptions([]);
				}
			})
			.catch((err) => setApprovalConfigOptions([]));
	};

	const addApprovalDependancyListing = () => {
		// const logList = JSON.parse(JSON.stringify([...listingConfig]));
		// const approvalDependancyList = { ...approvalConfig }
		const approvalDependancyList = JSON.parse(JSON.stringify({ ...approvalConfig }));
		approvalDependancyList.approvaldependancy.push({ itemType: "", id: [] });
		setApprovalConfig(approvalDependancyList);
	};
	const RemoveApprovalDependancyListing = (index) => {
		// const approvalDependancyList = { ...approvalConfig }
		const approvalDependancyList = JSON.parse(JSON.stringify({ ...approvalConfig }));
		approvalDependancyList.approvaldependancy.splice(index, 1);
		setApprovalConfig(approvalDependancyList);
	};

	const onHandleCheckpointDetailChange = (value, name) => {
		// console.log("value", value, name);
		const checkPointDetail = { ...checkPoint };
		if (name === "checkpointType") {
			if (value === null || value === undefined) {
				checkPointDetail[name] = "";
			} else {
				checkPointDetail[name] = value.value;
			}
		} else if (name === "checkpointStatus") {
			checkPointDetail[name] = value;
			const filters = [
				{
					type: "arrived",
					order: 1,
					label: "Arrived",
					filter: [
						{
							key: "status",
							selfFilter: true,
							formula: {
								operator: "object",
								eval: [
									{
										key: "$gte",
										isDynamic: false,
										value: value,
									},
								],
							},
						},
					],
				},
			];
			if (!addCustomListingConfig && listingConfig.length === 1) {
				setListingConfig(filters);
			}
		} else if (name === "orgId") {
			if (value === null || value === undefined) {
				checkPointDetail[name] = "";
			} else {
				checkPointDetail[name] = value.value;
				checkPointDetail["orgName"] = value.label;
				// console.log("orgName", value.label);
				devicesRef.current = { orgId: value.value };
				templateRef.current = { organization_id: value.value };
				refetchTemplate(templateRef.current);
				getDevices(devicesRef.current);
				checkpointTypeTrigger({ orgId: value.value });
				getProdCats({ ...defaultFilter, orgId: value.value });
				getProducts({ ...defaultFilter, orgId: value.value });
				getSuppliers({ ...defaultFilter, orgId: value.value });
				fetchWorkFlowListing(value.value ? { orgId: value.value } : {});
				variableListTrigger({ orgId: value.value })
					.unwrap()
					.then((res) => {
						if (!res.error) {
							const variableList = res.data.variableList.map((_) => ({ label: _, value: _ }));
							setVariableListing(variableList);
						} else {
							setVariableListing([]);
						}
					})
					.catch((err) => {
						setVariableListing([]);
					});
				fetchApprovalConfigurations({ orgId: value.value });
			}
		} else if (name === "api") {
			if (value === null || value === undefined) {
				checkPointDetail[name] = "";
			} else {
				checkPointDetail[name] = value.value;
			}
		} else if (name === "detailApi") {
			if (value === null || value === undefined) {
				checkPointDetail[name] = "";
			} else {
				checkPointDetail[name] = value.value;
			}
		} else {
			checkPointDetail[name] = value;
		}
		setCheckPoint(checkPointDetail);
	};

	const onhandleApprovalConfigration = (value, name, index) => {
		if (name === "itemType") {
			// const approvalConfigDetail = { ...approvalConfig };
			const approvalConfigDetail = JSON.parse(JSON.stringify({ ...approvalConfig }));
			// console.log("approoval ====", value);
			if (value === null || value === undefined) {
				approvalConfigDetail.approvaldependancy[index][name] = "";
			} else {
				approvalConfigDetail.approvaldependancy[index][name] = value.value;
				if (value.value === "productCategory") {
					getProdCats({ ...defaultFilter, orgId: checkPoint.orgId });
				}
				if (value.value === "product") {
					getProducts({ ...defaultFilter, orgId: checkPoint.orgId });
				}
				if (value.value === "supplier") {
					getSuppliers({ ...defaultFilter, orgId: checkPoint.orgId });
				}
			}

			setApprovalConfig(approvalConfigDetail);
			// console.log('approval', value, name, index)
		}

		if (name === "id") {
			// console.log('checkpoint detail===', value, name, index);
			// console.log('approvalConfigdetail===', approvalConfig);
			const approvalConfigDetail = JSON.parse(JSON.stringify({ ...approvalConfig }));
			// const approvalConfigDetail = { ...approvalConfig };
			const itemList = value.map((ele) => {
				if (ele.value) {
					return ele.value;
				} else {
					return ele;
				}
			});
			// console.log("itemList ====", itemList);
			approvalConfigDetail.approvaldependancy[index][name] = itemList;
			setApprovalConfig(approvalConfigDetail);
		}

		if (name === "approvalId") {
			const approvalConfigDetail = JSON.parse(JSON.stringify({ ...approvalConfig }));
			approvalConfigDetail.approvaldependancy[index][name] = value ?? "";
			setApprovalConfig(approvalConfigDetail);
		}
	};

	const onhandleConfigration = (value, name, config) => {
		const scanConfigDetail = { ...scanConfig };
		const checkoutConfigDetail = { ...checkoutConfig };
		let approvalConfigDetail = { ...approvalConfig };
		if (config === "checkpointConfig") {
			if (value === null || value === undefined) {
				checkoutConfigDetail[name] = "";
			} else if (value.constructor === Object) {
				checkoutConfigDetail[name] = value.value;
			} else {
				checkoutConfigDetail[name] = value;
			}
		} else if (config === "approvalConfig") {
			if (name == "sendApproval") {
				approvalConfigDetail = {
					sendApproval: value,
					dependentApproval: false,
					approvaldependancy: [{ itemType: "", id: [], approvalId: "" }],
				};
			} else if (name == "dependentApproval" && value == false) {
				// console.log("dependentApproval", name, value);
				approvalConfigDetail = {
					sendApproval: true,
					dependentApproval: false,
					approvaldependancy: [{ itemType: "", id: [], approvalId: "" }],
				};
			} else {
				if (name == "approvalId" && (value == null || value == undefined)) {
					approvalConfigDetail[name] = "";
				} else {
					approvalConfigDetail[name] = value;
				}
			}
		} else if (config == "assetConfig") {
			setAssetConfig((o) => ({ ...o, [name]: value }));
		} else {
			// console.log(value, name, config);
			if (value == undefined && name == "deviceId") {
				scanConfigDetail[name] = value;
				scanConfigDetail["antennaId"] = value;
			} else {
				if (name == "deviceScannning" && value == false) {
					checkoutConfigDetail.checkoutWhenOutOfRange = false;
				}
				scanConfigDetail[name] = value;
			}
		}
		setScanConfig(scanConfigDetail);
		setCheckoutconfig(checkoutConfigDetail);
		setApprovalConfig(approvalConfigDetail);
	};

	const handleSubmit = (action) => {
		const newlistingConfig = ensureValueIsArray(JSON.parse(JSON.stringify(listingConfig)));
		let payload = {
			...checkPoint,
			listingConfig: newlistingConfig,
			...approvalConfig,
			...scanConfig,
			checkoutConfig,
			accessories: {
				...accessories,
				triggerLED: LEDConfig,
				triggerSpeaker: speakerConfig,
			},
			assetConfig,
		};
		payload.orgName = orgValue?.label;
		// console.log(payload)
		// const checkValidation = { success: true };
		const checkValidation = validatePayload({
			checkPoint: { ...checkPoint, orgName: orgValue?.label },
			listingConfig: listingConfig,
			approvalConfig: approvalConfig,
			scanConfig: scanConfig,
			checkoutConfig: checkoutConfig,
			accessories: {
				...accessories,
				triggerLED: LEDConfig,
				triggerSpeaker: speakerConfig,
			},
			addCustomCheckoutAPIEndpoint,
		});

		// console.log({ checkValidation });
		if (checkValidation.success) {
			setError({
				checkPoint: {},
				listingConfig: [],
				approvalConfig: {},
				scanConfig: {},
				checkoutConfig: {},
				ledConfig: {},
			});
			// console.log("submit==", payload);
			if (action === "submit") {
				handleAddCheckPoint(payload)
					.unwrap()
					.then((res) => {
						toastMessage(!res.error, res.message);
						if (!res.error) {
							reset();
							setAddCheckPoint(!addCheckPoint);
							setData({
								createdCheckpoint: res?.data,
								users: res?.users,
							});
							setAssignUserModal(true);
						}
					})
					.catch((err) => {
						toastMessage(false, err.message);
					});
			} else {
				if (updatedData._id != "") {
					const updatedPayload = { ...payload, checkpointId: updatedData._id };
					// console.log(updatedPayload)
					handleUpdateCheckPoint(updatedPayload)
						.unwrap()
						.then((res) => {
							toastMessage(!res.error, res.message);
							if (!res.error) {
								reset();
								setAddCheckPoint(!addCheckPoint);
							}
						})
						.catch((err) => {
							toastMessage(false, err.message);
						});
				} else {
					toastMessage(false, "_id is missing");
				}
			}
		} else {
			// console.log("submit====", payload);
			setError(checkValidation.error);
			toastMessage(false, "Please check your input. Some fields are either empty or contain invalid values.");
		}
	};

	const handleViewMap = () => {
		const { latitude, longitude } = checkPoint;
		if (latitude !== null && longitude !== null) {
			// Redirect to Google Maps with the specified latitude and longitude
			window.open(`https://www.google.com/maps?q=${latitude},${longitude}`, "_blank");
		} else {
			alert("Latitude and Longitude are required.");
		}
	};

	useEffect(() => {
		if (Object.keys(updatedData)?.length > 0) {
			// console.log("edit", updatedData?.accessories?.triggerLED);
			const updatedNewData = { ...updatedData };
			const updatedCheckPoint = {
				orgId: updatedNewData.orgId || "",
				orgName: updatedNewData.orgName || "",
				checkpointNumber: updatedNewData.checkpointNumber || "",
				checkpointType: updatedNewData.checkpointType || "",
				checkpointStatus: updatedNewData.checkpointStatus || "",
				allowMultipleEntry: updatedNewData.allowMultipleEntry || false,
				displayName: updatedNewData.displayName || "",
				templateId: updatedNewData.templateId || "",
				deallocateTag: updatedNewData.deallocateTag || false,
				approvalRequired: updatedNewData.approvalRequired || false,
				required: updatedNewData.required || false,
				apiMethod: updatedNewData.apiMethod || "",
				api: updatedNewData.api || "",
				latitude: updatedNewData.latitude || "",
				longitude: updatedNewData.longitude || "",
				detailApi: updatedNewData.detailApi || "",
				detailApiMethod: updatedNewData.detailApiMethod || "",
				entryPoint: updatedNewData.entryPoint,
				exitPoint: updatedNewData.exitPoint,
				createTripsThroughStrayFastags: updatedNewData.createTripsThroughStrayFastags,
				defaultWorkflowId: updatedNewData.defaultWorkflowId || "",
				holdDuration: updatedNewData.holdDuration,
				validateCheckoutTime: updatedNewData.validateCheckoutTime || false,
			};
			const updatedListingConfig = updatedNewData?.listingConfig ? updatedNewData?.listingConfig : listingConfig;
			const updatedApprovalconfig = {
				sendApproval: updatedNewData.sendApproval || false,
				dependentApproval: updatedNewData.dependentApproval || false,
				approvaldependancy: updatedNewData.approvaldependancy || false,
				approvalId: updatedNewData.approvalId || "",
			};
			const updatedScanConfig = {
				deviceScannning: updatedNewData.deviceScannning || false,
				deviceId: updatedNewData.deviceId || "",
				antennaId: updatedNewData.antennaId || undefined,
			};
			const updatedCheckoutConfig = updatedNewData?.checkoutConfig ? updatedNewData?.checkoutConfig : checkoutConfig;
			devicesRef.current = { orgId: updatedNewData.orgId };
			templateRef.current = { organization_id: updatedNewData.orgId };
			// console.log({t: templateRef.current})
			refetchTemplate();
			fetchApprovalConfigurations({ orgId: updatedNewData.orgId });
			getDevices(devicesRef.current);
			checkpointTypeTrigger({ orgId: updatedNewData.orgId });
			fetchWorkFlowListing({ orgId: updatedNewData.orgId });
			setCheckPoint(updatedCheckPoint);
			setListingConfig(updatedListingConfig);
			setApprovalConfig(updatedApprovalconfig);
			setScanConfig(updatedScanConfig);
			setCheckoutconfig(updatedCheckoutConfig);
			setAccessories(updatedData.accessories || DEFAULT_ACCESSORIES);
			setLEDConfig(updatedData?.accessories?.triggerLED || DEFAULT_LED_CONFIG);
			setSpeakerConfig(updatedData?.accessories?.triggerSpeaker || DEFAULT_SPEAKER_CONFIG);
			setAssetConfig(updatedData.assetConfig || DEFAULT_ASSET_CONFIG);
			getProdCats({ ...defaultFilter, orgId: updatedNewData.orgId });
			getProducts({ ...defaultFilter, orgId: updatedNewData.orgId });
			getSuppliers({ ...defaultFilter, orgId: updatedNewData.orgId });
			variableListTrigger({ orgId: updatedNewData.orgId })
				.unwrap()
				.then((res) => {
					if (!res.error) {
						const variableList = res.data.variableList.map((_) => ({ label: _, value: _ }));
						setVariableListing(variableList);
					} else {
						setVariableListing([]);
					}
				})
				.catch((err) => {
					setVariableListing([]);
				});
		}
	}, [updatedData]);
	const ledLineOneIndices = findIndexForVariableList(LEDConfig?.ledDetails?.lineOne);
	const speakerLineOneIndices = findIndexForVariableList(speakerConfig?.speakerDetails?.lineOne);

	return (
		<>
			<div className="checkpoint-container">
				<div className="dropdown-div" style={{ marginTop: "10px" }}>
					<Dropdown
						id={"orgId"}
						name={"orgId"}
						value={orgValue}
						options={orgOptions}
						label={"Select Organisation"}
						onChange={(option, key) => {
							onHandleCheckpointDetailChange(option, key);
						}}
						required
						error={error?.checkPoint?.orgId}
					/>
				</div>
				<div className="mt-2">
					<h5 className="checkpoint_subHeading">Checkpoint detail</h5>
				</div>
				<div className="checkpoint-detail-container">
					<Input
						id={"checkpointNumber"}
						name={"checkpointNumber"}
						value={checkPoint.checkpointNumber}
						onChange={(e) => {
							onHandleCheckpointDetailChange(e.target.value, e.target.name);
						}}
						type={"number"}
						label={"Checkpoint Order No."}
						placeholder={"Enter Checkpoint Order No."}
						required
						step={1}
						error={error?.checkPoint?.checkpointNumber}
					/>
					<Input
						id={"displayName"}
						name={"displayName"}
						value={checkPoint.displayName}
						type={"text"}
						label={"Display Name"}
						onChange={(e) => {
							onHandleCheckpointDetailChange(e.target.value, e.target.name);
						}}
						required
						error={error?.checkPoint?.displayName}
					/>
					<div className="dropdown-div">
						<Dropdown
							id={"checkpointType"}
							name={"checkpointType"}
							value={
								checkPointTypeData && checkPointTypeData?.data && checkPointTypeData?.data?.length > 0
									? checkPointTypeData?.data
											?.filter((ele) => ele.checkpointType === checkPoint.checkpointType)
											.map((ele) => ({ label: ele.checkpointType, value: ele.checkpointType }))[0]
									: null
							}
							options={
								checkPointTypeData && checkPointTypeData?.data && checkPointTypeData?.data?.length > 0
									? checkPointTypeData?.data?.map((ele) => ({ label: ele.checkpointType, value: ele.checkpointType }))
									: []
							}
							label={"Checkpoint Type"}
							onChange={(option, key) => onHandleCheckpointDetailChange(option, key)}
							required
							error={error?.checkPoint?.checkpointType}
						/>
					</div>
					<Input
						id={"checkpointStatus"}
						name={"checkpointStatus"}
						value={checkPoint.checkpointStatus}
						type={"text"}
						label={"Checkpoint Status"}
						onChange={(e) => {
							onHandleCheckpointDetailChange(e.target.value, e.target.name);
						}}
						required
						error={error?.checkPoint?.checkpointStatus}
					/>
					<Input
						id={"holdDuration"}
						name={"holdDuration"}
						value={checkPoint.holdDuration}
						type="number"
						label={"Hold Duration (seconds)"}
						onChange={(e) => {
							onHandleCheckpointDetailChange(Number(e.target.value) < 0 ? Number(0) : Number(e.target.value), e.target.name);
						}}
					/>

					<div className="dropdown-div">
						<Dropdown
							id={"templateId"}
							name={"templateId"}
							value={
								templateList && templateList?.data && templateList?.data?.length > 0
									? templateList?.data?.filter((ele) => ele._id === checkPoint.templateId).map((ele) => ({ label: ele.name, value: ele._id }))[0]
									: null
							}
							options={templateList && templateList?.data && templateList?.data?.length > 0 ? templateList?.data?.map((ele) => ({ label: ele.name, value: ele._id })) : []}
							label={"Template Id"}
							onChange={(option, key) => onHandleCheckpointDetailChange(option?.value || "", key)}
							// required
							error={error?.checkPoint?.templateId}
						/>
					</div>
					<div className="checkpoint-detail-container">
						<Checkbox
							id="allowMultipleEntry"
							name="allowMultipleEntry"
							checked={checkPoint.allowMultipleEntry}
							label="Allow Multiple Entry"
							onChange={(e) => {
								onHandleCheckpointDetailChange(e.target.checked, e.target.name);
							}}
						/>
						<Checkbox
							id="validateCheckoutTime"
							name="validateCheckoutTime"
							checked={checkPoint.validateCheckoutTime}
							label="Validate Checkout Time"
							onChange={(e) => {
								onHandleCheckpointDetailChange(e.target.checked, e.target.name);
							}}
						/>

					<Checkbox
						id="entryPoint"
						name="entryPoint"
						checked={checkPoint.entryPoint}
						label="Entry Point"
						onChange={(e) => {
							onHandleCheckpointDetailChange(e.target.checked, e.target.name);
						}}
					/>
					{checkPoint.entryPoint && (
						<>
							<div className="dropdown-div">
								<Dropdown
									id="workFlowId"
									name="defaultWorkflowId"
									label="Default WorkFlow"
									onChange={(option, key) => onHandleCheckpointDetailChange(option?.value || "", key)}
									options={workflowListing}
									error={error?.checkPoint?.defaultWorkflowId}
									value={checkPoint?.defaultWorkflowId ? workflowListing.filter((ele) => ele.value === checkPoint.defaultWorkflowId)[0] : null}
								/>
							</div>
							<Checkbox
								id="createTripsThroughStrayFastags"
								name="createTripsThroughStrayFastags"
								checked={checkPoint.createTripsThroughStrayFastags}
								label="Create Trips Through Stray Fastags"
								onChange={(e) => {
									onHandleCheckpointDetailChange(e.target.checked, e.target.name);
								}}
							/>
						</>
					)}
					<Checkbox
						id="exitPoint"
						name="exitPoint"
						checked={checkPoint.exitPoint}
						label="Exit Point"
						onChange={(e) => {
							onHandleCheckpointDetailChange(e.target.checked, e.target.name);
						}}
					/>
					<Checkbox
						id="deallocateTag"
						name="deallocateTag"
						checked={checkPoint.deallocateTag}
						label="Deallocate Tag"
						onChange={(e) => {
							onHandleCheckpointDetailChange(e.target.checked, e.target.name);
						}}
					/>
					<Checkbox
						id="approvalRequired"
						name="approvalRequired"
						checked={checkPoint.approvalRequired}
						label="Approval Required"
						onChange={(e) => {
							onHandleCheckpointDetailChange(e.target.checked, e.target.name);
						}}
					/>

						<Checkbox
							id="addCustomAPIEndpoint"
							name="addCustomAPIEndpoint"
							checked={addCustomAPIEndpoint}
							label="Add Custom API Endpoints"
							onChange={(e) => {
								setAddCustomAPIEndpoint(e.target.checked);
							}}
						/>
						{addCustomAPIEndpoint == true && (
							<section className="checkpoint-detail-container">
								<Input
									id={"checkPoint_apiMethod"}
									name={"apiMethod"}
									value={checkPoint.apiMethod}
									type={"text"}
									label={"Create API Method"}
									onChange={(e) => {
										onHandleCheckpointDetailChange(e.target.value, e.target.name);
									}}
									required
									error={error?.checkPoint?.apiMethod}
								/>
								<div className="dropdown-div">
									<Dropdown
										id={"checkPoint_api"}
										name={"api"}
										value={
											ApiOption && ApiOption?.data && checkPoint.api
												? (ApiOption?.data.map((ele) => ({ label: ele.name, value: ele.endPoint })).filter((ele) => ele.value === checkPoint.api))[0]
												: null
										}
										options={ApiOption && ApiOption?.data ? ApiOption?.data.map((ele) => ({ label: ele.name, value: ele.endPoint })) : []}
										label={"Select Create API"}
										onChange={(option, key) => onHandleCheckpointDetailChange(option, key)}
										required
										error={error?.checkPoint?.api}
									/>
								</div>
								<Input
									id={"detailApiMethod"}
									name={"detailApiMethod"}
									value={checkPoint.detailApiMethod}
									type={"text"}
									label={"Detail API Method"}
									onChange={(e) => {
										onHandleCheckpointDetailChange(e.target.value, e.target.name);
									}}
									required
									error={error?.checkPoint?.detailApiMethod}
								/>
								<div className="dropdown-div">
									<Dropdown
										id={"detailApi"}
										name={"detailApi"}
										value={
											ApiOption && ApiOption?.data && checkPoint.detailApi
												? (ApiOption?.data.map((ele) => ({ label: ele.name, value: ele.endPoint })).filter((ele) => ele.value === checkPoint.detailApi))[0]
												: null
										}
										options={ApiOption && ApiOption?.data ? ApiOption?.data.map((ele) => ({ label: ele.name, value: ele.endPoint })) : []}
										label={"Select Detail API"}
										onChange={(option, key) => onHandleCheckpointDetailChange(option, key)}
										required
										error={error?.checkPoint?.detailApi}
									/>
								</div>
							</section>						
						)}
					</div>
				</div>
				<AccordionWrapper title='Location detail' isOpenAcc={false}>
				{/* <div className="">
					<h5 className="checkpoint_subHeading">Location detail</h5>
				</div> */}
				<div className="checkpoint-detail-container">
					<Input
						id={"checkPoint_lat"}
						name={"latitude"}
						value={checkPoint.latitude}
						type={"text"}
						label={"Latitude"}
						onChange={(e) => {
							onHandleCheckpointDetailChange(e.target.value, e.target.name);
						}}
						// required
						error={error?.checkPoint?.latitude}
					/>
					<Input
						id={"checkPoint_log"}
						name={"longitude"}
						value={checkPoint.longitude}
						type={"text"}
						label={"Longitude"}
						onChange={(e) => {
							onHandleCheckpointDetailChange(e.target.value, e.target.name);
						}}
						// required
						error={error?.checkPoint?.longitude}
					/>
					{checkPoint.longitude && checkPoint.latitude && (
						<Button
							text="View Location"
							onClick={() => {
								handleViewMap();
							}}
							style={{ ...buttonClasses.lynkitOrangeEmpty, width: "10%" }}
						/>
					)}
				</div>

				</AccordionWrapper>
				<AccordionWrapper title='Listing Configuration' isOpenAcc={false}>
				{/* <div>
					<h5 className="checkpoint_subHeading">Listing Configuration</h5>
				</div> */}
				<div style={{ position: "relative" }}>
					{!addCustomListingConfig && (
						<div className="listingConfig-listing-container">
							{listingConfig.length > 0 &&
								listingConfig
									?.map((ele) => (ele.label && ele.order ? { order: ele.order, label: ele.label } : { order: "Missing", label: "No Listing Configration, Please Add one" }))
									.filter((ele) => ele !== null) // Filter out the null values
									.map((ele, index) => (
										<div key={index} className="listing-card-container">
											<div className="listing-card">
												<label>Order No.</label>
												<span>{ele.order}</span>
											</div>
											<div className="listing-card-filter">
												<span style={{ fontWeight: "600" }}>{ele.label}</span>
											</div>
										</div>
									))}
						</div>
					)}
					<div className="custom-listingConfig-listing-container">
						{!addCustomListingConfig ? (
							<span style={{ cursor: "pointer" }} onClick={() => setAddCustomListingConfig(!addCustomListingConfig)}>
								+ Add / Edit Custom Listing Configration
							</span>
						) : (
							<span className="close" onClick={() => setAddCustomListingConfig(!addCustomListingConfig)}>
								close
							</span>
						)}
					</div>
					{addCustomListingConfig && (
						<ListingConfigration listingConfig={listingConfig} setListingConfig={setListingConfig} productCategories={productCategories} products={products} suppliers={suppliers} />
					)}
				</div>
				</AccordionWrapper>

				
				<AccordionWrapper title='Approval Configuration' isOpenAcc={false}>
				{/* <div className="mt-2">
					<h5 className="checkpoint_subHeading">Approval Configuration</h5>
				</div> */}
				<div className="approval-container">
					<div>
						<Checkbox
							id="sendApproval"
							name="sendApproval"
							label="Send Approval"
							checked={approvalConfig.sendApproval}
							onChange={(e) => {
								onhandleConfigration(e.target.checked, e.target.name, "approvalConfig");
							}}
						/>
					</div>
					{approvalConfig.sendApproval && (
						<div>
							<Checkbox
								id="dependentApproval"
								name="dependentApproval"
								label="Dependent Approval"
								checked={approvalConfig.dependentApproval}
								onChange={(e) => {
									onhandleConfigration(e.target.checked, e.target.name, "approvalConfig");
								}}
							/>
						</div>
					)}
					{approvalConfig.sendApproval && !approvalConfig.dependentApproval && (
						<div className="dropdown-div">
							<Dropdown
								id={"approvalId"}
								name={"approvalId"}
								value={approvalConfig.approvalId ? approvalConfigOptions.find((ele) => ele.value == approvalConfig.approvalId) : null}
								options={approvalConfigOptions}
								label={"Select Configuration"}
								onChange={(option, key) => {
									onhandleConfigration(option?.value, key, "approvalConfig");
								}}
							/>
						</div>
					)}
					{approvalConfig.dependentApproval && (
						<div className="approvaldependancy-container border boder-rad-10p">
							{approvalConfig.approvaldependancy?.map(({ itemType, id }, i) => {
								const ItemOption =
									itemType === "productCategory"
										? productCategories.map((ele) => ({ label: ele.productCategory, value: ele._id }))
										: itemType === "product"
										? products.map((ele) => ({ label: ele.productName, value: ele._id }))
										: itemType === "supplier"
										? suppliers.map((ele) => ({ label: ele.supplierName, value: ele._id }))
										: [];
								return (
									<div key={`approval_${i}`} className="form-inputs">
										<div className="dropdown-div">
											<Dropdown
												id={"itemType"}
												name={"itemType"}
												value={itemType ? itemTypeOption2.filter((ele) => ele.value === itemType)[0] : null}
												options={itemTypeOption2}
												label={"Item Type"}
												onChange={(option, key) => onhandleApprovalConfigration(option, key, i)}
												required
												error={error.approvalConfig?.approvaldependancy?.length > 0 ? error.approvalConfig?.approvaldependancy[i]?.itemType : ""}
											/>
										</div>
										<div className="dropdown-div">
											<Dropdown
												id={"id"}
												name={"id"}
												value={ItemOption.length > 0 ? filterOption(ItemOption, id) : []}
												options={ItemOption}
												label={"Select Item"}
												multiple={true}
												onChange={(option, key) => onhandleApprovalConfigration(option, key, i)}
												required
												error={error.approvalConfig?.approvaldependancy?.length > 0 ? error.approvalConfig?.approvaldependancy[i]?.id : ""}
											/>
										</div>
										<div className="dropdown-div">
											<Dropdown
												id={"approvalId"}
												name={"approvalId"}
												value={
													approvalConfig?.approvaldependancy?.[i].approvalId
														? approvalConfigOptions.find((ele) => ele.value == approvalConfig?.approvaldependancy?.[i].approvalId)
														: ""
												}
												options={approvalConfigOptions}
												label={"Select Configuration"}
												onChange={(option, key) => {
													onhandleApprovalConfigration(option?.value, key, i);
												}}
											/>
										</div>
										<AddRemove
											list={approvalConfig.approvaldependancy}
											filterMethod={(d) => d.itemType == ""}
											index={i}
											outerIndex={i}
											onAdd={addApprovalDependancyListing}
											onRemove={RemoveApprovalDependancyListing}
										/>
									</div>
								);
							})}
						</div>
					)}
				</div>
				</AccordionWrapper>
				<AccordionWrapper title='Device Scan Configuration' isOpenAcc={false}>
				<div className="mt-2">
					<h5 className="checkpoint_subHeading">Device Scan Configuration</h5>
				</div>
				<div className="deviceScannning-container">
					<div>
						<Checkbox
							id="deviceScannning"
							name="deviceScannning"
							checked={scanConfig.deviceScannning}
							label="Device Scanning"
							onChange={(e) => {
								onhandleConfigration(e.target.checked, e.target.name, "scanConfig");
							}}
						/>
					</div>
					{scanConfig.deviceScannning && (
						<>
							<div className="dropdown-div">
								<Dropdown
									id={"deviceId"}
									name={"deviceId"}
									value={
										scanConfig.deviceId
											? devices.length > 0 && devices.map((ele) => ({ label: ele.displayName, value: ele.imei })).filter((ele) => ele.value === scanConfig.deviceId)[0]
											: []
									}
									options={
										devices.length > 0 ? devices.filter((ele) => !["led", "speaker"].includes(ele.deviceType)).map((ele) => ({ label: ele.displayName, value: ele.imei })) : []
									}
									label={"Device Id"}
									multiple={false}
									onChange={(option, key) => onhandleConfigration(option?.value, key, "scanConfig")}
									required
									error={error?.scanConfig?.deviceId}
								/>
							</div>
							<div className="dropdown-div">
								<Dropdown
									id={"antennaId"}
									name={"antennaId"}
									value={scanConfig.antennaId && scanConfig.deviceId && devices.length > 0 ? antennaValue(scanConfig.antennaId, devices, scanConfig.deviceId) : []}
									options={
										scanConfig.deviceId && devices.length > 0
											? devices
													.filter((ele) => ele.imei === scanConfig.deviceId)[0]
													?.antenna.filter((ele) => ele.status == "Active")
													.map((ele) => ({ label: `${ele.action} (${ele.antennanumber})`, value: ele.antennanumber })).length > 0
												? devices
														.filter((ele) => ele.imei === scanConfig.deviceId)[0]
														.antenna.filter((ele) => ele.status == "Active")
														.map((ele) => ({ label: `${ele.action} (${ele.antennanumber})`, value: ele.antennanumber }))
												: []
											: []
									}
									label={"Antenna Id"}
									multiple={false}
									onChange={(option, key) => onhandleConfigration(option?.value, key, "scanConfig")}
									required
									error={error?.scanConfig?.antennaId}
								/>
							</div>						
						</>
					)}
				</div>
				</AccordionWrapper>	
				{assetAddPerm || assetScanPerm ? (
					<>
						<div className="mt-2">
							<h5 className="checkpoint_subHeading">Asset Configuration</h5>
						</div>
						<div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
							{assetAddPerm ? (
								<div>
									<Checkbox
										id="assetAddPerm"
										name="assetAdd"
										checked={assetConfig.assetAdd}
										label="Map Assets"
										onChange={(e) => {
											onhandleConfigration(e.target.checked, e.target.name, "assetConfig");
										}}
									/>
								</div>
							) : null}
							{assetScanPerm ? (
								<div>
									<Checkbox
										id="assetScanPerm"
										name="assetScan"
										checked={assetConfig.assetScan}
										label="Scan Assets"
										onChange={(e) => {
											onhandleConfigration(e.target.checked, e.target.name, "assetConfig");
										}}
									/>
								</div>
							) : null}
						</div>
					</>
				) : null}
				<AccordionWrapper title="Accessory Configuration" isOpenAcc={false}>
				{/* <div className="mt-2">
					<h5 className="checkpoint_subHeading">Accessory Configuration</h5>
				</div> */}
				<div>
					{Object.keys(accessories || {}).map((key, index) => {
						if (!["triggerLED", "triggerSpeaker"].includes(key)) {
							const { isActive, value, duration } = accessories[key];
							return (
								<div key={index} className="LED-container">
									<div className="title">{capitalizeCamelCase(key)}</div>
									<Checkbox
										id="isActive"
										name="isActive"
										checked={isActive}
										label="Is Active?"
										onChange={(e) => {
											setAccessories((o) => ({
												...o,
												[key]: {
													...o[key],
													[e.target.name]: e.target.checked,
												},
											}));
										}}
									/>
									<Input
										id="value"
										name="value"
										value={value}
										label="Value"
										onChange={(e) => {
											setAccessories((o) => ({
												...o,
												[key]: {
													...o[key],
													[e.target.name]: e.target.value,
												},
											}));
										}}
									/>
									<Input
										type="number"
										id="duration"
										name="duration"
										value={duration}
										label="Duration"
										onChange={(e) => {
											setAccessories((o) => ({
												...o,
												[key]: {
													...o[key],
													[e.target.name]: e.target.value,
												},
											}));
										}}
									/>
								</div>
							);
						}
					})}
				</div>
				<div className="LED-container">
					<div className="title">Trigger LED</div>
					<Checkbox
						id="triggerLED"
						name="triggerLED"
						checked={LEDConfig?.isActive}
						label="Is Active?"
						onChange={(e) => {
							setLEDConfig((prev) => (e.target.checked ? { ...prev, ["isActive"]: true } : DEFAULT_LED_CONFIG));
						}}
					/>
					<div className="dropdown-div">
						<Dropdown
							id={"ledId"}
							name={"ledId"}
							value={
								LEDConfig?.ledDetails?.ledId
									? devices.length > 0 && devices.map((ele) => ({ label: ele.displayName, value: ele.imei })).filter((ele) => ele.value === LEDConfig.ledDetails.ledId)[0]
									: []
							}
							options={devices.length > 0 ? devices.filter((ele) => ele.deviceType === "led").map((ele) => ({ label: ele.displayName, value: ele.imei })) : []}
							label={"LED Device Id"}
							multiple={false}
							onChange={(option, key) =>
								setLEDConfig((prev) => ({
									...prev,
									ledDetails: {
										...prev.ledDetails,
										ledId: option?.value || "",
									},
								}))
							}
							required
							error={error?.ledConfig?.ledId}
						/>
					</div>
					<Input
						id="lineOne"
						name="lineOne"
						value={LEDConfig?.ledDetails?.lineOne}
						label="Line One"
						onChange={(e) => {
							setLEDConfig((prev) => {
								let matches = e.target.value.match(/\{(\d+)+\}/g);
								let varList;
								if (matches && matches.length) {
									varList = prev.ledDetails.variableList.slice(0, matches.length);
								} else {
									varList = [];
								}
								return {
									...prev,
									ledDetails: {
										...prev.ledDetails,
										lineOne: e.target.value,
										variableList: varList,
									},
								};
							});
						}}
						required
						error={error?.ledConfig?.lineOne}
					/>
					<Tooltip title="Use {0} for Dynamic data. Count will be increased for multi dynamic data">
						<InfoIcon sx={{ fill: "#ff7200" }} />
					</Tooltip>
					{/* <Input
						type="number"
						id="duration"
						name="duration"
						// value={duration}
						label="Line Two"
					/> */}
				</div>
				{ledLineOneIndices.length > 0 && (
					<div className="LED-sub-container">
						<span className="div-label">Line One</span>
						{ledLineOneIndices.map((ele, index) => (
							<div key={ele.Index} className="listing-card-container">
								<div className="listing-card">
									<label>#Index</label>
									<span>{ele.element}</span>
								</div>
								<div className="listing-card-filter">
									<div className="dropdown-div">
										<Dropdown
											id={`variableList-${index}`}
											name={`variableList`}
											value={findVariableListing(index, LEDConfig?.ledDetails?.variableList, variableListing)}
											options={variableListing || []}
											label={"Variable List"}
											multiple={false}
											onChange={(option, key) => {
												setLEDConfig((prev) => {
													let ledDetails = JSON.parse(JSON.stringify({ ...prev.ledDetails }));
													ledDetails.variableList.splice(index, 1, option?.value || "");
													return {
														...prev,
														ledDetails,
													};
												});
											}}
											required
											getOptionDisabled={(option) => {
												return LEDConfig?.ledDetails?.variableList?.length > 0 ? LEDConfig?.ledDetails?.variableList.some((filterItem) => filterItem === option.value) : false;
											}}
											error={error?.ledConfig?.variableList?.[index]?.toString() ?? false}
										/>
									</div>
								</div>
							</div>
						))}
					</div>
				)}
				<div className="LED-container">
					<div className="title">Trigger Speaker</div>
					<Checkbox
						id="triggerSpeaker"
						name="isActive"
						checked={speakerConfig?.isActive}
						label="Is Active?"
						onChange={(e) => {
							setSpeakerConfig((prev) => ({ ...prev, ["isActive"]: e.target.checked }));
						}}
					/>
					<div className="dropdown-div">
						<Dropdown
							id={"speakerId"}
							name={"speakerId"}
							value={
								speakerConfig?.speakerDetails?.speakerId
									? devices.length > 0 &&
									  devices.map((ele) => ({ label: ele.displayName, value: ele.imei })).filter((ele) => ele.value === speakerConfig?.speakerDetails?.speakerId)[0]
									: []
							}
							options={devices.length > 0 ? devices.filter((ele) => ele.deviceType === "speaker").map((ele) => ({ label: ele.displayName, value: ele.imei })) : []}
							label={"Speaker Device Id"}
							multiple={false}
							onChange={(option, key) =>
								setSpeakerConfig((prev) => ({
									...prev,
									speakerDetails: {
										...prev.speakerDetails,
										speakerId: option?.value || "",
									},
								}))
							}
							required
							error={error?.speakerConfig?.speakerId}
						/>
					</div>
					<Input
						id="lineOne"
						name="lineOne"
						value={speakerConfig?.speakerDetails?.lineOne}
						label="Line One"
						onChange={(e) => {
							setSpeakerConfig((prev) => {
								let matches = e.target.value.match(/\{(\d+)+\}/g);
								let varList;
								if (matches && matches.length) {
									varList = prev.speakerDetails.variableList.slice(0, matches.length);
								} else {
									varList = [];
								}
								return {
									...prev,
									speakerDetails: {
										...prev.speakerDetails,
										lineOne: e.target.value,
										variableList: varList,
									},
								};
							});
						}}
						required
						error={error?.speakerConfig?.lineOne}
					/>
					<Tooltip title="Use {0} for Dynamic data. Count will be increased for multi dynamic data">
						<InfoIcon sx={{ fill: "#ff7200" }} />
					</Tooltip>
					{/* <Input
						type="number"
						id="duration"
						name="duration"
						// value={duration}
						label="Line Two"
					/> */}
				</div>
				{speakerLineOneIndices.length > 0 && (
					<div className="LED-sub-container">
						<span className="div-label">Line One</span>
						{speakerLineOneIndices.map((ele, index) => (
							<div key={ele.Index} className="listing-card-container">
								<div className="listing-card">
									<label>#Index</label>
									<span>{ele.element}</span>
								</div>
								<div className="listing-card-filter">
									<div className="dropdown-div">
										<Dropdown
											id={`variableList-${index}`}
											name={`variableList`}
											value={findVariableListing(index, speakerConfig?.speakerDetails?.variableList, variableListing)}
											options={variableListing || []}
											label={"Variable List"}
											multiple={false}
											onChange={(option, key) => {
												setSpeakerConfig((prev) => {
													let speakerDetails = JSON.parse(JSON.stringify({ ...prev.speakerDetails }));
													speakerDetails.variableList.splice(index, 1, option?.value || "");
													return {
														...prev,
														speakerDetails,
													};
												});
											}}
											required
											getOptionDisabled={(option) => {
												return speakerConfig?.speakerDetails?.variableList?.length > 0
													? speakerConfig?.speakerDetails?.variableList.some((filterItem) => filterItem === option.value)
													: false;
											}}
											error={error?.speakerConfig?.variableList?.[index]?.toString() ?? false}
										/>
									</div>
								</div>
							</div>
						))}
					</div>
				)}

			</AccordionWrapper>

			<AccordionWrapper title='Checkout Configuration' isOpenAcc={false}>

				{/* <div className="mt-2">
					<h5 className="checkpoint_subHeading">Checkout Configuration</h5>
				</div> */}
				<div className="checkoutConfig-container">
					{scanConfig.deviceScannning && (
						<div>
							<Checkbox
								id="checkoutWhenOutOfRange"
								name="checkoutWhenOutOfRange"
								checked={checkoutConfig.checkoutWhenOutOfRange}
								label="Checkout when out of Device Range"
								onChange={(e) => {
									onhandleConfigration(e.target.checked, e.target.name, "checkpointConfig");
								}}
							/>
						</div>
					)}
					<div>
						<Checkbox
							id="checkoutOnSubmit"
							name="checkoutOnSubmit"
							checked={checkoutConfig.checkoutOnSubmit}
							label="Checkout on Submit"
							onChange={(e) => {
								onhandleConfigration(e.target.checked, e.target.name, "checkpointConfig");
							}}
						/>
					</div>
					<div>
						<Checkbox
							id="autoCheckout"
							name="autoCheckout"
							checked={checkoutConfig.autoCheckout}
							label="Auto Checkout"
							onChange={(e) => {
								onhandleConfigration(e.target.checked, e.target.name, "checkpointConfig");
							}}
						/>
					</div>
					<div>
						<Checkbox
							id="checkoutOnApproval"
							name="checkoutOnApproval"
							checked={checkoutConfig.checkoutOnApproval}
							label="Checkout on Approval"
							onChange={(e) => {
								onhandleConfigration(e.target.checked, e.target.name, "checkpointConfig");
							}}
						/>
					</div>

					<div className="dropdown-div">
						<Dropdown
							id={"checkoutConfig_templateId"}
							name={"templateId"}
							value={
								templateList && templateList?.data && templateList?.data?.length > 0
									? templateList?.data?.filter((ele) => ele._id === checkoutConfig.templateId).map((ele) => ({ label: ele.name, value: ele._id }))[0]
									: null
							}
							options={templateList && templateList?.data && templateList?.data?.length > 0 ? templateList?.data?.map((ele) => ({ label: ele.name, value: ele._id })) : []}
							label={"Checkout template Id"}
							onChange={(option, key) => onhandleConfigration(option?.value, key, "checkpointConfig")}
							// required
							error={error?.checkoutConfig?.templateId}
						/>
					</div>
					{/* <Input
						id={"checkoutConfig_templateId"}
						name={"templateId"}
						value={checkoutConfig.templateId}
						type={"text"}
						label={"Checkout template Id"}
						onChange={(e) => {
							onhandleConfigration(e.target.value, e.target.name, "checkpointConfig");
						}}
						error={error?.checkoutConfig?.templateId}
					/> */}
					<Input
						id={"buttonLabel"}
						name={"buttonLabel"}
						value={checkoutConfig.buttonLabel}
						type={"text"}
						label={"Button label"}
						onChange={(e) => {
							onhandleConfigration(e.target.value, e.target.name, "checkpointConfig");
						}}
						// required
						error={error?.checkoutConfig?.buttonLabel}
					/>
					<Input
						id={"submitFormTitle"}
						name={"submitFormTitle"}
						value={checkoutConfig.submitFormTitle}
						type={"text"}
						label={"Submit Form Title"}
						onChange={(e) => {
							onhandleConfigration(e.target.value, e.target.name, "checkpointConfig");
						}}
						// required
						error={error?.checkoutConfig?.submitFormTitle}
					/>
					<Input
						id={"entrySuccessText"}
						name={"entrySuccessText"}
						value={checkoutConfig.entrySuccessText}
						type={"text"}
						label={"Entry Success Text"}
						onChange={(e) => {
							onhandleConfigration(e.target.value, e.target.name, "checkpointConfig");
						}}
						// error={error?.checkoutConfig?.entrySuccessText}
					/>
					<div className="checkpoint-detail-container">
						<Checkbox
							id="addCustomCheckoutAPIEndpoint"
							name="addCustomCheckoutAPIEndpoint"
							checked={addCustomCheckoutAPIEndpoint}
							label="Add Custom Checkout API Endpoints"
							onChange={(e) => {
								setAddCustomCheckoutAPIEndpoint(e.target.checked);
							}}
						/>
						{addCustomCheckoutAPIEndpoint && (
							<section className="checkpoint-detail-container">
								<Input
									id={"checkoutConfig_apiMethod"}
									name={"apiMethod"}
									value={checkoutConfig.apiMethod}
									type={"text"}
									label={"API Method"}
									onChange={(e) => {
										onhandleConfigration(e.target.value, e.target.name, "checkpointConfig");
									}}
									required
									error={error?.checkoutConfig?.apiMethod}
								/>
								<div className="dropdown-div">
									<Dropdown
										id={"checkoutConfig_api"}
										name={"api"}
										// value={checkoutConfig.api ? (checkpointType.filter((ele) => ele.value === checkoutConfig.api))[0] : null}
										// options={checkpointType}
										value={
											ApiOption && ApiOption?.data && checkoutConfig.api
												? (ApiOption?.data.map((ele) => ({ label: ele.name, value: ele.endPoint })).filter((ele) => ele.value === checkoutConfig.api))[0]
												: null
										}
										options={ApiOption && ApiOption?.data ? ApiOption?.data.map((ele) => ({ label: ele.name, value: ele.endPoint })) : []}
										label={"Select API"}
										onChange={(option, key) => onhandleConfigration(option, key, "checkpointConfig")}
										required
										error={error?.checkoutConfig?.api}
									/>
								</div>
							</section>
						)}
					</div>
				</div>
			</AccordionWrapper>
				
			</div>
			<div className="d-flex justify-content-center my-4">
				<Button
					testId={"Submit"}
					text={isDuplicate ? "Submit" : Object.keys(updatedData)?.length > 0 ? "Update" : "Submit"}
					style={{ ...buttonClasses.lynkitOrangeFill, width: "10%" }}
					// disabled={false}
					onClick={() => handleSubmit(isDuplicate ? "submit" : Object.keys(updatedData)?.length > 0 ? "update" : "submit")}
				/>
			</div>
		</>
	);
}

const findVariableListing = (index, variableList, optionList) => {
	const currentList = variableList.length > 0 ? variableList[index] : null;
	if (currentList) {
		const currentValue = optionList.filter((ele) => ele.value === currentList)[0];
		return currentValue;
	}
	return [];
};

export const isValidLatitude = (lat) => {
	// Latitude range: -90 to 90
	const latRegex = /^-?([1-8]?[0-9](\.\d+)?|90(\.0+)?)$/;
	return latRegex.test(lat);
};

export const isValidLongitude = (lon) => {
	// Longitude range: -180 to 180
	const lonRegex = /^-?((1[0-7]|[1-9])?\d(\.\d+)?|180(\.0+)?)$/;
	return lonRegex.test(lon);
};

export const validatePayload = (payload) => {
	let success = true;
	const error = {
		checkPoint: {},
		listingConfig: [],
		approvalConfig: {},
		scanConfig: {},
		checkoutConfig: {},
		ledConfig: {
			variableList: [],
		},
		speakerConfig: {
			variableList: [],
		},
	};
	// console.log('submit-validaiton', payload);
	//check checkpoint detail
	Object.keys(payload.checkPoint).forEach((ele) => {
		if (ele === "latitude" || ele === "longitude") {
			if (payload.checkPoint[ele] != "") {
				if (ele === "latitude" && !isValidLatitude(payload.checkPoint[ele])) {
					// console.log("Error in checkpoint latitude");
					error.checkPoint[ele] = "Please enter a valid latitude";
					success = false;
				}
				if (ele === "longitude" && !isValidLongitude(payload.checkPoint[ele])) {
					// console.log("Error in checkpoint longitude");
					error.checkPoint[ele] = "Please enter a valid longitude";
					success = false;
				}
			} else if ((ele === "latitude" && payload.checkPoint["longitude"] != "") || (ele === "longitude" && payload.checkPoint["latitude"] != "")) {
				error.checkPoint[ele] = "This field is required";
				success = false;
			} else {
				// error.checkPoint[ele] = "This field is required";
				// success = false;
				return;
			}
		}
		if (payload.checkPoint[ele] === "" && !["defaultWorkflowId", "templateId"].includes(ele)) {
			error.checkPoint[ele] = "This field is required";
			success = false;
			// console.log("Error in checkpoint main");
		}
	});
	// console.log(error)
	//check Listing Configuration
	// payload.listingConfig.forEach((ele, listIndex) => {
	// 	Object.keys(payload.listingConfig[listIndex]).forEach((ele, listItemIndex) => {
	// 		if (payload.listingConfig[listIndex][ele] === "" && !Array.isArray(payload.listingConfig[listIndex][ele])) {
	// 			error.listingConfig[listIndex] = { ...error.listingConfig[listIndex] };
	// 			error.listingConfig[listIndex][ele] = "This field is required";
	// 			success = false;
	// 		}
	// 		if (Array.isArray(payload.listingConfig[listIndex][ele])) {
	// 			error.listingConfig[listIndex] = { ...error.listingConfig[listIndex] };
	// 			error.listingConfig[listIndex]["filter"] = [];
	// 			const filter = payload.listingConfig[listIndex][ele];
	// 			filter.forEach((ele, filterIndex) => {
	// 				Object.keys(ele).forEach((filterItem, filterItemIndex) => {
	// 					if (ele[filterItem] === "" && ele[filterItem].constructor !== Object) {
	// 						error.listingConfig[listIndex]["filter"][filterIndex] = { ...error.listingConfig[listIndex]["filter"][filterIndex] };
	// 						error.listingConfig[listIndex]["filter"][filterIndex][filterItem] = "This field is required";
	// 						success = false;
	// 					}
	// 					if (ele[filterItem].constructor === Object) {
	// 						error.listingConfig[listIndex]["filter"][filterIndex] = { ...error.listingConfig[listIndex]["filter"][filterIndex] };
	// 						error.listingConfig[listIndex]["filter"][filterIndex]["formula"] = { eval: [] };
	// 						ele[filterItem].eval.forEach((formulaItem, formulaIndex) => {
	// 							Object.keys(formulaItem).forEach((evalItem, evalIndex) => {
	// 								if (formulaItem[evalItem] === "") {
	// 									error.listingConfig[listIndex]["filter"][filterIndex]["formula"]["eval"][formulaIndex] = {
	// 										...error.listingConfig[listIndex]["filter"][filterIndex]["formula"]["eval"][formulaIndex],
	// 									};
	// 									error.listingConfig[listIndex]["filter"][filterIndex]["formula"]["eval"][formulaIndex][evalItem] = "This field is required";
	// 									success = false;
	// 								}
	// 							});
	// 						});
	// 					}
	// 				});
	// 			});
	// 		}
	// 	});
	// });

	//check Approval Configuration
	if (payload.approvalConfig.sendApproval && payload.approvalConfig.dependentApproval) {
		// error.approvalConfig["approvaldependancy"] = [];
		payload.approvalConfig.approvaldependancy.forEach((ele, index) => {
			if (ele.itemType === "") {
				error.approvalConfig["approvaldependancy"][index] = { ...error.approvalConfig["approvaldependancy"][index], itemType: "This field is required" };
			}
			if (ele.id.length == 0) {
				error.approvalConfig["approvaldependancy"][index] = { ...error.approvalConfig["approvaldependancy"][index], id: "This field is required" };
			}
		});
	}
	//check Device Scan Configuration
	if (payload.scanConfig.deviceScannning) {
		Object.keys(payload.scanConfig).forEach((ele) => {
			if (payload.scanConfig[ele] === "") {
				error.scanConfig[ele] = "This field is required";
				success = false;
			}
		});
	}

	//check Checkout Configuration

	Object.keys(payload.checkoutConfig).forEach((ele) => {
		// console.log(payload.checkoutConfig[ele], ele)
		if (payload.addCustomCheckoutAPIEndpoint && ["apiMethod", "api"].includes(ele) && payload.checkoutConfig[ele] == "") {
			error.checkoutConfig[ele] = "This field is required";
			success = false;
		}
		if (payload.checkoutConfig.checkoutWhenOutOfRange && !payload.scanConfig.deviceScannning) {
			err.checkoutConfig["checkoutWhenOutOfRange"] = "Cannot use this checkout method if device scanning is disabled.";
			success = false;
		}
	});

	//check led config;
	if (payload.accessories.triggerLED.isActive === true) {
		if (payload.accessories.triggerLED.ledDetails.ledId === "") {
			error.ledConfig.ledId = "This field is required";
			success = false;
		}
		if (payload.accessories.triggerLED.ledDetails.lineOne === "") {
			error.ledConfig.lineOne = "This field is required";
			success = false;
		}
		if (payload.accessories.triggerLED.ledDetails.lineOne) {
			const range = findIndexForVariableList(payload.accessories.triggerLED.ledDetails.lineOne);
			const variableList = payload.accessories.triggerLED.ledDetails.variableList;
			if (range.length > 0) {
				range.forEach((ele, index) => {
					if (!variableList[index]) {
						error.ledConfig.variableList.splice(index, 1, "This field is required");
					} else {
						error.ledConfig.variableList.splice(index, 1, "");
					}
				});
				if (error.ledConfig.variableList.length > 0 && error.ledConfig.variableList.some((ele) => ele !== "")) {
					success = false;
				} else {
					success = true;
				}
			}
		}
	}

	//check speaker config;
	if (payload.accessories.triggerSpeaker.isActive === true) {
		if (payload.accessories.triggerSpeaker.speakerDetails.speakerId === "") {
			error.speakerConfig.speakerId = "This field is required";
			success = false;
		}
		if (payload.accessories.triggerSpeaker.speakerDetails.lineOne === "") {
			error.speakerConfig.lineOne = "This field is required";
			success = false;
		}
		if (payload.accessories.triggerSpeaker.speakerDetails.lineOne) {
			const range = findIndexForVariableList(payload.accessories.triggerSpeaker.speakerDetails.lineOne);
			const variableList = payload.accessories.triggerSpeaker.speakerDetails.variableList;
			if (range.length > 0) {
				range.forEach((ele, index) => {
					if (!variableList[index]) {
						error.speakerConfig.variableList.splice(index, 1, "This field is required");
					} else {
						error.speakerConfig.variableList.splice(index, 1, "");
					}
				});
				if (error.speakerConfig.variableList.length > 0 && error.speakerConfig.variableList.some((ele) => ele !== "")) {
					success = false;
				} else {
					success = true;
				}
			}
		}
	}

	// console.log('submit-validaiton-error', error)
	return { error: error, success: success };
};

const antennaValue = (antennaValue, devices, deviceValue) => {
	// console.log(antennaValue, devices, deviceValue);
	const selectedDevice = devices.find((ele) => ele.imei === deviceValue);
	const activeAntennas = selectedDevice ? selectedDevice.antenna.filter((ele) => ele.status === "Active") : [];
	const selectedAntenna = activeAntennas.find((ele) => ele.antennanumber == antennaValue);

	const value = selectedAntenna ? { label: `${selectedAntenna.action} (${selectedAntenna.antennanumber})`, value: selectedAntenna.antennanumber } : null;
	const options = activeAntennas.map((ele) => ({ label: `${ele.action} (${ele.antennanumber})`, value: ele.antennanumber }));
	return value;
};

export const filterOption = (rawOption, selectedOption) => {
	// console.log('item option', rawOption, selectedOption)
	return rawOption?.filter((chk) => selectedOption?.find((fchk) => fchk == chk.value));
};

function ensureValueIsArray(filter) {
	filter.forEach((obj) => {
		processFilter(obj.filter);
	});
	return filter;
}

const findIndexForVariableList = (str) => {
	// const str = '{0} is active {1}';
	const arr = str.split(" ");
	let result = [];
	arr.forEach((ele, index) => {
		if (ele.match(/\{\d+\}/)) {
			result.push({ element: ele, Index: index });
		}
	});
	return result.filter((item, index, self) => index === self.findIndex((t) => t.element === item.element));
};

function processFilter(filters) {
	filters.forEach((filterObj) => {
		if (filterObj.formula) {
			if (filterObj.formula.operator === "object" && Array.isArray(filterObj.formula.eval)) {
				filterObj.formula.eval.forEach((evalObj) => {
					if (evalObj.isDynamic === false && ["$in", "$nin"].includes(evalObj.key)) {
						if (!Array.isArray(evalObj.value)) {
							evalObj.value = [evalObj.value];
						}
					}
				});
			}
			if (filterObj.formula.operator === "array" && Array.isArray(filterObj.formula.subFilter)) {
				processFilter(filterObj.formula.subFilter);
			}
		}
	});
}
