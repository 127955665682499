import React, { useState, } from "react";
import "./WorkFlowIndex.css";
import { Button, ActionMenu, GenericTable, tCell } from "../utils";
import { buttonClasses } from "../utils/theme";
import { useOrgs, useUserDetail, getWorkflow } from "../../services/hooks";
import Genericfilter from "../utils/Genericfilter";
import { debounce } from "../../services/functions";
import { Grid } from "@mui/material";
import { GenericstatsV2 } from "../utils/Genericstats";
import { Link, useNavigate } from "react-router-dom";

export default function WorkFlowIndex() {
	const navigate = useNavigate();
	const [filter, setFilter] = useState({
		pagesize: 10,
		page_no: 1,
	});
	const { orgOptions } = useOrgs();
	const { workflowListing, error: workflowError, isLoading: isUpdating, refetch, count } = getWorkflow(filter);
	const { userDetail } = useUserDetail();

	const filterFields = [
		{
			inputType: "textfield",
			id: "1",
			name: "name",
			type: "text",
			label: "Display Name",
			placeholder: "Display Name",
			readOnly: false,
			error: "",
			value: "",
		},
	];
	if (userDetail?.userType == "superAdmin") {
		filterFields.push({
			inputType: "dropdown",
			id: "orgId",
			name: "orgId",
			label: "Organization",
			error: "",
			option: orgOptions,
		});
	}

	let actionHandlers = {};
	actionHandlers.edit = (id) => {
		if (workflowListing?.length > 0) {
			navigate("/edit-workflow", { state: { data: workflowListing[id] } });
		}
	};

	actionHandlers.duplicate = (id) => {
		if (workflowListing?.length > 0) {

			navigate("/add-workflow", { state: { data: workflowListing[id] } });
		}

	};
	let header = ["S.no", "Display Name", "Org Name", "Action"];
	const rows = fetchRow(workflowListing, filter, ActionMenu, actionHandlers);

	const onPageChange = (page_no) => {
		let filterRef = { ...filter, page_no: page_no + 1 };
		setFilter(filterRef);
		refetch;
	};
	const onPageSizeChange = (e) => {
		const { value } = e.target;
		let filterRef = { ...filter, pagesize: value };
		setFilter(filterRef);
		refetch;
	};

	const handleFilterChange = (filterr) => {
		let filterRef = { ...filter, filter: { ...filterr } };
		delete filterRef.filter.orgId;
		const orgIdValue = filterr?.orgId?.value || "";
		filterRef.orgId = orgIdValue;
		setFilter(filterRef);
		refetch;
	};

	return (
		<>
			<div className="Workflow_panel">
				<Grid container spacing="1rem">
					<Grid item xl={11} lg={10} md={10} sm={9} xs={6}>
						<div className="workflow_heading">Workflow</div>
					</Grid>
					<Grid item xl={1} lg={2} md={2} sm={3} xs={6} display={"flex"} justifyContent={"flex-end"}>
						<Link to="/add-workflow" className="a_tag_no">
							<Button
								text="Add Workflow"
								style={{ ...buttonClasses.lynkitOrangeEmpty, width: "fit-content" }}
							/>
						</Link>
					</Grid>
				</Grid>
			</div>
			<>
				<GenericstatsV2 statsFor={"workflow"} />
				<div className="cust-row flex-algn-cent">
					<Genericfilter filterFields={filterFields} onFilterChange={debounce((filter) => handleFilterChange(filter), 500)} />
				</div>
				<div>
					<GenericTable
						header={header}
						rows={rows}
						pageCount={count}
						pageNo={filter.page_no}
						limit={filter.pagesize}
						onPageChange={onPageChange}
						onPageSizeChange={onPageSizeChange}
						isLoading={isUpdating}
					/>
				</div>
			</>
		</>
	);
}

const fetchRow = (workflowListing, filter, ActionMenu, actionHandlers) => {
	const { page_no = 1, pagesize = 10 } = filter;
	return (
		workflowListing?.map((d, i) => {
			let row = [];
			row.push(
				tCell((page_no - 1) * pagesize + i + 1),
				tCell(d.name),
				tCell(d.orgName || "-")
				// tCell("-"),
			);
			row.push(<ActionMenu id={i} handlers={actionHandlers} />);

			return row;
		}) || []
	);
};
