import { useEffect, useMemo, useState } from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import { useCheckpoints, useThemeSetting, useUserDetail, useUserPermissions } from "../../services/hooks";
import MainContent from "../Layout/MainContent";
import theme, { buttonClasses } from "../utils/theme";
import UploadFileButton, { AddRemove, Button, Checkbox, ColorPicker, Dropdown, Input, Loader, toastMessage } from "../utils";
import { base64Convert, capitalizeCamelCase } from "../../services/functions";
import { assignDeep } from "../Dynamic/DynamicForm";
import "./Settings.css";
import { useGetOrgSettingQuery, useGetTemplateDetailOnCallMutation, useUpdateOrgSettingMutation } from "../../api/vmsSlice";

function getDefaultPrefix() {
	return { type: "", value: "" };
}
function getDefaultPrefixConfig() {
	return {
		isDefault: false,
		reset_type: "",
		prefix_type: "vehicleEntry",
		prefix_sub_type: "",
		prefix: [getDefaultPrefix()],
	};
}

const BASIC_DETAILS = {
	displayName: "Basic Details",
	checkpointId: "BasicDetails",
	fields: [
		{ label: "Vehicle No.", value: "", isChecked: false },
		{ label: "Supplier", value: "", isChecked: false },
		{ label: "Product Category", value: "", isChecked: false },
		{ label: "Product", value: "", isChecked: false },
		{ label: "Trip ID", value: "", isChecked: false },
		{ label: "Driver Name", value: "", isChecked: false },
		{ label: "Driver Number", value: "", isChecked: false },
		{ label: "Allocate RFID Tag ( Optional )", value: "", isChecked: false },
		{ label: "Vehicle RC Number", value: "", isChecked: false },
		{ label: "Trip Status", value: "", isChecked: false },
		{ label: "Remarks", value: "", isChecked: false },
		{ label: "Tag tId", value: "", isChecked: false },
		{ label: "Tag EPC", value: "", isChecked: false },
		{ label: "Tag Type", value: "", isChecked: false },
		{ label: "Tag Display Name", value: "", isChecked: false },
		{ label: "Vehicle In Time", value: "", isChecked: false },
		{ label: "Vehicle Out Time", value: "", isChecked: false },
	],
};

const DEFAULT_THEME_FORM = {
	title: "LynkID",
	theme: {
		primaryColor: "#ff7200",
		selectedTheme: "lightMode",
	},
	logo: {
		fileData: "",
		docType: "",
		fileName: "",
	},
	favIcon: {
		fileData: "",
		docType: "",
		fileName: "",
	},
	statIcon: {
		total: {
			fileData: "",
			docType: "",
			fileName: "",
		},
		todayIn: {
			fileData: "",
			docType: "",
			fileName: "",
		},
		todayOut: {
			fileData: "",
			docType: "",
			fileName: "",
		},
	},
};

const DEFAULT_FORM = {
	prefixes: [getDefaultPrefixConfig()],

	pdfSettings: {
		doc_type: "vehicle_detail",
		default_fields: [BASIC_DETAILS],
	},
};

function useOrgSetting() {
	const { data, isFetching } = useGetOrgSettingQuery({});
	let orgSetting = useMemo(() => {
		let { data: orgSetting = {} } = data || { data: {} };
		return orgSetting;
	}, [data]);

	const [updateOrgSetting, { isLoading }] = useUpdateOrgSettingMutation();
	return { orgSetting, isOrgSettingLoading: isFetching, updateOrgSetting, isOrgSettingUpdating: isLoading };
}

function Settings() {
	const { userPermissions } = useUserPermissions();
	const { checkpoints } = useCheckpoints();
	const [getTemplateDetail] = useGetTemplateDetailOnCallMutation();

	const { orgSetting, isOrgSettingLoading, updateOrgSetting, isOrgSettingUpdating } = useOrgSetting();

	useEffect(() => {
		if (!Object.keys(orgSetting).length) return;
		setForm((o) => ({
			...o,
			prefixes: orgSetting.prefixArray.map((_) => ({
				isDefault: _.isDefault,
				reset_type: _.reset_type,
				prefix_type: _.prefix_type,
				prefix_sub_type: _.prefix_sub_type,
				prefix: _.prefix,
			})),
		}));
	}, [orgSetting]);

	const showThemeSettings = userPermissions?.customizeTheme?.value;
	const [tab, setTab] = useState(showThemeSettings ? "theme" : "prefix");

	const [form, setForm] = useState(DEFAULT_FORM);

	const handlePrefixChange = (e, prefixIndex, prefixPartIndex) => {
		let { name, value, type, checked } = e.target;
		value = type == "checkbox" ? checked : value;
		setForm((o) => ({
			...o,
			prefixes: o.prefixes.map((_, i) => {
				if (i == prefixIndex) {
					if (prefixPartIndex != undefined) {
						return {
							..._,
							prefix: _.prefix.map((_, j) => {
								if (j == prefixPartIndex) {
									return { ..._, value: name == "type" ? "" : _.value, [name]: value };
								}
								return _;
							}),
						};
					}
					return { ..._, [name]: value };
				}
				if (name == "isDefault") {
					return { ..._, isDefault: false };
				}
				return _;
			}),
		}));
	};
	const handleSettingSubmit = (type) => {
		let error = {};

		if (type == "prefix") {
			// console.log(form);
			form.prefixes.forEach(({ prefix }, i) => {
				prefix.forEach((p) => {
					if (!p.type || !p.value) {
						if (!["counter"].includes(p.type)) {
							error[i] = "Missing Value";
						}
					}
				});
			});
		}


		if (Object.keys(error).length) {
			toastMessage(false, "Please fill in the input values before submitting");
		} else {
			updateOrgSetting(form)
				.unwrap()
				.then((res) => {
					if (res.error) {
						toastMessage(false, res.message);
					} else {
						toastMessage(true, res.message);
					}
				})
				.catch((err) => {
					// console.log(err);
					toastMessage(false, "Error in updating theme setting");
				});
		}
	};
	const handleReset = () => {
		setForm({
			prefixes: DEFAULT_FORM.prefixes,
			pdfSettings: { ...form.pdfSettings, default_fields: form.pdfSettings.default_fields.map((_) => ({ ..._, fields: _.fields.map((_) => ({ ..._, isChecked: false })) })) },
		});
	};
	const prefixUI = (
		<>
			{isOrgSettingLoading || isOrgSettingUpdating ? (
				<Loader size="2rem" height="8.5rem" />
			) : (
				<section className="prefixes">
					{form.prefixes.map(({ reset_type, prefix, isDefault }, i) => {
						let prefixResult = prefix.reduce((res, curr) => {
							switch (curr.type) {
								case "date":
								case "global":
									res += curr.value ? `<${curr.value}>` : "";
									break;
								case "finance_year":
									res += curr.value ? `<${financialPrefixOptions.find((_) => _.value == curr.value)?.label}>` : "";
									break;
								case "product":
									res += curr.value ? `<product-${curr.value}>` : "";
									break;
								case "productCategories":
									res += curr.value ? `<productCategories-${curr.value}>` : "";
									break;
								case "supplier":
									res += curr.value ? `<supplier-${curr.value}>` : "";
									break;
								case "counter":
									res += "<COUNTER>";
									break;
							}
							return res;
						}, "");
						if (!prefixResult.includes("<COUNTER>")) {
							prefixResult += "<COUNTER>";
						}

						return (
							<div className="prefix" key={i}>
								<div className="prefix-row">
									<Checkbox label="Default Prefix" name="isDefault" checked={isDefault} onChange={(e) => handlePrefixChange(e, i)} />
									<AddRemove
										list={form.prefixes}
										filterMethod={({ prefix }) => prefix.every(({ type, value }) => type == "" && value == "")}
										onAdd={() => setForm((o) => ({ ...o, prefixes: [...o.prefixes, getDefaultPrefixConfig()] }))}
										onRemove={(index) => setForm((o) => ({ ...o, prefixes: o.prefixes.filter((_, pI) => pI != index) }))}
										index={i}
										outerIndex={i}
									/>
								</div>

								{prefix.map(({ type, value }, j) => {
									return (
										<div key={j} className="form-inputs" style={{ marginTop: "8px" }}>
											<Dropdown
												label="Type"
												name="type"
												value={prefixTypeOptions.find((_) => _.value == type) || null}
												options={prefixTypeOptions}
												onChange={(opt) => {
													handlePrefixChange({ target: { name: "type", value: opt?.value || "" } }, i, j);
												}}
												getOptionDisabled={(option) => {
													return !!prefix.find((_) => _.type == option.value);
												}}
											/>
											<div className="prefix-input-wrap">
												{type == "date" ? (
													<>
														<Dropdown
															label="Format"
															name="value"
															value={datePrefixOptions.find((_) => _.value == value) || null}
															options={datePrefixOptions}
															onChange={(opt) => {
																handlePrefixChange({ target: { name: "value", value: opt?.value || "" } }, i, j);
															}}
														/>
														<Dropdown
															label="Reset On"
															name="reset_type"
															value={resetOptions.find((_) => _.value == reset_type) || null}
															options={resetOptions}
															onChange={(opt) => {
																handlePrefixChange({ target: { name: "reset_type", value: opt?.value || "" } }, i);
															}}
														/>
													</>
												) : type == "finance_year" ? (
													<Dropdown
														label="Format"
														name="value"
														value={financialPrefixOptions.find((_) => _.value == value) || null}
														options={financialPrefixOptions}
														onChange={(opt) => {
															handlePrefixChange({ target: { name: "value", value: opt?.value || "" } }, i, j);
														}}
													/>
												) : type == "global" ? (
													<Input label="Value" name="value" value={value} onChange={(e) => handlePrefixChange(e, i, j)} />
												) : ["product"].includes(type) ? (
													<Dropdown
														label="Format"
														name="value"
														value={productPrefixOptions.find((_) => _.value == value) || null}
														options={productPrefixOptions}
														onChange={(opt) => {
															handlePrefixChange({ target: { name: "value", value: opt?.value || "" } }, i, j);
														}}
													/>
												) : ["productCategories"].includes(type) ? (
													<Dropdown
														label="Format"
														name="value"
														value={productCatPrefixOptions.find((_) => _.value == value) || null}
														options={productCatPrefixOptions}
														onChange={(opt) => {
															handlePrefixChange({ target: { name: "value", value: opt?.value || "" } }, i, j);
														}}
													/>
												) : ["supplier"].includes(type) ? (
													<Dropdown
														label="Format"
														name="value"
														value={supplierPrefixOptions.find((_) => _.value == value) || null}
														options={supplierPrefixOptions}
														onChange={(opt) => {
															handlePrefixChange({ target: { name: "value", value: opt?.value || "" } }, i, j);
														}}
													/>
												) : null}
											</div>

											<AddRemove
												list={prefix}
												filterMethod={({ type, value }) => type == "" && value == ""}
												onAdd={() => {
													setForm((o) => ({
														...o,
														prefixes: o.prefixes.map((_, pI) => {
															if (pI == i) {
																return { ..._, prefix: [..._.prefix, getDefaultPrefix()] };
															}
															return _;
														}),
													}));
												}}
												onRemove={(index) => {
													setForm((o) => ({
														...o,
														prefixes: o.prefixes.map((_, pI) => {
															if (pI == i) {
																return { ..._, prefix: _.prefix.filter((_, j) => j != index) };
															}
															return _;
														}),
													}));
												}}
												index={j}
												outerIndex={j}
											/>
										</div>
									);
								})}

								<div className="prefix_output">
									<div>RESULT:</div>
									<div>
										<b>{prefixResult}</b>
									</div>
								</div>
							</div>
						);
					})}
				</section>
			)}
			<div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem", marginTop: "5rem" }}>
				<Button onClick={handleReset} text="Reset" style={{ ...buttonClasses.lynkitBlackEmpty, width: "fit-content" }} disabled={isOrgSettingLoading || isOrgSettingUpdating} />
				<Button
					onClick={() => handleSettingSubmit("prefix")}
					text="Save Changes"
					style={{ ...buttonClasses.lynkitOrangeEmpty, width: "fit-content" }}
					disabled={isOrgSettingLoading || isOrgSettingUpdating}
				/>
			</div>
		</>
	);

	useEffect(() => {
		if (form.pdfSettings.doc_type == "vehicle_detail") {
			let promiseArr = [];
			let tempMap = new Map();
			for (let c of checkpoints) {
				let key = c.templateId;
				if (c.checkoutConfig?.templateId && c.checkoutConfig?.templateId != c.templateId) {
					key = key + "-" + c.checkoutConfig?.templateId;
				}
				tempMap.set(key, key);
			}

			tempMap.forEach((value) => {
				let ids = value.split("-").map((_) => _.trim());
				// console.log(ids)
				if (ids.length > 1) {
					if (ids[0] == "" && ids[1] == "") return;
					let p = Promise.all([getTemplateDetail({ id: ids[0] }).unwrap(), getTemplateDetail({ id: ids[1] }).unwrap()]).then((results) => {
						if (!results[0].error && !results[1].error) {
							let res = JSON.parse(JSON.stringify(results[0]));
							results[1].data?.attributes?.forEach((attr) => {
								if (res.data?.attributes?.find((_) => _._id == attr._id)) {
								} else {
									res.data?.attributes.push(attr);
								}
							});
							return res;
						} else if (!results[0].error) {
							return results[0];
						} else if (!results[1].error) {
							return results[1];
						}
					});
					promiseArr.push(p);
				} else {
					if (ids[0]) {
						promiseArr.push(getTemplateDetail({ id: ids[0] }).unwrap());
					}
				}
			});

			Promise.all(promiseArr).then((results) => {
				let finalData = [];
				// console.log(results.filter(Boolean));
				checkpoints.forEach((chkpt) => {
					if (chkpt.templateId) {
						let res = results.filter(Boolean).find((_) => _?.data?._id == chkpt.templateId);
						let existingData = orgSetting?.pdfSettings?.default_fields?.find((_) => _.displayName == chkpt.displayName);
						// console.log({i: chkpt.templateId}, res)
						if (res) {
							finalData.push({
								displayName: chkpt.displayName,
								checkpointId: chkpt._id,
								fields: res?.data?.attributes?.map((_) => {
									let existingField = existingData?.fields?.find((f) => f.label == _._id);
									return { isChecked: existingField?.isChecked || false, label: _._id, value: "" };
								}),
							});
						}
					}
				});
				setForm((o) => ({ ...o, pdfSettings: { ...o.pdfSettings, default_fields: [orgSetting?.pdfSettings?.default_fields[0] || BASIC_DETAILS, ...finalData] } }));
			});
		}
	}, [form.pdfSettings.doc_type, orgSetting, checkpoints]);

	const pdfUI = (
		<>
			{isOrgSettingLoading || isOrgSettingUpdating ? (
				<Loader size="2rem" height="8.5rem" />
			) : (
				<div className="print_selection_container">
					<Dropdown
						label="Document Type"
						name="pdfSettings.doc_type"
						value={pdfDocTypeOptions.find((_) => _.value == form.pdfSettings.doc_type) || null}
						options={pdfDocTypeOptions}
						readonly
						disabled
					/>
					{form.pdfSettings.default_fields.map((ele, i) => {
						return (
							<div key={i} style={{ marginTop: i == 0 ? "1rem" : "" }}>
								<div className="level_heading">
									<Checkbox
										id={ele.displayName}
										name={ele.displayName}
										checked={ele?.fields?.every((ele) => ele.isChecked) ? true : false}
										onChange={(e) => {
											setForm((o) => ({
												...o,
												pdfSettings: {
													...o.pdfSettings,
													default_fields: o.pdfSettings.default_fields.map((_, j) => {
														if (i == j) {
															return {
																..._,
																fields: _.fields.map((_) => {
																	return { ..._, isChecked: e.target.checked };
																}),
															};
														}
														return _;
													}),
												},
											}));
										}}
									/>
									<Input
										value={ele.displayName}
										onChange={(e) => {
											setForm((o) => ({
												...o,
												pdfSettings: {
													...o.pdfSettings,
													default_fields: o.pdfSettings.default_fields.map((_, j) => {
														if (i == j) {
															return { ..._, displayName: e.target.value };
														}
														return _;
													}),
												},
											}));
										}}
									/>
								</div>
								<div className="level_1">
									{ele?.fields.map((el) => (
										<div style={{ width: "260px" }} key={el.label}>
											<Checkbox
												id={el.label}
												name={el.label}
												checked={el.isChecked}
												label={el.label}
												onChange={(e) => {
													setForm((o) => ({
														...o,
														pdfSettings: {
															...o.pdfSettings,
															default_fields: o.pdfSettings.default_fields.map((_) => {
																if (_.displayName === ele.displayName) {
																	return {
																		..._,
																		fields: _.fields.map((_) => {
																			if (_.label === e.target.name) {
																				return { ..._, isChecked: e.target.checked };
																			}
																			return _;
																		}),
																	};
																}
																return _;
															}),
														},
													}));
												}}
											/>
										</div>
									))}
								</div>
							</div>
						);
					})}
				</div>
			)}
			<div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem", marginTop: "3rem" }}>
				<Button onClick={handleReset} text="Reset" style={{ ...buttonClasses.lynkitBlackEmpty, width: "fit-content" }} disabled={isOrgSettingLoading || isOrgSettingUpdating} />
				<Button onClick={handleSettingSubmit} text="Save Changes" style={{ ...buttonClasses.lynkitOrangeEmpty, width: "fit-content" }} disabled={isOrgSettingLoading || isOrgSettingUpdating} />
			</div>
		</>
	);

	let tabContentUI;
	switch (tab) {
		case "theme":
			tabContentUI = <ThemeSetting showThemeSettings={showThemeSettings} />;
			break;
		case "prefix":
			tabContentUI = prefixUI;
			break;
		case "pdf":
			tabContentUI = pdfUI;
			break;
		default:
			tabContentUI = <></>;
	}

	return (
		<MainContent title="Settings">
			<Box>
				<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
					<Tabs
						value={tab}
						onChange={(e, v) => setTab(v)}
						aria-label="setting tabs"
						sx={{
							"& .MuiTabs-indicator": { backgroundColor: theme.themeOrange },
							"& .MuiTab-root.Mui-selected": { color: theme.themeOrange },
						}}
					>
						{showThemeSettings ? <Tab label="Theme Options" value="theme" {...a11yProps(0)} /> : null}

						<Tab label="Prefix Options" value="prefix" {...a11yProps(1)} />
						<Tab label="PDF Options" value="pdf" {...a11yProps(2)} />
					</Tabs>
				</Box>
				{tabContentUI}
			</Box>
		</MainContent>
	);
}

export default Settings;

function ThemeSetting({ showThemeSettings }) {
	const { userDetail } = useUserDetail();
	const { orgDetail, isOrgDetailLoading, updateTheme, isOrgDetailUpdating } = useThemeSetting();
	useEffect(() => {
		if (!Object.keys(orgDetail).length) return;
		setTheme((o) => ({
			...o,
			theme: orgDetail.theme,
			title: orgDetail.title,
			logo: {
				fileData: orgDetail.logo,
				docType: "",
				fileName: orgDetail.logoName,
			},
			favIcon: {
				fileData: orgDetail.favIcon,
				docType: "",
				fileName: orgDetail.favIconName,
			},
			statIcon: {
				total: {
					fileData: orgDetail.statIcon?.total || "",
					docType: "",
					fileName: orgDetail.statIcon?.totalName || "",
				},
				todayIn: {
					fileData: orgDetail.statIcon?.todayIn || "",
					docType: "",
					fileName: orgDetail.statIcon?.todayInName || "",
				},
				todayOut: {
					fileData: orgDetail.statIcon?.todayOut || "",
					docType: "",
					fileName: orgDetail.statIcon?.todayOutName || "",
				},
			},
		}));
	}, [orgDetail]);
	const [error, setError] = useState({});
	const [themeData, setTheme] = useState(DEFAULT_THEME_FORM);

	const handleThemeSubmit = () => {
		const payload = { ...themeData, orgID: userDetail?.orgId };

		// console.log(payload)
		updateTheme(payload)
			.unwrap()
			.then((res) => {
				if (res.error) {
					toastMessage(false, res.message);
				} else {
					toastMessage(true, res.message);
				}
			})
			.catch((err) => {
				// console.log(err);
				toastMessage(false, "Error in updating theme setting");
			});
	};

	const handleThemeValidation = () => {
		let error = {};
		if (!themeData.title) error.title = "Title is required";
		if (!themeData.theme.selectedTheme) error.selectedTheme = "Mode is required";
		if (!themeData.logo.fileData) error.logo = "Logo is required";
		if (!themeData.favIcon.fileData) error.favIcon = "Favicon is required";
		if (!themeData.theme.primaryColor) error.primaryColor = "Primary Color is required";

		if (Object.keys(error).length) {
			setError(error);
		} else {
			setError({});
			handleThemeSubmit();
		}
	};

	const handleThemeChange = (e, evt) => {
		let { name, value, checked, type, files } = evt ? evt.target : e.target;
		value = type == "checkbox" ? checked : value;

		if (type == "file") {
			base64Convert(files[0]).then((res) => {
				setTheme((o) => {
					let n = JSON.parse(JSON.stringify(o));
					assignDeep(n, name, {
						fileData: res,
						docType: name,
						fileName: files[0].name,
					});
					return n;
				});
			});
		} else {
			setTheme((o) => {
				let n = JSON.parse(JSON.stringify(o));
				assignDeep(n, name, value);
				return n;
			});
		}
	};
	return showThemeSettings ? (
		<>
			{isOrgDetailLoading || isOrgDetailUpdating ? (
				<Loader size="2rem" height="8.5rem" />
			) : (
				<div className="form-inputs" style={{ margin: "1rem" }}>
					<Input label="Website Title" placeholder="Enter Website Title" name="title" value={themeData.title} error={error.title} onChange={handleThemeChange} required />

					<Dropdown
						name="theme.selectedTheme"
						label="Mode"
						options={modeOptions}
						onChange={(opt, name) => {
							handleThemeChange({ target: { name, value: opt?.value || "" } });
						}}
						value={modeOptions.find((_) => _.value == themeData.theme?.selectedTheme)}
						error={error.selectedTheme}
						required
					/>

					<UploadFileButton
						allowedExtensions=".jpg, .jpeg, .png"
						name="logo"
						fileName={themeData.logo.fileName}
						onChange={(file) => handleThemeChange({ target: { name: "logo", type: "file", files: [file] } })}
						onChangeClear={() => setTheme((o) => ({ ...o, logo: { fileData: "", docType: "logo", fileName: "" } }))}
						id="uploadFileforLogo"
						buttonText="Upload Logo *"
						error={error.logo}
					/>

					<UploadFileButton
						allowedExtensions=".png, .ico"
						name="favIcon"
						fileName={themeData.favIcon.fileName}
						onChange={(file) => handleThemeChange({ target: { name: "favIcon", type: "file", files: [file] } })}
						onChangeClear={() => setTheme((o) => ({ ...o, favIcon: { fileData: "", docType: "favIcon", fileName: "" } }))}
						id="uploadFileforFavIcon"
						buttonText="Upload Favicon *"
						error={error.favIcon}
					/>
					{Object.keys(themeData.statIcon).map((key, i) => {
						let statIcon = themeData.statIcon[key];
						return (
							<UploadFileButton
								key={i}
								allowedExtensions=".jpg, .jpeg, .png, .svg"
								name={`statIcon.${key}`}
								fileName={statIcon.fileName}
								onChange={(file) => handleThemeChange({ target: { name: `statIcon.${key}`, type: "file", files: [file] } })}
								onChangeClear={() =>
									setTheme((o) => {
										let n = JSON.parse(JSON.stringify(o));
										assignDeep(n, [`statIcon.${key}`], {
											fileData: "",
											docType: `statIcon.${key}`,
											fileName: "",
										});
										return n;
									})
								}
								id={`statIcon.${key}`}
								buttonText={`Upload ${capitalizeCamelCase(key)} Icon`}
							/>
						);
					})}
					<ColorPicker name="theme.primaryColor" onChange={handleThemeChange} value={themeData.theme.primaryColor} />
				</div>
			)}

			<div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem", marginTop: "5rem" }}>
				<Button
					onClick={() => setTheme(DEFAULT_THEME_FORM)}
					text="Reset"
					style={{ ...buttonClasses.lynkitBlackEmpty, width: "fit-content" }}
					disabled={isOrgDetailLoading || isOrgDetailUpdating}
				/>
				<Button onClick={handleThemeValidation} text="Save Changes" style={{ ...buttonClasses.lynkitOrangeEmpty, width: "fit-content" }} disabled={isOrgDetailLoading || isOrgDetailUpdating} />
			</div>
		</>
	) : null;
}

function a11yProps(index) {
	return {
		id: `tab-${index}`,
		"aria-controls": `tabpanel-${index}`,
	};
}

const modeOptions = [
	{ label: "Light", value: "lightMode" },
	{ label: "Dark", value: "darkMode" },
];

const datePrefixOptions = [
	{ label: "DD/MM/YYYY", value: "DD/MM/YYYY" },
	{ label: "DD/MM/YY", value: "DD/MM/YY" },
	{ label: "MM/YY", value: "MM/YY" },
	{ label: "MM/YYYY", value: "MM/YYYY" },
	{ label: "DD/MM", value: "DD/MM" },
	{ label: "MM/DD/YYYY", value: "MM/DD/YYYY" },
	{ label: "MM/DD/YY", value: "MM/DD/YY" },
	{ label: "MMDDYY", value: "MMDDYY" },
	{ label: "MMDDYYYY", value: "MMDDYYYY" },
	{ label: "MMYY", value: "MMYY" },
	{ label: "DDMM", value: "DDMM" },
	{ label: "MMYYYY", value: "MMYYYY" },
];

const financialPrefixOptions = [
	{ label: "YY-YY", value: "short" },
	{ label: "YYYY-YY", value: "long" },
];

const productPrefixOptions = [
	{ label: "Prefix", value: "prefix" },
	{ label: "Product id", value: "productId" },
];

const productCatPrefixOptions = [
	{ label: "Prefix", value: "prefix" },
	{ label: "Product Categories id", value: "productCategoryId" },
];

const supplierPrefixOptions = [
	{ label: "Prefix", value: "prefix" },
	{ label: "Supplier id", value: "supplierId" },
];

const prefixTypeOptions = [
	{ label: "Date", value: "date" },
	{ label: "Global", value: "global" },
	{ label: "Counter", value: "counter" },
	{ label: "Financial Year", value: "finance_year" },
	{ label: "Product", value: "product" },
	{ label: "ProductCategories", value: "productCategories" },
	{ label: "Supplier", value: "supplier" },
];

const resetOptions = [
	{ label: "Day", value: "day" },
	{ label: "Month", value: "month" },
	{ label: "Year", value: "year" },
	{ label: "Financial Year", value: "finance_year" },
];

const pdfDocTypeOptions = [{ label: "Vehicle Detail", value: "vehicle_detail" }];
