import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./Layout.css";
import LynkIdlogo from "../../LynkIdlogo.png";
import HaierLogo from "../../HaierLogo.png";
import { useEffect, useRef, useState } from "react";
import { getShortText, redirectToTMS, userDetail, capitalizeCamelCase, removeSpecialChars } from "../../services/functions";
import { useCheckpoints, useClickOutside, useDynamicModules, useSocket, useUserDetail, useUserPermissions } from "../../services/hooks";
import {
	DashboardIcon,
	DeviceIcon,
	TagIcon,
	UsersIcon,
	CompanyIcon,
	ProductIcon,
	ProductCategoriesIcon,
	SupplierIcon,
	VehicleIcon,
	TagMappingIcon,
	RoleIcon,
	GateOutIcon,
	GateInIcon,
	AccessIcon,
	BayIcon,
	DummyIcon,
	ReportIcon,
	WorkFlowIcon,
	ManageTripIcon,
	ParkingIcon,
	WeighbridgeIcon,
	ApprovalIcon,
	CheckpointTypeIcon,
	DynamicFormIcon,
	RightArrowIcon,
	ArrowDownIcon,
	DynamicModuleIcon,
	BallotIcon,
} from "../../services/icons";
import { Dialog, Input, Loader, toastMessage } from "../utils/index";
import { useChangePasswordMutation, useUpdateTourStatusMutation } from "../../api/userSlice";
import { useApplytheme, useCheckInternet, useOfflineTemplate } from "../../services/hooks";
import { socket } from "../../socket";
import { useGetThemeQuery } from "../../api/userSlice";
import GuidedTour from "../Tour/Tour";
import { useSidebarItems, useTour } from "../../services/context";

export default function Layout({ children }) {
	useSocket();
	useApplytheme();
	const { userDetail, isFetching } = useUserDetail();
	// useOfflineTemplate();
	// const isOnline = useCheckInternet();
	let location = useLocation();
	const navigate = useNavigate();

	const [receivedGuidedTour, setReceivedGuidedTour] = useTour();

	const windowType = localStorage.getItem("windowType") || false;
	const activeUrl = localStorage.getItem("activeURL") || false;

	if (windowType === "windowOpener" && activeUrl !== location.pathname) {
		navigate("/not-authorized");
	}
	useEffect(() => {
		if (!userDetail) return;
		setReceivedGuidedTour(userDetail?.receivedGuidedTour || false);
	}, [userDetail]);

	return (
		<div className="layout">
			{windowType === "windowIframe" ? null : <Header />}
			{activeUrl ? null : <Sidebar />}

			<main>{isFetching ? <Loader size="2.5rem" height="80%" /> : children ? children : <Outlet />}</main>

			{receivedGuidedTour == false ? <GuidedTour /> : null}
		</div>
	);
}

function Header() {
	const activeUrl = localStorage.getItem("activeURL");
	const { data, refetch, isLoading, isFetching, isSuccess } = useGetThemeQuery(
		{},
		{
			refetchOnMountOrArgChange: true,
		}
	);
	let { data: orgDetail, count, message, error } = data || { data: {} };
	const [Logo, setLogo] = useState(LynkIdlogo);

	useEffect(() => {
		if (orgDetail?.logo) {
			localStorage.setItem("logo", orgDetail?.logo);
			setLogo(orgDetail?.logo);
		}
	}, [orgDetail?.logo]);
	return (
		<header>
			<div className="logo">
				<Link to={activeUrl ? activeUrl : "/login"}>
					<img src={localStorage.getItem("logo") || LynkIdlogo} alt="" className="" />
				</Link>
			</div>
			<Profile />
		</header>
	);
}

function Profile() {
	const navigate = useNavigate();
	const { userPermissions } = useUserPermissions();
	const [isMenu, setIsMenu] = useState(false);
	const handleMenuOpen = () => setIsMenu(true);
	const [, setReceivedGuidedTour] = useTour();

	const profileRef = useRef();
	useClickOutside(profileRef, () => setIsMenu(false));

	const { userDetail } = useUserDetail();
	const initial = userDetail?.name ? userDetail.name[0].toUpperCase() : "G";
	const name = userDetail?.name ? userDetail?.name.split(" ")[0] : "";

	const [updateTour, { isLoading: isUpdating }] = useUpdateTourStatusMutation();

	const onLogOut = () => {
		socket.disconnect();
		localStorage.removeItem("primaryColor");
		localStorage.removeItem("selectedTheme");
		localStorage.removeItem("secretkey");
		localStorage.removeItem("favicon");
		localStorage.removeItem("title");
		// localStorage.removeItem("logo");
		localStorage.removeItem("project");
		window.location.reload();
	};

	const onReplayTour = () => {
		updateTour({ receivedGuidedTour: false, userId: userDetail?.userId })
			.unwrap()
			.then((res) => {
				if (!res.error) {
					setReceivedGuidedTour(false);
				}
			});
	};
	const [isChangePass, setIsChangePass] = useState(false);
	const handleChangePassOpen = () => setIsChangePass(true);
	const handleChangePassClose = () => setIsChangePass(false);

	return (
		<>
			<div className="user_panel" ref={profileRef}>
				<button className="profile_btn" onClick={handleMenuOpen}>
					{initial}
				</button>
				{isMenu ? (
					<ul>
						<li className="profile_detail">
							<div className="profile_btn">{initial}</div>
							<div className="profile_info">
								<div className="profile_name">{name || "Guest"}</div>
								<div className="profile_mob">{userDetail?.mobile || "NA"}</div>
							</div>
						</li>
						<li>
							<button onClick={handleChangePassOpen}>Change Password</button>
						</li>
						{userDetail?.userType == "admin" ? (
							<li>
								<button onClick={() => navigate("/settings")}>Settings</button>
							</li>
						) : null}
						<li>
							<button onClick={onReplayTour}>Replay Tour</button>
						</li>
						<li>
							<button onClick={onLogOut}>Log Out</button>
						</li>
					</ul>
				) : null}

				{isChangePass && userPermissions?.changePassword?.value ? <ChangePassword open={isChangePass} onClose={handleChangePassClose} /> : null}
			</div>
		</>
	);
}

function ChangePassword({ open, onClose }) {
	const [formData, setFormData] = useState({
		password: "",
		newPassword: "",
		confirmNewPassword: "",
	});
	const handleInput = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};
	const [handleSubmit, { isLoading }] = useChangePasswordMutation();

	const handleFormSubmit = async () => {
		const payload = { ...formData };
		delete payload.confirmNewPassword;
		const {
			data: { success, message },
		} = await handleSubmit(payload);
		if (!success) {
			toastMessage(false, message);
		} else {
			toastMessage(true, message);
			handleClose();
			socket.disconnect();
			localStorage.removeItem("secretkey");
			localStorage.removeItem("primaryColor");
			localStorage.removeItem("selectedTheme");
			localStorage.removeItem("favicon");
			localStorage.removeItem("title");
			localStorage.removeItem("logo");
			localStorage.removeItem("project");
			window.location.reload();
		}
	};
	const handleClose = () => {
		onClose();
	};
	return (
		<Dialog open={open} handleClose={handleClose} handleSubmit={handleFormSubmit} title="Change Password" size="sm" isSubmitDisabled={isLoading}>
			<form className="form-inputs">
				<Input label="Old Password" placeholder="Enter Old Password" name="password" value={formData.password} onChange={handleInput} required />
				<Input label="New Password" placeholder="Enter New Password" name="newPassword" value={formData.newPassword} onChange={handleInput} required />
				<Input label="Confirm Password" placeholder="Confirm New Password" name="confirmNewPassword" value={formData.confirmNewPassword} onChange={handleInput} required />
			</form>
		</Dialog>
	);
}

function Sidebar() {
	const [openItems, setOpenItems] = useState([]);
	const [isActiveChild, setIsActiveChild] = useState("");

	const toggleItem = (index) => {
		if (openItems.includes(index)) {
			setOpenItems([]);
		} else {
			setOpenItems([index]);
		}
	};
	const sidebarItems = useSidebarItems();

	return sidebarItems?.length ? (
		<aside onMouseLeave={() => setOpenItems([])}>
			<ul>
				{sidebarItems?.map((item, i) => {
					if (!item.permission) return null;
					const isOpen = openItems.includes(i);
					return (
						<li key={i}>
							{item.children.length > 0 ? (
								<Link className={Boolean(item.children.find((ele) => ele.title === isActiveChild)) ? "navlink childActive" : "navlink"} onClick={() => toggleItem(i)}>
									<item.Icon fontSize="small" className="sidebar_icon" />
									<span className={`sidebar_title ${item.class ? item.class : ""}`}>{item.title}</span>
									{item.children.length > 0 && !isOpen ? (
										<RightArrowIcon fontSize="small" className="sidebar_icon arrowIcon" />
									) : (
										<ArrowDownIcon fontSize="small" className="sidebar_icon" />
									)}
								</Link>
							) : (
								<NavLink
									to={item.link}
									state={item.state}
									className={({ isActive }) => (isActive ? "navlink active" : "navlink")}
									onMouseEnter={() => toggleItem(i)}
									onClick={() => setIsActiveChild("")}
								>
									<item.Icon fontSize="small" className="sidebar_icon" />
									<span className={`sidebar_title ${item.class ? item.class : ""}`}>{item.title}</span>
								</NavLink>
							)}

							{isOpen &&
								item.children?.map((child, indx) => {
									if (!child.permission) return null;
									return (
										<NavLink
											key={indx}
											to={child.link}
											state={child.state}
											style={{ paddingLeft: "3rem" }}
											className={({ isActive }) => (isActive ? "navlink active" : "navlink")}
											onClick={() => setIsActiveChild(child.title)}
										>
											<span className="sidebar_title">{child.title}</span>
										</NavLink>
									);
								})}
						</li>
					);
				})}
			</ul>
		</aside>
	) : null;
}
